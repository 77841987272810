import {
    FormControlLabel,
    Checkbox,
    Box,
    Button,
    Divider,
    Modal,
    Stack,
    Alert,
    InputAdornment,
    IconButton,
    ThemeProvider,
    createTheme,
    TextField,
    Snackbar,
    AlertTitle,
} from '@mui/material';
import stylesInput from '../Stepps/InputStyle.module.css';
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import styleHeader from '../Header/styles';
import logo1 from "../../Assets/logo-pt1.png";
import React, { useState } from 'react';
import { IMaskInput } from "react-imask";
import api from "../../Services/api";
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';

const label = { inputProps: { 'aria-label': 'Usuário Administrador' } };

const schema = yup.object({
    password:
        yup.string()
            .min(6, "Tamanho mínimo de 6 caracteres")
            .required("Defina uma senha*"),

    email:
        yup.string()
            .email("Email inválido")
            .required("Defina um Email* "),

    cpf:
        yup.string()
            .required("Defina um CPF*"),
}).required();

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    overflowY: 'scroll',
    height: '95%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    bgcolor: 'background.paper',
    borderRadius: '16px',
    border: '4px solid orange',
    boxShadow: 24,
    background: 'rgb(34,29,124)',
    background: 'linear-gradient(9deg, rgba(34,29,124,1) 0%, rgba(46,29,149,1) 39%, rgba(47,34,131,1) 100%)',
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    p: 4,
};

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "white", // Defina a cor da borda aqui
                        },
                        "&:hover fieldset": {
                            borderColor: "white", // Defina a cor da borda ao passar o mouse aqui
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "white", // Defina a cor da borda quando o campo está focado aqui
                        },
                    },
                    "& .MuiInputBase-input::placeholder": {
                        color: "white", // Defina a cor do placeholder aqui
                    },
                    "& .MuiInputBase-input": {
                        color: 'white', // Defina a cor do texto aqui
                    },
                },
            },
        },
    },
});

export default function ModalNovoUsuario() {
    const [open, setOpen] = React.useState(false);
    const [userCreated, setUserCreated] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [erro, setErro] = useState('');
    const [carregando, setCarregando] = useState(false);

    const { handleSubmit, control } = useForm({ resolver: yupResolver(schema) });

    const navigate = useNavigate();

    const closeAlert = () => {
        setUserCreated(false);
    }

    function validaToken(validadeToken) {
        // Passo 1: Converter a string de data do token para um objeto Date
        const tokenExpirationDate = new Date(validadeToken);

        // Passo 2: Obter a data atual
        const currentDate = new Date();

        // Comparar as datas
        return currentDate < tokenExpirationDate;
    }

    async function onSubmit(data) {
        console.log(data);
        setCarregando(true);
        try {
            // para validar:
            const valid = await schema.isValid(data);

            if (valid) {
                const formatCpf = data.cpf.replace(/[^a-zA-Z0-9]/g, '');

                let sucessoToken = false;

                if (!localStorage.getItem("tokenCartao") || !validaToken(localStorage.getItem("tokenValidade"))) {
                    try {
                        const response = await api.post('/ApiCartaoCidadao');
                        if (response.data.status === "100") {
                            localStorage.setItem("tokenCartao", response.data.token);
                            localStorage.setItem("tokenValidade", response.data.expira);
                            localStorage.setItem("tokenTempo", response.data.tempo);
                            sucessoToken = true;
                        } else {
                            setErro("Falha ao conectar-se com o servidor do Cartão pira cidadão. Entre em contato com o suporte");
                        }
                    } catch (err) {
                        if (err.response.status === 403) {
                            setErro(err.response.data.message);
                        } else {
                            setErro("Algum erro inesperado aconteceu, tente novamente mais tarde!");
                        }
                    }
                } else {
                    sucessoToken = true;
                }

                if (sucessoToken) {
                    const params = {
                        'cpf': formatCpf,
                        'token': localStorage.getItem("tokenCartao"),
                    };

                    const queryString = new URLSearchParams(params).toString();

                    try {
                        console.log(data);
                        if (data.PanelAccess == true) {
                            try {
                                const headers = {
                                    'TokenPiraCidadao': localStorage.getItem("tokenCartao"),
                                };
                                await api.post('/register', data, { headers });
                                navigate('/cadastro/verificacaoCadastro');
                            } catch (err) {
                                setErro(err.response.data.message.toString());
                            } finally {
                                setCarregando(false);
                            }
                        } else {
                            const response = await api.get('/ApiCartaoCidadao?' + queryString, "");
                            if (response.status === 200) {
                                try {
                                    const headers = {
                                        'TokenPiraCidadao': localStorage.getItem("tokenCartao"),
                                    };

                                    data.cpfWithoutMask = data.cpf.replace(".", "");
                                    data.cpfWithoutMask = data.cpfWithoutMask.replace(".", "");
                                    data.cpfWithoutMask = data.cpfWithoutMask.replace("-", "");
                                    await api.post('/register', data, { headers });
                                    navigate('/cadastro/verificacaoCadastro');
                                } catch (err) {
                                    setErro(err.response.data.message.toString());
                                } finally {
                                    setCarregando(false);
                                }
                            } else {
                                navigate('/cadastroCidadao');
                            }
                        }


                    } catch (err) {
                        // if (err.response.status === 403) {
                        // setErro(err.response.data.message);
                        setErro(err.response);

                        // } else {
                        //     setErro("Algum erro inesperado aconteceu, tente novamente mais tarde!");
                        // }
                    } finally {
                        setCarregando(false);
                    }
                }
            } else {
                // tem algum erro de validação.
                setErro("Erro de validação");
                setCarregando(false);
            }
        } catch (err) {
            console.log('valid', err);
            setCarregando(false);
        }
    }

    return (
        <>
            <Button
                sx={{
                    display: 'flex',
                    justifyContent: 'end',
                }}
                variant='contained'
                onClick={handleOpen}
            >Criar novo Usuário</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <form onSubmitCapture={handleSubmit(onSubmit)} >
                    <Box sx={style}>
                        <div className='flex flex-col items-center '>
                            <img
                                src={logo1}
                                style={{ width: '180px' }} />
                            <p className="font-medium text-xl mt-4 text-white"  > Crie um novo usuário ! </p>
                        </div>
                        <Stack spacing={4}>
                            <Controller
                                control={control}
                                name="name"
                                render={({ field }) => (
                                    <ThemeProvider theme={theme}>
                                        <TextField
                                            required
                                            style={{ marginTop: '32px' }}
                                            placeholder="Nome *"
                                            {...field}
                                        />
                                    </ThemeProvider>
                                )}
                            />

                            <Divider color="orange" />
                            <Controller
                                control={control}
                                name="cpf"
                                sx={{ color: '#fff' }}
                                render={({ field }) => (
                                    <>
                                        <IMaskInput
                                            required
                                            className={stylesInput.InputStyleWhite}
                                            mask="000.000.000-00"
                                            minLength="14"
                                            maxLength="14"
                                            placeholder="CPF *"
                                            {...field}  // importante
                                        >
                                        </IMaskInput>
                                    </>

                                )} />

                            <Divider color="orange" />
                            <Controller
                                control={control}
                                name="email"
                                render={({ field }) => (
                                    <>
                                        <ThemeProvider theme={theme}>
                                            <TextField
                                                required
                                                placeholder="Email *"
                                                type="email"
                                                {...field}
                                            />
                                        </ThemeProvider>
                                    </>
                                )} />

                            <Divider color="orange" />
                            <Controller
                                control={control}
                                name="password"
                                render={({ field }) => (
                                    <>
                                        <ThemeProvider theme={theme}>
                                            <TextField
                                                required
                                                placeholder="Senha *"
                                                type={showPassword ? 'text' : 'password'}
                                                {...field}
                                                // InputLabelProps={{ sx: inputStyles }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </ThemeProvider>
                                    </>
                                )} />

                            <Divider color="orange" />
                            <Controller
                                control={control}
                                name="confirmPassword"
                                render={({ field }) => (
                                    <>
                                        <ThemeProvider theme={theme}>
                                            <TextField
                                                required
                                                placeholder="Confirmar Senha *"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...field}
                                            />
                                        </ThemeProvider>
                                    </>
                                )} />

                            <Divider color="orange" />
                            <Controller
                                control={control}
                                name='PanelAccess'
                                render={({ field }) => (
                                    <>
                                        <FormControlLabel sx={{ color: '#fff' }}
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        color: 'orange',
                                                        '&.Mui-checked': { color: 'orange' }
                                                    }} />
                                            }
                                            label="Usuário Administrador"
                                            {...field}
                                        />
                                    </>
                                )}
                            />

                            {erro &&
                                <Alert severity='error'>
                                    {erro}
                                </Alert>
                            }

                            <Divider color="orange" />
                            <LoadingButton
                                loading={carregando}
                                sx={{
                                    backgroundColor: 'orange',
                                    color: 'rgb(34,29,124)'
                                }}
                                variant='contained'
                                type='submit'
                            >
                                Criar!
                            </LoadingButton>
                        </Stack>
                    </Box>
                </form>
            </Modal>

            <Snackbar open={userCreated} autoHideDuration={6000} onClose={closeAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={closeAlert} severity="success">
                    Usuário criado com sucesso!
                </Alert>
            </Snackbar>
        </>
    )
}