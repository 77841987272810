import React, { useState,useEffect, createContext } from "react";
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const isAuthenticated = () => {
    return sessionStorage.getItem('token') !== null;
}

export const login = (response) => {
    sessionStorage.setItem('token', response?.token);
    sessionStorage.setItem('usuarioLogado', response.usuarioPortal);
}

export const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const recoveredUser = localStorage.getItem('user');

        if(recoveredUser) {
            setUser(JSON.parse(recoveredUser));
        }

        setLoading(false);
    }, []);

    const logout = () => {
        localStorage.removeItem("user")
        setUser(null);
        navigate("/");

        console.log('logout');
    };

    return (
        <AuthContext.Provider value={{ authenticated: !!user, user, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    )
}