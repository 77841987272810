//_1 Importações
//_2 Mensagem de erro
//_3 Validação Yup
//_4 Ligação com a API
//_5 Styles
//_6 Formulário Login

//_1 Importações =====================================================================================
//React
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import dadosAplicacao from "../../package.json";


//MUI
import { Paper, Container, TextField, Stack, Box, Button, Alert } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';

//API
import api from ".././Services/api";
import { getDados } from '.././Services/globalRequests';

//Estilos
import stylesInput from '../Components/Stepps/InputStyle.module.css';
import stylesText from '../Components/Stepps/Text.module.css';
import CodigoPenal from '../Components/CódigoPenal/index';
import styleHeader from '../Components/Header/styles';
import { useMediaQuery } from 'react-responsive'
import logo1 from "../Assets/logo-pt1.png";
import logo2 from "../Assets/logo-pt2.png";

//Yup
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

//iMask
import { IMaskInput } from "react-imask";
import { LoadingButton } from '@mui/lab';
import ReactInputMask from 'react-input-mask';

export const Login = ({
    endpoint = "/users/data",
    bodyParametros,
    open,
    handleClose
}) => {

    if (localStorage.getItem('AppVersion')) {
        if (localStorage.getItem('AppVersion') != dadosAplicacao.version) {
            localStorage.setItem('AppVersion', dadosAplicacao.version);
            window.location.reload(true);
        }
    } else {
        localStorage.setItem('AppVersion', dadosAplicacao.version);
        window.location.reload(true);
    }

    //_2 Mensagem de erro ================================================================================
    const [carregando, setCarregando] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [erro, setErro] = useState();
    const [erroCidadao, setErroCidadao] = useState();

    const navigate = useNavigate();

    //css
    const style = {
        icon: {
            color: 'gray',
        }
    }

    const handlePasswordVisibility = (event) => {
        event.preventDefault();
        setShowPassword(!showPassword);
    }

    async function msgError() {
        api()
            .then(res => setDados(res.data))
            .catch(err => setErro(err.response.data ? err.response.data.message : err.toString()))
            .finally(() => setCarregando(false));
    }

    //_3 Validação Yup ===================================================================================
    const schema = yup.object({
        cpf:
            yup.string()
                .required("Defina um CPF"),

        password:
            yup.string()
                .min(6, "Tamanho mínimo de 6 caracteres")
                .required("Defina uma senha*")

    }).required();

    const { handleSubmit, control, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

    //_4 Ligação com a API ================================================================================
    const [dados, setDados] = useState('');
    const [campos, setCampos] = useState(sessionStorage);

    const [ultimaChamada, setUltimaChamada] = useState(0);
    const [tempoRestante, setTempoRestante] = useState(0);
    const intervalo = 5000;

    const [contador, setContador] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setContador((prevContador) => prevContador - 1);
        }, 1000);
        // Limpar o intervalo quando o componente for desmontado
        return () => (clearInterval(intervalId));
    }, []);

    function validaToken(validadeToken) {
        // Passo 1: Converter a string de data do token para um objeto Date
        const tokenExpirationDate = new Date(validadeToken);

        // Passo 2: Obter a data atual
        const currentDate = new Date();

        // Comparar as datas
        return currentDate < tokenExpirationDate;
    }

    async function onSubmit(data) {
        setCarregando(true);
        const agora = new Date().getTime();

        if (agora - ultimaChamada >= 5000) {
            setUltimaChamada(agora);
            setContador(5)


            let dadosCartao = false;
            console.log(data);

            try {
                // para validar:
                const valid = await schema.isValid(data);

                // caso esteja validado
                if (valid) {
                    // chamada para a API passando os dados (`data`) por parâmetro
                    const cpf = data.cpf;

                    let sucessoToken = false;
                    if (!localStorage.getItem("tokenCartao") || !validaToken(localStorage.getItem("tokenValidade"))) {
                        try {
                            const response = await api.post('/ApiCartaoCidadao');
                            if (response.data.status === "100") {
                                localStorage.setItem("tokenCartao", response.data.token);
                                localStorage.setItem("tokenValidade", response.data.expira);
                                localStorage.setItem("tokenTempo", response.data.tempo);
                                sucessoToken = true;
                            } else {
                                setErro("Falha ao conectar-se com o servidor do Cartão pira cidadão. Entre em contato com o suporte");
                            }
                        } catch (err) {
                            if (err.response.status === 403) {
                                setErro(err.response.data.message);
                            } else {
                                setErro("Algum erro inesperado aconteceu, tente novamente mais tarde!");
                            }
                        }
                    } else {
                        sucessoToken = true;
                    }

                    if (sucessoToken) {
                        const params = {
                            'cpf': cpf.replace(/[.-]/g, ''),
                            'token': localStorage.getItem("tokenCartao"),
                        }
                        const queryString = new URLSearchParams(params).toString();

                        const responseApiCartao = await api.get('/ApiCartaoCidadao?' + queryString);



                        if (responseApiCartao.status == 200) {
                            sessionStorage.setItem("celular", responseApiCartao.data.celular);
                            sessionStorage.setItem("CEP", responseApiCartao.data.cep);
                            sessionStorage.setItem("email", responseApiCartao.data.email);

                            if (responseApiCartao.data.deficiencia == "Auditiva") {
                                sessionStorage.setItem("possuiDeficiencia", true);
                                sessionStorage.setItem("tipoDeDeficiencia", 3);

                            } else if (responseApiCartao.data.deficiencia == "Visual") {
                                sessionStorage.setItem("possuiDeficiencia", true);
                                sessionStorage.setItem("tipoDeDeficiencia", 2);

                            } else if (responseApiCartao.data.deficiencia == "Intelectual/Cognitiva") {
                                sessionStorage.setItem("possuiDeficiencia", true);
                                sessionStorage.setItem("tipoDeDeficiencia", 4);

                            } else if (responseApiCartao.data.deficiencia == "Física") {
                                sessionStorage.setItem("possuiDeficiencia", true);
                                sessionStorage.setItem("tipoDeDeficiencia", 1);

                            } else if (responseApiCartao.data.deficiencia == "Autismo") {
                                sessionStorage.setItem("possuiDeficiencia", true);

                            } else if (responseApiCartao.data.deficiencia == "Outra") {
                                sessionStorage.setItem("possuiDeficiencia", true);

                            } else if (responseApiCartao.data.deficiencia == null) {
                                sessionStorage.setItem("possuiDeficiencia", false);
                            }

                            sessionStorage.setItem("rg", responseApiCartao.data.rg);

                            if (responseApiCartao.data.sexo == "M") {
                                sessionStorage.setItem("sexoNascimento", 1);
                            } else {
                                sessionStorage.setItem("sexoNascimento", 0);
                            }

                            sessionStorage.setItem("emailLogin", responseApiCartao.data.email);
                            sessionStorage.setItem("userName", responseApiCartao.data.nome);
                            sessionStorage.setItem("dataDeNascimento", responseApiCartao.data.nascimento);

                            if (responseApiCartao.data.nacionalidade == "BRASILEIRO") {
                                sessionStorage.setItem("nacionalidade", "Brasil");
                                sessionStorage.setItem("naturalidadeEstado", responseApiCartao.data.naturalidade.slice(-2));
                                sessionStorage.setItem("naturalidadeCidade", responseApiCartao.data.naturalidade.slice(0, -5));
                            } else {
                                sessionStorage.setItem("nacionalidade", null)
                            }

                            if (responseApiCartao.data.moradia == "Própria") {
                                sessionStorage.setItem("condicaoDaMoradia", 1)
                            } else if (responseApiCartao.data.moradia == "Cedida") {
                                sessionStorage.setItem("condicaoDaMoradia", 2)
                            } else if (responseApiCartao.data.moradia == "Alugada") {
                                sessionStorage.setItem("condicaoDaMoradia", 3)
                            } else {
                                sessionStorage.setItem("condicaoDaMoradia", 4)
                            }


                            // sessionStorage.setItem("escolaridade", responseApiCartao.data.escolaridade)
                            sessionStorage.setItem("Endereco", responseApiCartao.data.endereco);
                            sessionStorage.setItem("Logradouro", responseApiCartao.data.LOGRADOURO);
                            sessionStorage.setItem("Complemento", responseApiCartao.data.end_compl);
                            sessionStorage.setItem("Bairro", responseApiCartao.data.end_bairro);
                            sessionStorage.setItem("Numero", responseApiCartao.data.numero);

                            // if (responseApiCartao.data.complemento) {
                            //     sessionStorage.setItem("ComprovanteResidencia", responseApiCartao.data.complemento);
                            // }

                            // if (responseApiCartao.data.documento) {
                            //     localStorage.setItem("Documento", responseApiCartao.data.documento);
                            // }

                            dadosCartao = true;


                        } else if (responseApiCartao.status == 204) {
                            setErroCidadao("Dados não encontrados no cadastro do cartão cidadão.")
                        }

                        const responseLogin = await api.post('/Login', data);

                        sessionStorage.setItem("token", responseLogin.data.token);
                        sessionStorage.setItem("cpf", data.cpf);

                        if (!sessionStorage.getItem("emailLogin")) {
                            sessionStorage.setItem("emailLogin", responseLogin.data.userEmail);
                        }

                        if (responseLogin.data.acessoPainel === true) {
                            navigate('/admin');
                        } else if (responseLogin.data.contaAtiva == false) {
                            alert("Sua conta não está mais ativa!\nEntre em contato para maiores informações");
                        } else {
                            const dados = await getDados(endpoint);

                            if (!sessionStorage.getItem("celular")) {
                                sessionStorage.setItem("celular", dados.celular);
                            }

                            if (!sessionStorage.getItem("nome")) {
                                sessionStorage.setItem("nome", dados.nome);
                            }

                            if (dadosCartao == false) {
                                sessionStorage.setItem("celular", dados.celular);
                                sessionStorage.setItem("emailMutuario", dados.emailMutuario);
                                sessionStorage.setItem("emailCadastro", dados.emailCadastro);
                                sessionStorage.setItem("nacionalidade", dados.nacionalidade);
                                sessionStorage.setItem("dataDeNascimento", dados.dataDeNascimento);
                                sessionStorage.setItem("naturalidadeEstado", dados.naturalidadeEstado);
                                sessionStorage.setItem("naturalidadeCidade", dados.naturalidadeCidade);
                                sessionStorage.setItem("nome", dados.nome);
                                sessionStorage.setItem("userName", dados.nome);
                                sessionStorage.setItem("rg", dados.rg);
                                sessionStorage.setItem("orgaoEmissor", dados.orgaoEmissor);
                                sessionStorage.setItem("sexoNascimento", dados.sexoNascimento);
                                sessionStorage.setItem("possuiDeficiencia", dados.possuiDeficiencia);
                                sessionStorage.setItem("tipoDeDeficiencia", dados.tipoDeDeficiencia);
                            }

                            sessionStorage.setItem("celularDeRecado", dados.celularDeRecado);
                            sessionStorage.setItem("contatoCelularRecado", dados.contatoCelularRecado);
                            if (dados.identidadeGenero) {
                                sessionStorage.setItem("identidadeGenero", dados.identidadeGenero);

                            } else {
                                sessionStorage.setItem("identidadeGenero", 0);

                            }
                            sessionStorage.setItem("nomeSocial", dados.nomeSocial);

                            if (dados.racaCor) {
                                sessionStorage.setItem("racaCor", dados.racaCor);

                            } else {
                                sessionStorage.setItem("racaCor", 0);

                            }

                            if (dados.possuiDoencaCronica) {
                                sessionStorage.setItem("possuiDoencaCronica", dados.possuiDoencaCronica);
                            } else {
                                sessionStorage.setItem("possuiDoencaCronica", false);
                            }

                            sessionStorage.setItem("doencaCronicaDescricao", dados.doencaCronicaDescricao);
                            sessionStorage.setItem("funcionarioPublico", dados.funcionarioPublico);
                            sessionStorage.setItem("instituicaoPublica", dados.instituicaoPublica);

                            if (dados.votaEmPiracicaba) {
                                sessionStorage.setItem("votaEmPiracicaba", dados.votaEmPiracicaba);
                            } else {
                                sessionStorage.setItem("votaEmPiracicaba", true);
                            }

                            sessionStorage.setItem("votaEmPiracicabaDesde", dados.votaEmPiracicabaDesde);
                            sessionStorage.setItem("cidadeDeVoto", dados.cidadeDeVoto);
                            sessionStorage.setItem("estadoDeVoto", dados.estadoDeVoto);

                            if (dados.escolaridade) {
                                sessionStorage.setItem("escolaridade", dados.escolaridade);

                            } else {
                                sessionStorage.setItem("escolaridade", "");
                            }

                            if (dados.estadoCivil) {
                                sessionStorage.setItem("estadoCivil", dados.estadoCivil);
                            } else {
                                sessionStorage.setItem("estadoCivil", "");
                            }

                            sessionStorage.setItem("moraEmPiracicabaDesde", dados.moraEmPiracicabaDesde);


                            setDados(dados);
                            setCarregando(false);

                            sessionStorage.setItem("dadosCartao", dadosCartao);

                            navigate('/cadastroPessoal');
                        }
                    }
                } else {
                    setErro("Um erro de validação ocorreu!");
                }
            } catch (err) {
                setErro(err.response.data.message);
            } finally {
                setCarregando(false);
            }
        } else {
            setCarregando(false);
        }
    }


    const titleHeader = useMediaQuery({
        query: '(min-width: 640px)'
    })

    //_5 Styles =========================================================================================

    return (
        <div className="BackgroundImg" style={{ textAlign: 'center' }}>
            <Box sx={styleHeader.header}>
                <Box sx={styleHeader.logoContainer}>
                    <img style={styleHeader.imgLogo} src={logo1} height='50' alt='' />
                </Box>
                {titleHeader &&
                    <Box sx={styleHeader.titulo}>
                        CADASTRO DE DEMANDA PARA HABITAÇÃO DE INTERESSE SOCIAL
                    </Box>
                }

                {/* <h1
                        style={{
                            fontSize: '28px',
                            fontWeight: '800',
                            color: '#F78C2F'
                        }}
                    >
                        HOMOLOGAÇÃO
                    </h1> */}

                <Box sx={styleHeader.logoContainer}>
                    <img style={styleHeader.imgLogo2} src={logo2} height='50' alt='' />
                </Box>
            </Box>

            <Container sx={{ pt: '2vh', mb: 2 }}>

                <Paper
                    sx={{
                        p: 3,
                        maxWidth: 880,
                        margin: 'auto',
                        borderRadius: '16px'
                    }}
                >
                    <Paper
                        sx={{
                            p: 3,
                            margin: 'auto',
                            borderRadius: '8px',
                            boder: '1px solid #000',
                            backgroundColor: '#f5f5f5'
                        }}
                    >
                        <Box sx={{ mb: 3, position: 'relative' }}>
                            <p className={stylesText.subtitulo}>
                                Preencha as informações, caso ja tenha um cadastro.
                            </p>
                        </Box>

                        {/* _6 Formulário Login ===================================================================================== */}
                        <form onSubmitCapture={handleSubmit(onSubmit)}>
                            <Stack spacing={1} >

                                <Controller
                                    control={control}
                                    name="cpf"
                                    render={({ field }) => (
                                        <>
                                            <Box sx={{
                                                border: "1px solid #ccc",
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                m: 1,
                                                bgcolor: 'background.paper',
                                                borderRadius: 1,
                                            }}>
                                                <AccountCircleIcon sx={{
                                                    color: '#696969',
                                                    m: 1,
                                                    fontSize: '40px',
                                                    border: 'solix 1px #000'
                                                }} />

                                                {/* 
                                                Não utilizo o iMask, pois a porra do hook form conflita
                                                (propriedade {...field})
                                                */}
                                                <ReactInputMask 
                                                     required
                                                     className={stylesInput.InputStyleLogin}
                                                     mask="999.999.999-99"
                                                     placeholder="CPF *"
                                                     {...field}  // importante
                                                
                                                />
                                               
                                                <p
                                                    style={{
                                                        color: 'red',
                                                        marginTop: '20px',
                                                        display: 'flex'
                                                    }}
                                                >
                                                    {errors.cpf?.message}
                                                </p>
                                            </Box>
                                        </>
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="password"
                                    render={({ field }) => (
                                        <>
                                            <Box sx={{
                                                border: "1px solid #ccc",
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                margin: "7px !important",
                                                bgcolor: 'background.paper',
                                                borderRadius: 1,
                                            }}>
                                                <KeyIcon sx={{
                                                    color: '#696969',
                                                    m: 1,
                                                    fontSize: '40px',
                                                    border: 'solix 1px #000'
                                                }} />
                                                <input
                                                    className={stylesInput.InputStyleLogin}
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder="Senha *"
                                                    {...field} // importante
                                                />
                                                <a onClick={handlePasswordVisibility}>
                                                    <span className={showPassword ? stylesInput.icon : stylesInput.icon}>
                                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </span>
                                                </a>
                                            </Box>
                                            <p style={{ color: 'red', display: 'flex', margin: '7px' }}>{errors.password?.message}</p>
                                        </>
                                    )}
                                />

                                {erro &&
                                    <Alert severity='error'>
                                        {erro}
                                    </Alert>
                                }

                                {erroCidadao &&
                                    <Alert severity='error'>
                                        {erroCidadao}
                                    </Alert>
                                }
                                <div>
                                    {contador > 0 && (
                                        <Alert severity='warning'>Aguarde {contador} segundos para tentar novamente</Alert>
                                    )}
                                </div>

                                <Stack direction='row'>
                                    <LoadingButton
                                        style={{ backgroundColor: '#3F51b5' }}
                                        loading={carregando}
                                        type="submit"
                                        fullWidth={true}
                                        variant='contained'
                                        disableElevation
                                    >
                                        Entrar
                                    </LoadingButton>
                                </Stack>

                                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                                    <Link
                                        to="/"
                                        className={stylesText.linkCadastro}
                                        style={{ textDecoration: 'underline', marginTop: '16px' }}
                                    >
                                        Voltar para a tela de Cadastro
                                    </Link>

                                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <p
                                            className={stylesText.perguntaCadastro}
                                        >
                                            Esqueceu sua senha?
                                        </p>

                                        <Link
                                            to="/restaurarConta"
                                            className={stylesText.linkCadastro}
                                            style={{ textDecoration: 'underline' }}
                                        >
                                            Clique aqui!
                                        </Link>
                                        <div
                                            style={{ color: "#9e9e9e", fontSize: "14px", marginTop: "14px" }}
                                        >Versão: {localStorage.getItem('AppVersion')}</div>
                                    </Box>
                                </Box>
                            </Stack>
                        </form>
                    </Paper>
                    <CodigoPenal />
                </Paper>
            </Container>
        </div >
    );
}