//Importações do React
import { useEffect, useState } from 'react';
import React from 'react';
// Importações do MUI 
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';

//impotação da API
import api from "../../Services/api";
import { getDados, getDadosById } from '../../Services/globalRequests';

//   Importações do "Stepper" 
import { StepperContext } from "../../Contexts/StepperContext";
import Stepper from '../../Components/Stepper/Stepper';

// Importações de estilo (.css e imagens)
import BackgroundImg from '../../Components/Background/estiloPages.css';
import styles from '../../Components/Stepps/InputStyle.module.css';

// Importações para o formulário
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Button, Form, Space } from 'antd';
import MaskedInput from 'react-text-mask'

//Importações yup
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Demais importações
import { useNavigate, useParams } from "react-router-dom";
import { Header } from '../../Components/Header';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { saveFinanceiroStorage } from '../../Utils/GlobalStorage';
import { incluirDados } from '../../Services/globalRequests'

const defaultMaskOptions = {
    prefix: 'R$ ',
    suffix: '', includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: false,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}

export const CadFinanceiro = ({
    endpoint = "/users/finance",
    endpoint2 = "/users/dependents",
    endpoint3 = "/users/attchments"
}) => {
    const { id } = useParams();

    const [carregando, setCarregando] = useState(false);

    // Funções para o funcionamento correto do Stepper ---------------------------------------
    const [openTipoTrabalho, setOpenTipoTrabalho] = React.useState(false);
    const [tipoTrabalho, setTipoTrabalho] = React.useState('');
    const [selectedRadio, setSelectedRadio] = useState(true);
    const [currentStep, setCurrentStep] = useState(3);
    const [qtdSalarios, setQtdSalarios] = useState();
    const [salarioUser, setSalarioUser] = useState();
    const [finalData, setFinalData] = useState([]);
    const [salarioMin, setSalarioMin] = useState();
    const [stepData, setStepData] = useState('');
    const [renda, setRenda] = React.useState('');
    const [openRenda, setOpenRenda] = React.useState(false);
    const [showFinanceiroValue, setShowFinanceiroValue] = useState(false);
    const [dados, setDados] = useState()
    const navigate = useNavigate();

    const steps = [
        "Dados Pessoais",
        "Endereço Atual",
        "Composição e Renda Familiar",
        "Gasto mensal (Familiar)",
        "Anexos",
        "Finalizado"
    ];


    const style = {
        inputAdd: {
            padding: '8px',
            border: '1px solid #C8C8C8',
            borderRadius: '4px',
            marginRight: '4px',
            color: '#b9b9b9',
        },
        containerStepper: {
            height: 'auto',
            margin: 'auto',
            marginTop: '80px'
        }
    }
    const styleMask = {
        mask: {
            marginRight: '1%',
            marginLeft: '1%',
            width: "100%"
        }
    }

    //  Funções do formulário
    //      1 - Funções base: Selects e outros elementos necessários para o funcionamento do formulário
    //  ------------------------------------------------------------------------------------------------

    const currencyMask = createNumberMask(defaultMaskOptions)

    // Srcipts do UseForm -------------------------------------------------------------
    const [erro, setErro] = useState('');


    // Validação pelo yup
    var schema = yup.object().shape({
        showFinanceiro: yup.boolean(),


        expenseValues:
            yup.string()
                .when("showFinanceiro", {
                    is: true,
                    then: yup.string().required("Por favor, nos informe o valor de pelo menos um gasto mensal"),
                }),

        valorAgua:
            yup.number()
                .typeError('Caso você não gaste com água, por favor, digite "0"')
                .moreThan(-1, 'Água: O valor deve ser positivo ou zero.')
                .max(99999.99, 'Água: Foi excedido o limite de 5 números inteiros')
                .required("Caso você não gaste com água, por favor, digite '0'"),

        valorAlimentacao:
            yup.number()
                .typeError('Caso você não gaste com alimentação, por favor, digite "0"')
                .moreThan(-1, 'Alimentação: O valor deve ser positivo ou zero.')
                .max(99999.99, 'Alimentação: Foi excedido o limite de 5 números inteiros')
                .required("Caso você não gaste com alimentação, por favor, digite '0'"),

        valorAluguel:
            yup.number()
                .typeError('Caso você não gaste com aluguel, por favor, digite "0"')
                .moreThan(-1, 'Aluguel: O valor deve ser positivo ou zero.')
                .max(99999.99, 'Aluguel: Foi excedido o limite de 5 números inteiros')
                .required("Caso você não gaste com aluguel, por favor, digite '0'"),

        valorEducacao:
            yup.number()
                .typeError('Caso você não gaste com educação, por favor, digite "0"')
                .moreThan(-1, 'Educação: O valor deve ser positivo ou zero.')
                .max(99999.99, 'Educação: Foi excedido o limite de 5 números inteiros')
                .required("Caso você não gaste com educação, por favor, digite '0'"),

        valorEnergia:
            yup.number()
                .typeError('Caso você não gaste com energia, por favor, digite "0"')
                .moreThan(-1, 'Energia: O valor deve ser positivo ou zero.')
                .max(99999.99, 'Energia: Foi excedido o limite de 5 números inteiros')
                .required("Caso você não gaste com energia, por favor, digite '0'"),

        valorGasDeCozinha:
            yup.number()
                .typeError('Caso você não gaste com gás de energia, por favor, digite "0"')
                .moreThan(-1, 'Gás de Cozinha: O valor deve ser positivo ou zero.')
                .max(99999.99, 'Gás de Cozinha: Foi excedido o limite de 5 números inteiros')
                .required("Caso você não gaste com gás de energia, por favor, digite '0'"),

        valorMedicamentos:
            yup.number()
                .typeError('Caso você não gaste com medicamentos, por favor, digite "0"')
                .moreThan(-1, 'Medicamentos: O valor deve ser positivo ou zero.')
                .max(99999.99, 'Medicamentos: Foi excedido o limite de 5 números inteiros')
                .required("Caso você não gaste com medicamentos, por favor, digite '0'"),

        valorPlanoDeSaude:
            yup.number()
                .typeError('Caso você não gaste com plano de saúde, por favor, digite "0"')
                .moreThan(-1, 'Plano de Saúde: O valor deve ser positivo ou zero.')
                .max(99999.99, 'Plano de Saúde: Foi excedido o limite de 5 números inteiros')
                .required("Caso você não gaste com plano de saúde, por favor, digite '0'"),

        valorPlanos:
            yup.number()
                .typeError('Caso você não gaste com planos de assinatura, por favor, digite "0"')
                .moreThan(-1, 'Planos de Assinaturas: O valor deve ser positivo ou zero.')
                .max(99999.99, 'Planos de Assinaturas: Foi excedido o limite de 5 números inteiros')
                .required("Caso você não gaste com planos de assinatura, por favor, digite '0'"),

        valorTransporte:
            yup.number()
                .typeError('Caso você não gaste com transporte, por favor, digite "0"')
                .moreThan(-1, 'Transporte: O valor deve ser positivo ou zero.')
                .max(99999.99, 'Transporte: Foi excedido o limite de 5 números inteiros')
                .required("Caso você não gaste com transporte, por favor, digite '0'"),

        valorOutros:
            yup.number()
                .typeError('Caso você não gaste com outras coisas, por favor, digite "0"')
                .moreThan(-1, 'Outros gastos: O valor deve ser positivo ou zero.')
                .max(99999.99, 'Outros gastos: Foi excedido o limite de 5 números inteiros')
                .required("Caso você não gaste com outras coisas, por favor, digite '0'"),

    });

    // useform
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {

            "valorAgua": !!sessionStorage.getItem("valorAgua") ? sessionStorage.getItem("valorAgua") : 0,
            "valorAlimentacao": !!sessionStorage.getItem("valorAlimentacao") ? sessionStorage.getItem("valorAlimentacao") : 0,
            "valorAluguel": !!sessionStorage.getItem("valorAluguel") ? sessionStorage.getItem("valorAluguel") : 0,
            "valorEducacao": !!sessionStorage.getItem("valorEducacao") ? sessionStorage.getItem("valorEducacao") : 0,
            "valorEnergia": !!sessionStorage.getItem("valorEnergia") ? sessionStorage.getItem("valorEnergia") : 0,
            "valorGasDeCozinha": !!sessionStorage.getItem("valorGasDeCozinha") ? sessionStorage.getItem("valorGasDeCozinha") : 0,
            "valorMedicamentos": !!sessionStorage.getItem("valorMedicamentos") ? sessionStorage.getItem("valorMedicamentos") : 0,
            "valorOutros": !!sessionStorage.getItem("valorOutros") ? sessionStorage.getItem("valorOutros") : 0,
            "valorPlanoDeSaude": !!sessionStorage.getItem("valorPlanoDeSaude") ? sessionStorage.getItem("valorPlanoDeSaude") : 0,
            "valorPlanos": !!sessionStorage.getItem("valorPlanos") ? sessionStorage.getItem("valorPlanos") : 0,
            "valorSalario": !!sessionStorage.getItem("valorSalario") ? sessionStorage.getItem("valorsalario") : 0,
            "valorTransporte": !!sessionStorage.getItem("valorTransporte") ? sessionStorage.getItem("valorTransporte") : 0

        }
    });

    //Conexão com a API e próxima página
    // console.log(dados)

    function voltar(e) {
        // e.preventDefault();
        if (
            window.location.href == 'https://habitacao.piracicaba.sp.gov.br/#/cadastroFinanceiro' ||
            window.location.href == 'https://habita-teste.pmp.sp.gov.br/#/cadastroFinanceiro' ||
            window.location.href === 'https://localhost:88/#/cadastroFinanceiro' ||
            window.location.href === 'http://localhost:3000/#/cadastroFinanceiro'
        ) {
            getDados(endpoint2)
                .then((dados) => {
                    sessionStorage.setItem("renda", dados.renda);
                    sessionStorage.setItem("tipoDeRenda", dados.tipoDeRenda);
                    sessionStorage.setItem("profisao", dados.profisao);
                    sessionStorage.setItem("quantidadeDeResidentes", dados.quantidadeDeResidentes);
                    sessionStorage.setItem("nomeCompletoFamiliar", dados.nomeCompletoFamiliar);
                    sessionStorage.setItem("cpfFamiliar", dados.cpfFamiliar);
                    sessionStorage.setItem("rgFamiliar", dados.rgFamiliar);
                    sessionStorage.setItem("nivelParentescoFamiliar", dados.nivelParentescoFamiliar);
                    sessionStorage.setItem("dataNascimentoFamiliar", dados.dataNascimentoFamiliar);
                    sessionStorage.setItem("familiarDeficiente", dados.familiarDeficiente);
                    sessionStorage.setItem("tipoDeficienciaFamiliar", dados.tipoDeficienciaFamiliar);
                    sessionStorage.setItem("familiarDoencaCronica", dados.familiarDoencaCronica);
                    sessionStorage.setItem("descricaoDoencaCronicaFamiliar", dados.descricaoDoencaCronicaFamiliar);
                    sessionStorage.setItem("familiarDependente", dados.familiarDependente);

                    setDados(dados);
                    navigate('/cadastroDependentes');

                })
                .catch(() => setErro)
                .finally(() => {
                    setCarregando(false)
                });
        } else {
            getDadosById("/users/dependents", id)
                .then((dados) => {
                    sessionStorage.setItem("renda", dados.renda);
                    sessionStorage.setItem("tipoDeRenda", dados.tipoDeRenda);
                    sessionStorage.setItem("profisao", dados.profisao);
                    sessionStorage.setItem("quantidadeDeResidentes", dados.quantidadeDeResidentes);
                    sessionStorage.setItem("nomeCompletoFamiliar", dados.nomeCompletoFamiliar);
                    sessionStorage.setItem("cpfFamiliar", dados.cpfFamiliar);
                    sessionStorage.setItem("rgFamiliar", dados.rgFamiliar);
                    sessionStorage.setItem("nivelParentescoFamiliar", dados.nivelParentescoFamiliar);
                    sessionStorage.setItem("dataNascimentoFamiliar", dados.dataNascimentoFamiliar);
                    sessionStorage.setItem("familiarDeficiente", dados.familiarDeficiente);
                    sessionStorage.setItem("tipoDeficienciaFamiliar", dados.tipoDeficienciaFamiliar);
                    sessionStorage.setItem("familiarDoencaCronica", dados.familiarDoencaCronica);
                    sessionStorage.setItem("descricaoDoencaCronicaFamiliar", dados.descricaoDoencaCronicaFamiliar);
                    sessionStorage.setItem("familiarDependente", dados.familiarDependente);

                    setDados(dados);
                    navigate("/cadastroDependentes/edit/" + id);

                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        sessionStorage.removeItem("token");
                        sessionStorage.clear()
                        navigate('/');
                    } else {
                        setErro(err);
                    }
                })
                .finally(() => {
                    setCarregando(false)
                });
        }
    }
    async function onSubmit(data) {

        data.valorSalario = saldo;

        setCarregando(true);

        // const { complementarIncome, familiarIncome } = data;

        // let jsonToSend = { complementarIncome, familiarIncome };

        if (
            window.location.href == 'https://habitacao.piracicaba.sp.gov.br/#/cadastroFinanceiro' ||
            window.location.href == 'https://habita-teste.pmp.sp.gov.br/#/cadastroFinanceiro' ||
            window.location.href === 'https://localhost:88/#/cadastroFinanceiro' ||
            window.location.href === 'http://localhost:3000/#/cadastroFinanceiro'
        ) {
            schema.isValid(data)
                .then(isValid => {
                    getDados(endpoint3)
                        .then((dados) => {

                            setDados(dados);
                            console.log(dados);
                            navigate('/cadastroAnexos');

                        })
                        .catch((err) => {
                            setErro(err);
                        })
                        .finally(() => {
                            setCarregando(false)
                        });
                })
                .catch(err => console.log(err));

            incluirDados(endpoint, data)
                .then(response => console.log(response))
                .catch((err) => {
                    if (err.response.status === 403) {
                        sessionStorage.removeItem("token");
                        sessionStorage.clear()
                        navigate('/');
                    } else {
                        setErro(err);
                    }
                })
        } else {
            schema.isValid(data)
                .then(valid => {
                    if (valid) {

                        api.post('/users/finance/' + id, data)

                            .then(function (response) {
                                navigate('/cadastroAnexos/edit/' + id);
                                
                            })
                            .catch(function (err) {
                                if (err.response.status === 403) {
                                    sessionStorage.removeItem("token");
                                    sessionStorage.clear()
                                    navigate('/');
                                } else {
                                    setErro(err);
                                }
                            })
                            .finally((response) => {
                                setCarregando(false);
                            });
                    } else {

                        console.log("Formulário Invalido  ");
                    }
                })
                .catch(err => console.log(err))
                .finally(() => setCarregando(false))
        }
    }

    useEffect(() => {
        const salarios = salarioUser / 1320;
        setQtdSalarios(salarios.toFixed(2));
    })

    if (sessionStorage.getItem("rendaMensal") == undefined || isNaN(sessionStorage.getItem("rendaMensal"))) {
        sessionStorage.setItem("rendaMensal", 0)
    }

    const [saldo, setSaldo] = useState(sessionStorage.getItem("rendaMensal"));

    const [descontoAluguel, setDescontoAluguel] = useState(sessionStorage.getItem("valorAluguel") ? sessionStorage.getItem("valorAluguel") : 0);
    const [descontoAlimentacao, setDescontoAlimentacao] = useState(sessionStorage.getItem("valorAlimentacao") ? sessionStorage.getItem("valorAlimentacao") : 0);
    const [descontoAgua, setDescontoAgua] = useState(sessionStorage.getItem("valorAgua") ? sessionStorage.getItem("valorAgua") : 0);
    const [descontoEnergia, setDescontoEnergia] = useState(sessionStorage.getItem("valorEnergia") ? sessionStorage.getItem("valorEnergia") : 0);
    const [descontoGas, setDescontoGas] = useState(sessionStorage.getItem("valorGasDeCozinha") ? sessionStorage.getItem("valorGasDeCozinha") : 0);
    const [descontoTransporte, setDescontoTransporte] = useState(sessionStorage.getItem("valorTransporte") ? sessionStorage.getItem("valorTransporte") : 0);
    const [descontoEducacao, setDescontoEducacao] = useState(sessionStorage.getItem("valorEducacao") ? sessionStorage.getItem("valorEducacao") : 0);
    const [descontoSaude, setDescontoSaude] = useState(sessionStorage.getItem("valorPlanoDeSaude") ? sessionStorage.getItem("valorPlanoDeSaude") : 0);
    const [descontoMedicamentos, setDescontoMedicamentos] = useState(sessionStorage.getItem("valorMedicamentos") ? sessionStorage.getItem("valorMedicamentos") : 0);
    const [descontoEntretenimento, setDescontoEntretenimento] = useState(sessionStorage.getItem("valorPlanos") ? sessionStorage.getItem("valorPlanos") : 0);
    const [descontoOutros, setDescontoOutros] = useState(sessionStorage.getItem("valorOutros") ? sessionStorage.getItem("valorOutros") : 0);

    const handleChangeAluguel = (e) => {
        setDescontoAluguel(e.target.value);
    }
    const handleChangeAlimentacao = (e) => {
        setDescontoAlimentacao(e.target.value);
    }
    const handleChangeAgua = (e) => {
        setDescontoAgua(e.target.value);
    }
    const handleChangeEnergia = (e) => {
        setDescontoEnergia(e.target.value);
    }
    const handleChangeGas = (e) => {
        setDescontoGas(e.target.value);
    }
    const handleChangeTransporte = (e) => {
        setDescontoTransporte(e.target.value);
    }
    const handleChangeEducacao = (e) => {
        setDescontoEducacao(e.target.value);
    }
    const handleChangeSaude = (e) => {
        setDescontoSaude(e.target.value);
    }
    const handleChangeMedicamentos = (e) => {
        setDescontoMedicamentos(e.target.value);
    }
    const handleChangeEntretenimento = (e) => {
        setDescontoEntretenimento(e.target.value);
    }
    const handleChangeOutros = (e) => {
        setDescontoOutros(e.target.value);
    }

    const descontos = () => {
        let descontosSoma = 0;
        descontosSoma = parseFloat(descontoAluguel) + parseFloat(descontoAlimentacao) + parseFloat(descontoAgua) + parseFloat(descontoEnergia);
        descontosSoma += parseFloat(descontoGas) + parseFloat(descontoTransporte) + parseFloat(descontoEducacao) + parseFloat(descontoSaude);
        descontosSoma += parseFloat(descontoMedicamentos) + parseFloat(descontoEntretenimento) + parseFloat(descontoOutros);

        return descontosSoma;
    }


    return (
        <div className="BackgroundImg">
            <Header/>
            {/* {sessionStorage.setItem("valoralimentacao", dados.valoralimentacao)} */}
            <div className='mx-auto my-4 rounded-2xl bg-white shadow-xl md:w-2/3 pb-6 p-1'>
                <Stepper steps={steps} currentStep={currentStep} />
                <div className="container horizontal mt-8">
                    <div className="my-0 p-10">
                        <StepperContext.Provider value={{
                            stepData,
                            setStepData,
                            finalData,
                            setFinalData
                        }}>
                        </StepperContext.Provider>
                        <div className="flex flex-col">
                            <div className='flex justify-between items-center mt-6'>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={12} sm={6}>
                                        <h1 style={{
                                            fontFamily: 'Exo-SemiBold',
                                            fontSize: '32px'
                                        }}>
                                            Gasto mensal (Familiar)
                                        </h1>
                                        <h1 style={{
                                            fontFamily: 'Exo-SemiBold',
                                            fontSize: '24px'
                                        }}>
                                            Despesas Mensal Familiar:
                                        </h1>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <p
                                            style={{
                                                fontFamily: 'Exo-SemiBold',
                                                fontSize: '22px',
                                                textAlign: 'end'
                                            }}
                                        >
                                            {sessionStorage.getItem("cpf")}
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: 'Exo-SemiBold',
                                                fontSize: '22px',
                                                textAlign: 'end'
                                            }}
                                        >
                                            {sessionStorage.getItem("userName")}
                                        </p>
                                    </Grid>
                                </Grid>
                            </div>
                            <br />
                            <Form onSubmitCapture={handleSubmit(onSubmit)} name="cadastraFinanceiro">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="valorAluguel"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel htmlFor="outlined-adornment-amount">Aluguel:</InputLabel>
                                                        <OutlinedInput
                                                            style={{ width: '100%' }}
                                                            type='number'
                                                            inputProps={{
                                                                step: "0.01"
                                                            }}
                                                            name="valorAluguel"
                                                            placeholder="Aluguel"
                                                            defaultValue={sessionStorage.getItem("valorAluguel")}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                            label="Aluguel"
                                                            {...field}
                                                            onBlur={handleChangeAluguel}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="valorAlimentacao"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel htmlFor="outlined-adornment-amount">Alimentação: </InputLabel>
                                                        <OutlinedInput
                                                            name="valorAlimentacao"
                                                            style={{ width: '100%' }}
                                                            inputProps={{
                                                                step: "0.01"
                                                            }}
                                                            type='number'
                                                            placeholder="Alimentação"
                                                            defaultValue={sessionStorage.getItem("valorAlimentacao")}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                            label="Alimentação"
                                                            {...field}
                                                            onBlur={handleChangeAlimentacao}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="valorAgua"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel htmlFor="outlined-adornment-amount">Água: </InputLabel>
                                                        <OutlinedInput
                                                            style={{ width: '100%' }}
                                                            inputProps={{
                                                                step: "0.01",
                                                            }}
                                                            type='number'
                                                            name="valorAgua"
                                                            placeholder="Água (SEMAE)"
                                                            defaultValue={sessionStorage.getItem("valorAgua")}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                            label="Água *"
                                                            {...field}
                                                            onBlur={handleChangeAgua}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="valorEnergia"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel htmlFor="outlined-adornment-amount">Energia: </InputLabel>
                                                        <OutlinedInput
                                                            style={{ width: '100%' }}
                                                            type='number'
                                                            inputProps={{
                                                                step: "0.01"
                                                            }}
                                                            name="valorEnergia"
                                                            placeholder="Energia (CPFL)"
                                                            defaultValue={sessionStorage.getItem("valorEnergia")}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                            label="Energia"
                                                            {...field}
                                                            onBlur={handleChangeEnergia}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="valorGasDeCozinha"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel htmlFor="outlined-adornment-amount">Gás:</InputLabel>
                                                        <OutlinedInput
                                                            style={{ width: '100%' }}
                                                            inputProps={{
                                                                step: "0.01"
                                                            }}
                                                            type='number'
                                                            name="valorGasDeCozinha"
                                                            placeholder="Gás de Cozinha"
                                                            defaultValue={sessionStorage.getItem("valorGasDeCozinha")}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                            label="Gás"
                                                            {...field}
                                                            onBlur={handleChangeGas}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="valorTransporte"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel htmlFor="outlined-adornment-amount">Transporte:</InputLabel>
                                                        <OutlinedInput
                                                            style={{ width: '100%' }}
                                                            inputProps={{
                                                                step: "0.01"
                                                            }}
                                                            type='number'
                                                            name="valorTransporte"
                                                            placeholder="Transporte"
                                                            defaultValue={sessionStorage.getItem("valorTransporte")}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                            label="Transporte"
                                                            {...field}
                                                            onBlur={handleChangeTransporte}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="valorEducacao"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel htmlFor="outlined-adornment-amount">Educação:</InputLabel>
                                                        <OutlinedInput
                                                            style={{ width: '100%' }}
                                                            inputProps={{
                                                                step: "0.01"
                                                            }}
                                                            type='number'
                                                            name="valorEducacao"
                                                            placeholder="Educação/ Curso"
                                                            defaultValue={sessionStorage.getItem("valorEducacao")}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                            label="Educação"
                                                            {...field}
                                                            onBlur={handleChangeEducacao}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="valorPlanoDeSaude"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel htmlFor="outlined-adornment-amount">Saúde:</InputLabel>
                                                        <OutlinedInput
                                                            style={{ width: '100%' }}
                                                            inputProps={{
                                                                step: "0.01"
                                                            }}
                                                            type='number'
                                                            name="valorPlanoDeSaude"
                                                            placeholder="Plano de Saúde"
                                                            defaultValue={sessionStorage.getItem("valorPlanoDeSaude")}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                            label="Saúde"
                                                            {...field}
                                                            onBlur={handleChangeSaude}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="valorMedicamentos"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel htmlFor="outlined-adornment-amount">Medicamentos:</InputLabel>
                                                        <OutlinedInput
                                                            style={{ width: '100%' }}
                                                            inputProps={{
                                                                step: "0.01"
                                                            }}
                                                            type='number'
                                                            name="valorMedicamentos"
                                                            placeholder="Medicamentos"
                                                            defaultValue={sessionStorage.getItem("valorMedicamentos")}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                            label="Medicamentos"
                                                            {...field}
                                                            onBlur={handleChangeMedicamentos}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="valorPlanos"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel htmlFor="outlined-adornment-amount">Telefone/ Internet/ TV:</InputLabel>
                                                        <OutlinedInput
                                                            style={{ width: '100%' }}
                                                            type='number'
                                                            inputProps={{
                                                                step: "0.01"
                                                            }}
                                                            name="valorPlanos"
                                                            placeholder="Planos de TV/ Celular/ Internet"
                                                            defaultValue={sessionStorage.getItem("valorPlanos")}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                            label="Telefone/ Internet/ TV:"
                                                            {...field}
                                                            onBlur={handleChangeEntretenimento}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="valorOutros"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel htmlFor="outlined-adornment-amount">Outros:</InputLabel>
                                                        <OutlinedInput
                                                            style={{ width: '100%' }}
                                                            inputProps={{
                                                                step: "0.01"
                                                            }}
                                                            type='valorOutros'
                                                            name="health"
                                                            placeholder="Outros"
                                                            defaultValue={sessionStorage.getItem("valorOutros")}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                            label="Amount"
                                                            {...field}
                                                            onBlur={handleChangeOutros}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <div className='space-around mt-8'>
                                    <h5 style={{ fontFamily: 'Exo-SemiBold', fontSize: '18px', marginTop: '4px' }}>
                                        Renda total da família:&nbsp;
                                        {
                                            parseInt(sessionStorage.getItem("rendaMensal"))
                                                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    </h5>

                                    <h5 style={{ fontFamily: 'Exo-SemiBold', fontSize: '18px', marginTop: '4px' }}>
                                        Total de Descontos:&nbsp;
                                        {(
                                            descontos().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        )}
                                    </h5>

                                    <h5 style={{ fontFamily: 'Exo-SemiBold', fontSize: '18px', marginTop: '4px' }}>
                                        Renda per capita:&nbsp;
                                        {(
                                            (sessionStorage.getItem("rendaMensal") / (parseInt(sessionStorage.getItem("qtdResidentes")) + 1))
                                                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

                                        )}
                                    </h5>

                                    <h5 style={{ fontFamily: 'Exo-SemiBold', fontSize: '18px', marginTop: '4px' }}>
                                        Saldo da família:&nbsp;
                                        {(
                                            (saldo - descontos()).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        )}
                                    </h5>


                                    <p style={{ fontFamily: 'Exo-SemiBold', fontSize: '15px', marginTop: 40 }}>*Fórmula Usada:</p>
                                    <p style={{ fontFamily: 'Exo-SemiBold', fontSize: '15px' }}>*Saldo da Família = Renda Total da família - Despesas</p>
                                </div>

                                <br />

                                {errors.valorAgua && <Alert severity="error">{errors.valorAgua?.message}</Alert>}
                                {errors.valorEnergia && <Alert severity="error">{errors.valorEnergia?.message}</Alert>}
                                {errors.valorEducacao && <Alert severity="error">{errors.valorEducacao?.message}</Alert>}
                                {errors.valorAluguel && <Alert severity="error">{errors.valorAluguel?.message}</Alert>}
                                {errors.valorAlimentacao && <Alert severity="error">{errors.valorAlimentacao?.message}</Alert>}
                                {errors.valorGasDeCozinha && <Alert severity="error">{errors.valorGasDeCozinha?.message}</Alert>}
                                {errors.valorMedicamentos && <Alert severity="error">{errors.valorMedicamentos?.message}</Alert>}
                                {errors.valorPlanoDeSaude && <Alert severity="error">{errors.valorPlanoDeSaude?.message}</Alert>}
                                {errors.valorPlanos && <Alert severity="error">{errors.valorPlanos?.message}</Alert>}
                                {errors.valorTransporte && <Alert severity="error">{errors.valorTransporte?.message}</Alert>}
                                {errors.valorOutros && <Alert severity="error">{errors.valorOutros?.message}</Alert>}

                                <div className='flex justify-between'>
                                    <LoadingButton
                                        style={{
                                            padding: '16px',
                                            borderRadius: '12px'
                                        }}
                                        variant='outlined'
                                        size="large"
                                        onClick={voltar}
                                    >
                                        Voltar
                                    </LoadingButton>
                                    <LoadingButton
                                        style={{
                                            backgroundColor: '#3F51b5',
                                            padding: '16px',
                                            borderRadius: '12px'
                                        }}
                                        loading={carregando}
                                        type='submit'
                                        variant='contained'
                                        size="large"
                                    >
                                        Próximo
                                    </LoadingButton>
                                </div>

                                <input type="hidden" name="showFinanceiro" value={showFinanceiroValue} />
                            </Form>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}