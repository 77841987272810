import React from 'react';
import { Box, Container, Grid, Paper } from '@mui/material';
import logo1 from "../Assets/logo-pt1.png";
import logo2 from "../Assets/logo-pt2.png";
import { useMediaQuery } from 'react-responsive'
import styleHeader from '../Components/Header/styles';
import { useNavigate } from 'react-router-dom';
import { BorderColor } from '@mui/icons-material';


export const CadastroCidadao = () => {

    const navigate = useNavigate()

    const handleLogout = () => {
        navigate("/");
    }

    const titleHeader = useMediaQuery({
        query: '(min-width: 640px)'
    })

    return (
        <div className="BackgroundImg" style={{ textAlign: 'center' }}>
            <Box sx={styleHeader.header}>
                <Box sx={styleHeader.logoContainer}>
                    <img
                        style={styleHeader.imgLogo}
                        src={logo1}
                        height='50'
                        onClick={handleLogout}
                        alt=''
                    />
                </Box>
                {titleHeader &&
                    <Box sx={styleHeader.titulo}>
                        CADASTRO DE DEMANDA PARA HABITAÇÃO DE INTERESSE SOCIAL
                    </Box>
                }

                {/* <h1
                        style={{
                            fontSize: '28px',
                            fontWeight: '800',
                            color: '#F78C2F'
                        }}
                    >
                        HOMOLOGAÇÃO
                    </h1> */}

                <Box sx={styleHeader.logoContainer}>
                    <img
                        style={styleHeader.imgLogo2}
                        src={logo2}
                        height='50'
                        alt=''
                        onClick={handleLogout}
                    />
                </Box>
            </Box>

            <Container sx={{ pt: '8vh' }}>
                <Paper
                    sx={{
                        p: 4,
                        maxWidth: 760,
                        margin: 'auto',
                        border: '4px solid #FF8C00',
                        borderRadius: '16px',
                        backgroundColor: '#e9e9e9'
                    }}
                >
                    <Box
                        sx={{
                            mb: 3,
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Grid container spacing={2} sx={{ textAlign: 'center', justifyContent: 'center', alignContent: 'flex-start' }}>

                            <Grid item xs={12} sm={12}>
                                <p className=" mr-2 text-2xl font-bold  ">
                                    Você não possui um cadastro no site "Cadastro do Cidadão"
                                </p>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <a
                                    target='_blank'
                                    href='https://cidadao.piracicaba.sp.gov.br/'
                                    className=" text-lg font-semibold uppercase text-blue-800 underline"
                                >
                                    Clique aqui para ser movido ao outro site
                                </a>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <p className=" mr-2 text-l font-semibold uppercase text-orange-500">
                                    Após ter realizado o seu cadastro, volte aqui para tentar novamente!
                                </p>
                            </Grid>

                        </Grid>
                    </Box>
                </Paper>
            </Container>
        </div >
    )

}