import { Route, Navigate, Routes, Outlet, HashRouter } from 'react-router-dom';
import { VerificacaoEmail } from './Pages/RestaurarConta/VerificacaoEmail';
import { ConfirmacaoSenha } from './Pages/RestaurarConta/ConfirmacaoSenha';
import { CadDadosPessoais } from './Pages/FormsCadastro/cadDadosPessoais';
import { CadDependentes } from './Pages/FormsCadastro/cadDependentes';
import { CadFinanceiro } from './Pages/FormsCadastro/cadFinanceiro';
import { CadFinalizado } from './Pages/FormsCadastro/cadFinalizado';
import { AlterarSenha } from './Pages/RestaurarConta/AlterarSenha';
import { RestaurarConta } from './Pages/RestaurarConta/EnviarCpf';
import { CadMoradia } from './Pages/FormsCadastro/cadMoradia';
import { CadAnexos } from './Pages/FormsCadastro/cadAnexos';
import { Login } from './Pages/Login';
import React from 'react';
import './App.css';


import { VerificacaoCadastro } from './Pages/VerificacaoCadastro';
import { AuthProvider, isAuthenticated } from "./Services/auth";
import { HomeAdm } from './Pages/HomeAdm';
import { Cadastro } from './Pages/Cadastro';
import { CadastroCidadao } from './Pages/CadastroCidadao';

function App() {

  const PrivateRoute = () => {
    return isAuthenticated() ? <Outlet /> : <Navigate to={'/'} />
  }

  return (
    <HashRouter>
      <div className="App" style={{ height: '100vh' }}>
        <AuthProvider>
          <Routes>
            <Route exact path="/" element={<Cadastro />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cadastro/verificacaoCadastro" element={<VerificacaoCadastro />} />
            <Route path="/cadastroCidadao" element={<CadastroCidadao />} />
            <Route path="/restaurarConta" element={<RestaurarConta />} />
            <Route path="/restaurarConta/verificacaoEmail" element={<VerificacaoEmail />} />
            <Route path="/restaurarConta/verificacaoEmail/alterarSenha/:token" element={<AlterarSenha />} />
            <Route path="/restaurarConta/verificacaoEmail/alterarSenha/confirmacaoSenha" element={<ConfirmacaoSenha />} />

            <Route element={<PrivateRoute />}>
              <Route element={<Outlet />}>

                <Route path="/admin" element={<HomeAdm />} />
                <Route path="/cadastroAnexos" element={<CadAnexos />} />
                <Route path="/cadastroPessoal" element={<CadDadosPessoais />} />
                <Route path="/cadastroDependentes" element={<CadDependentes />} />
                <Route path="/cadastroMoradia" element={<CadMoradia />} />
                <Route path="/cadastroFinanceiro" element={<CadFinanceiro />} />
                <Route path="/cadastroFinalizado" element={<CadFinalizado />} />

                <Route path="/cadastroPessoal/edit/:id" element={<CadDadosPessoais  />} />
                <Route path="/cadastroMoradia/edit/:id" element={<CadMoradia />} />
                <Route path="/cadastroDependentes/edit/:id" element={<CadDependentes />} />
                <Route path="/cadastroFinanceiro/edit/:id" element={<CadFinanceiro />} />
                <Route path="/cadastroAnexos/edit/:id" element={<CadAnexos />} />
                <Route path="/cadastroFinalizado/edit/:id" element={<CadFinalizado />} />
              </Route>
            </Route>
          </Routes>
        </AuthProvider>
      </div >
    </HashRouter>
  );
}

export default App;
