const styleModal = {

    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        textAlign: 'center',
        bgcolor: 'background.paper',
        borderRadius: '16px',
        border: '4px solid orange',
        boxShadow: 24,
        background: 'rgb(34,29,124)',
        background: 'linear-gradient(9deg, rgba(34,29,124,1) 0%, rgba(46,29,149,1) 39%, rgba(47,34,131,1) 100%)',
        p: 2,
    },

    logotipo: {
        width: '200px',
        border: '4px solid orange',
        borderRadius: '32px',
        padding: '4px',
        background: 'rgb(34,29,124)',
        background: 'linear-gradient(9deg, rgba(34,29,124,1) 0%, rgba(46,29,149,1) 39%, rgba(47,34,131,1) 100%)',
        position: 'relative',
        marginTop: '-60px',
        alignItems: 'center',
    }
}

export default styleModal