//_1 Importações 
// _1.1 Api Cidade e estado
//_2 Funções para o Stepper
//_3 States e Funções dos selects
//_4 Validação pelo yup
//_5 UseForm
//_6 API
//_7 Formulário "Dados Pessoais"
//_8 Formulário "Dados Profissionais"
//_9 Mensagem de erro (validação)

//_1 Importações
//Importações do React
import { useEffect, useState } from 'react';
import { format } from 'date-fns'
import React from 'react';
import { useParams } from 'react-router-dom';

// Importações do MUI 
import { Box, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';

//impotação da API
import { getDados } from '../../Services/globalRequests';
import api from "../../Services/api";
import axios from 'axios';


//   Importações do "Stepper" 
import { StepperContext } from "../../Contexts/StepperContext";
import Stepper from '../../Components/Stepper/Stepper';

// Importações de estilo (.css e imagens)
import BackgroundImg from '../../Components/Background/estiloPages.css';
import styles from '../../Components/Stepps/InputStyle.module.css';

// Importações para o formulário
import { IMaskInput } from "react-imask";

//Importações yup
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Demais importações
import { Header } from '../../Components/Header';
import { useNavigate } from 'react-router-dom';
import { getDadosById } from '../../Services/globalRequests';
import { data } from 'autoprefixer';

const handleInputChange = (event) => {
  let inputValue = event.target.value;

  // Remove qualquer não-dígito
  inputValue = inputValue.replace(/\D/g, '');

  // Limita a dois dígitos
  inputValue = inputValue.slice(0, 2);

  // Atualiza o valor do campo
  event.target.value = inputValue;
};

export const CadDadosPessoais = ({
  endpoint = "/users/addresses",
  endpoint2 = "/users/data"
}) => {
  const { id } = useParams();

  //_2 Funções para o Stepper ==========================================================================
  const [carregando, setCarregando] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [finalData, setFinalData] = useState([]);
  const [stepData, setStepData] = useState('');
  const navigate = useNavigate();

  const steps = [
    "Dados Pessoais",
    "Endereço Atual",
    "Composição e Renda Familiar",
    "Gasto mensal (Familiar)",
    "Anexos",
    "Finalizado"
  ];

  const style = {
    containerStepper: {
      height: 'auto',
      margin: 'auto',
      marginTop: '80px'
    }
  }


  //_3 States e Funções dos selects =======================================================================
  const [selectedNaturality, setSelectedNaturality] = useState(!sessionStorage.getItem("naturalidadeCidade") ? '' : sessionStorage.getItem("naturalidadeCidade"));
  const [selectedNaturality2, setSelectedNaturality2] = useState(!sessionStorage.getItem("cidadeDeVoto") ? '' : sessionStorage.getItem("cidadeDeVoto"));
  const [selectedUf, setSelectedUf] = useState(!sessionStorage.getItem("naturalidadeEstado") ? '' : sessionStorage.getItem("naturalidadeEstado"));
  const [selectedUf2, setSelectedUf2] = useState(!sessionStorage.getItem("estadoDeVoto") ? '' : sessionStorage.getItem("estadoDeVoto"));
  const [selectedRadioPublico, setSelectedRadioPublico] = useState('false');
  const [selectedRadioVotacao, setSelectedRadioVotacao] = useState('true');
  const [selectedRadioDoenca, setSelectedRadioDoenca] = useState('false');
  const [openIdentidadeGenero, setOpenIdentidadeGenero] = useState(false);
  const [campoDesabilitado, setCampoDesabilitado] = useState(false);
  const [cartaoCidadaoValue, setCartaoCidadaoValue] = useState([""]);
  const [selectedRadioGF, setSelectedRadioGF] = useState('false')
  const [openDeficiencia, setOpenDeficiencia] = useState(false);
  const [identidadeGenero, setIdentidadeGenero] = useState('');
  const [selectedRadio, setSelectedRadio] = useState('false');
  const [openRelacao, setOpenRelacao] = React.useState(false);
  const [escolaridade, setEscolaridade] = React.useState('');
  const [openPaises, setOpenPaises] = React.useState(false);
  const [dataPiracicaba, setDataPiracicaba] = useState();
  const [naturality2, setNaturality2] = useState(['']);
  const [deficiencia, setDeficiencia] = useState('');
  const [naturality, setNaturality] = useState(['']);
  const [relacao, setRelacao] = React.useState('');
  const [openSexo, setOpenSexo] = useState(false);
  const [paises, setPaises] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openCor, setOpenCor] = useState(false);
  const [sexo, setSexo] = useState('');
  const [ufs2, setUfs2] = useState([]);
  const [cor, setCor] = useState('');
  const [ufs, setUfs] = useState([]);

  // if(typeof sessionStorage.getItem("estado") != null){
  //     setSelectedUf(sessionStorage.getItem("estado"));
  // }

  //CIDADE ESTADO NATURALIDADE
  useEffect(() => {
    const nacionalidade = sessionStorage.getItem("nacionalidade")
    setCampoDesabilitado(nacionalidade !== "Brasil")
    console.log("campo desabilitado =>", campoDesabilitado);
    axios

      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome")
      .then((response) => {
        setUfs(response.data);
      });
  }, [selectedUf]);
  useEffect(() => {
    if (selectedUf === '') {
      return;
    }

    axios
      .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios?orderBy=nome`)
      .then((response) => {
        setNaturality(response.data);
      });
  }, [selectedUf]);

  function handleSelectUf(event) {
    setSelectedUf(event.target.value);
  }

  function handleSelectUf2(event) {
    setSelectedUf2(event.target.value);
  }

  function handleSelectNaturality(event) {
    setSelectedNaturality(event.target.value);
    console.log(event.target.value);
  }
  function handleSelectNaturality2(event) {
    setSelectedNaturality2(event.target.value);
    console.log(event.target.value);
  }

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome")
      .then((response2) => {
        setUfs2(response2.data);
      });
  }, [selectedUf]);
  useEffect(() => {
    if (selectedUf2 === '') {
      return;
    }

    axios
      .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf2}/municipios?orderBy=nome`)
      .then((response2) => {
        console.log(selectedUf2)
        setNaturality2(response2.data);
      });
  }, [selectedUf2]);

  const handleChangeSexo = (event) => {
    setSexo(event.target.value);
  }

  const handleChangeIdentidadeGenero = (event) => {
    setIdentidadeGenero(event.target.value);
  }

  const handleChangeCor = (event) => {
    setCor(event.target.value);
  }

  const handleChangeEscolaridade = (event) => {
    setEscolaridade(event.target.value);
  };

  const handleChangeDeficiencia = (event) => {
    setDeficiencia(event.target.value);
  }

  const handleChangeRelacao = (event) => {
    setRelacao(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseSexo = () => {
    setOpenSexo(false);
  }

  const handleOpenSexo = () => {
    setOpenSexo(true)
  }

  const handleCloseIdentidadeGenero = () => {
    setOpenIdentidadeGenero(false);
  }

  const handleOpenIdentidadeGenero = () => {
    setOpenIdentidadeGenero(true)
  }

  const handleCloseCor = () => {
    setOpenCor(false);
  }

  const handleOpenCor = () => {
    setOpenCor(true)
  }

  const handleCloseDeficiencia = () => {
    setOpenDeficiencia(false);
  }

  const handleOpenDeficiencia = () => {
    setOpenDeficiencia(true)
  }

  const handleCloseRelacao = () => {
    setOpenRelacao(false);
  };
  const handleOpenRelacao = () => {
    setOpenRelacao(true);
  };

  const handleClosePaises = () => {
    setOpenPaises(false);
  };
  const handleOpenPaises = () => {
    setOpenPaises(true);
  };

  const handleChangeValue = (event) => {
    setSelectedRadio(event.target.value);
  }

  const handleChangeValuePublico = (event) => {
    setSelectedRadioPublico(event.target.value);
  }

  const handleChangeValueDoenca = (event) => {
    setSelectedRadioDoenca(event.target.value);
  }

  const handleChangeCadastroGF = (event) => {
    setSelectedRadioGF(event.target.value);
  }

  const handleChangePaises = (e) => {
    const selectedValue = e.target.value;
    setPaises(selectedValue)

    if (selectedValue === 'Brasil') {
      setCampoDesabilitado(false)
    } else {
      setCampoDesabilitado(true)
      if (campoDesabilitado) {
        sessionStorage.setItem("naturalidadeCidade", "");
      }
    }
    sessionStorage.setItem("nacionalidade", selectedValue);
    console.log("campo desabilitado =>", campoDesabilitado);
    setCampoDesabilitado(selectedValue !== "Brasil");
  }

  const handleChangeValueVotacao = (event) => {
    setSelectedRadioVotacao(event.target.value);
  }

  //_4 Validação pelo yup =================================================================================
  var schema = yup.object().shape({

    nome:
      yup.string()
        .max(120, "Por favor, nome com até 120 caracteres!")
        .required("Por favor, nos informe seu nome completo."),

    genero:
      yup.string(),

    possuiDeficiencia:
      yup.boolean()
        .required("Por favor, nos diga se você é portador de alguma deficiência."),

    tipoDeDeficiencia:
      yup.string(),

    escolaridade:
      yup.string()
        .required("Por favor, nos informe seu nível de escolaridade."),


    estadoCivil:
      yup.string()
        .required("Por favor, nos informe o seu estado civil no momento."),

    nacionalidade:
      yup.string(),
    // .required("Por favor, nos informe sua nacionalidade."),

    naturalidadeEstado:
      yup.string(),
    // .required("Por favor, nos informe o seu estado"),

    // naturality:
    //     yup.string()
    //         .required("Por favor, nos informe sua naturalidade."),

    rg:
      yup.string()
        .required("Por favor, nos informe o seu RG")
        .max(12, "Por favor, RG com no máximo 12 caracteres!"),

    dataDeNascimento:
      yup.date()
        .typeError("Por favor, nos informe sua data de nascimento.")
        .required("Por favor, nos informe sua data de nascimento."),

    sexoNascimento:
      yup.string()
        .typeError("Por favor, nos informe o seu sexo de nascimento")
        .required("Por favor, nos informe o seu sexo de nascimento"),

    votaEmPiracicaba:
      yup.boolean(),

    possuiDoencaCronica:
      yup.boolean(),

    cadastradoGovernoFederal:
      yup.boolean(),

    votaEmPiracicabaDesde:
      yup.number(),

    moraEmPiracicabaDesde:
      yup.number()
        .typeError('Por favor, nos informe há quantos anos você mora em Piracicaba')
        .required("Por favor, nos informe há quantos anos você mora em Piracicaba"),

  });



  //_5 UseForm =================================================================================================
  const { register, handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      "cadastradoGovernoFederal": !!sessionStorage.getItem("cadastradoGovernoFederal") ? sessionStorage.getItem("cadastradoGovernoFederal") : false,
      "emailMutuario": !!sessionStorage.getItem("emailMutuario") ? sessionStorage.getItem("emailMutuario") : sessionStorage.getItem("emailLogin"),
      "dataDeNascimento": !!sessionStorage.getItem("dataDeNascimento") ? sessionStorage.getItem("dataDeNascimento").substring(0, 10) : NaN,
      "doencaCronicaDescricao": (sessionStorage.getItem("doencaCronicaDescricao") !== undefined && sessionStorage.getItem("doencaCronicaDescricao") !== 'undefined') ? sessionStorage.getItem("doencaCronicaDescricao") : "",
      "moraEmPiracicabaDesde": (sessionStorage.getItem("moraEmPiracicabaDesde") !== undefined && sessionStorage.getItem("moraEmPiracicabaDesde") !== "undefined") ? sessionStorage.getItem("moraEmPiracicabaDesde").substring(0, 10) : "",
      "votaEmPiracicabaDesde": (sessionStorage.getItem("votaEmPiracicabaDesde") !== undefined && sessionStorage.getItem("votaEmPiracicabaDesde") !== "undefined") ? sessionStorage.getItem("votaEmPiracicabaDesde") : 0,
      "possuiDoencaCronica": !!sessionStorage.getItem("possuiDoencaCronica") ? sessionStorage.getItem("possuiDoencaCronica") : false,
      "contatoCelularRecado": (sessionStorage.getItem("contatoCelularRecado") !== undefined && sessionStorage.getItem("contatoCelularRecado") !== "undefined") ? sessionStorage.getItem("contatoCelularRecado") : "",
      "naturalidadeEstado": !!sessionStorage.getItem("naturalidadeEstado") ? sessionStorage.getItem("naturalidadeEstado") : "",
      "possuiDeficiencia": !!sessionStorage.getItem("possuiDeficiencia") ? sessionStorage.getItem("possuiDeficiencia") : false,
      "naturalidadeCidade": !!sessionStorage.getItem("naturalidadeCidade") ? sessionStorage.getItem("naturalidadeCidade") : 0,
      "votaEmPiracicaba": !!sessionStorage.getItem("votaEmPiracicaba") ? sessionStorage.getItem("votaEmPiracicaba") : true,
      "tipoDeDeficiencia": !!sessionStorage.getItem("tipoDeDeficiencia") ? sessionStorage.getItem("tipoDeDeficiencia") : 0,
      "nome": !!sessionStorage.getItem("nome") ? sessionStorage.getItem("userName") : sessionStorage.getItem("userName"),
      "nacionalidade": !!sessionStorage.getItem("nacionalidade") ? sessionStorage.getItem("nacionalidade") : 0,
      "celularDeRecado": (sessionStorage.getItem("celularDeRecado") !== undefined && sessionStorage.getItem("celularDeRecado") !== "undefined") ? sessionStorage.getItem("celularDeRecado") : "",
      "escolaridade": !!sessionStorage.getItem("escolaridade") ? sessionStorage.getItem("escolaridade") : "",
      "cidadeDeVoto": (sessionStorage.getItem("cidadeDeVoto") !== undefined && sessionStorage.getItem("cidadeDeVoto") !== "undefined") ? sessionStorage.getItem("cidadeDeVoto") : "",
      "estadoDeVoto": (sessionStorage.getItem("estadoDeVoto") !== undefined && sessionStorage.getItem("estadoDeVoto") !== "undefined") ? sessionStorage.getItem("estadoDeVoto") : "",
      "estadoCivil": !!sessionStorage.getItem("estadoCivil") ? sessionStorage.getItem("estadoCivil") : "",
      "nomeSocial": (sessionStorage.getItem("nomeSocial") !== undefined && sessionStorage.getItem("nomeSocial") !== 'undefined' && sessionStorage.getItem("nomeSocial") !== null && sessionStorage.getItem("nomeSocial") !== 'null') ? sessionStorage.getItem("nomeSocial") : "",
      "celular": !!sessionStorage.getItem("celular") ? sessionStorage.getItem("celular") : "",
      "identidadeGenero": !!sessionStorage.getItem("identidadeGenero") ? sessionStorage.getItem("identidadeGenero") : 0,
      "sexoNascimento": !!sessionStorage.getItem("sexoNascimento") ? sessionStorage.getItem("sexoNascimento") : null,
      "racaCor": !!sessionStorage.getItem("racaCor") ? sessionStorage.getItem("racaCor") : 0,
      "rg": !!sessionStorage.getItem("rg") ? sessionStorage.getItem("rg") : "",
    }
  });
  //_6 Conexão com a API =======================================================================================

  const [dados, setDados] = useState('');
  const [erro, setErro] = useState();

  async function onSubmit(data) {

    setCarregando(true);

    console.log(data); // data recebida do useForm

    data.naturalidadeCidade = selectedNaturality;
    data.naturalidadeEstado = selectedUf;

    data.estadoDeVoto = selectedUf2;
    data.cidadeDeVoto = selectedNaturality2;
    data.emailCadastro = sessionStorage.getItem("emailLogin");

    // Verifica se os campos estão desabilitados
    if (campoDesabilitado) {
      data.naturalidadeEstado = '';
      data.naturalidadeCidade = '';
    }

    if (window.location.href == 'https://habitacao.piracicaba.sp.gov.br/#/cadastroPessoal' || window.location.href == 'https://habita-teste.pmp.sp.gov.br/#/cadastroPessoal' || window.location.href === 'https://localhost:88/#/cadastroPessoal' || window.location.href === 'http://localhost:3000/#/cadastroPessoal') {
      api.post('/users/data', data)
        .then(function (response) {

          getDados(endpoint)
            .then((dados) => {
              if (dados.cep) {
                sessionStorage.setItem("cep", dados.cep);
                sessionStorage.setItem("bairro", dados.bairro);
                sessionStorage.setItem("rua", dados.rua);
                sessionStorage.setItem("numero", dados.numero);
                sessionStorage.setItem("estado", dados.estado);
                sessionStorage.setItem("cidade", dados.cidade);
              }

              sessionStorage.setItem("tempoResidindoNaMoradiaAtual", dados.tempoResidindoNaMoradiaAtual);
              sessionStorage.setItem("tipoResidencia", dados.tipoResidencia);
              sessionStorage.setItem("condicaoDaMoradia", dados.condicaoDaMoradia);
              sessionStorage.setItem("referencia", dados.referencia);
              sessionStorage.setItem("complemento", dados.complemento);
              sessionStorage.setItem("possuiBanheiro", dados.possuiBanheiro);
              sessionStorage.setItem("moradorDeRua", dados.moradorDeRua);
              sessionStorage.setItem("quantidadeDeBanheiros", dados.quantidadeDeBanheiros);
              sessionStorage.setItem("quantidadeDeComodos", dados.quantidadeDeComodos);
              sessionStorage.setItem("possuiEncanamento", dados.possuiEncanamento);
              sessionStorage.setItem("possuiEletricidade", dados.possuiEletricidade);
              sessionStorage.setItem("possuiEsgoto", dados.possuiEsgoto);
              sessionStorage.setItem("possuiColetaDeLixo", dados.possuiColetaDeLixo);
              sessionStorage.setItem("possuiAsfalto", dados.possuiAsfalto);
              sessionStorage.setItem("tempoTrabalho", dados.tempoTrabalho);
              setDados(dados);

              console.log(window.location.href)
            })
            .catch((err) => {
              setErro(err);
              console.log(window.location.href)
            })
            .finally(() => {
              setCarregando(false)
              navigate('/cadastroMoradia');
            });
        })
        .catch(function (error) {
          console.log("Erro ao buscar dados da tabela.");
        })
        .finally((response) => {
          setCarregando(false);

        });
    } else {
      api.post('/users/data/' + id, data)
        .then(function (response) {

          getDadosById('/users/addresses', id)
            .then((dados) => {

              if (dados.cep) {
                sessionStorage.setItem("cep", dados.cep);
                sessionStorage.setItem("bairro", dados.bairro);
                sessionStorage.setItem("rua", dados.rua);
                sessionStorage.setItem("numero", dados.numero);
                sessionStorage.setItem("estado", dados.estado);
                sessionStorage.setItem("cidade", dados.cidade);
              }

              sessionStorage.setItem("tempoResidindoNaMoradiaAtual", dados.tempoResidindoNaMoradiaAtual);
              sessionStorage.setItem("tipoResidencia", dados.tipoResidencia);
              sessionStorage.setItem("condicaoDaMoradia", dados.condicaoDaMoradia);
              sessionStorage.setItem("referencia", dados.referencia);
              sessionStorage.setItem("complemento", dados.complemento);
              sessionStorage.setItem("possuiBanheiro", dados.possuiBanheiro);
              sessionStorage.setItem("moradorDeRua", dados.moradorDeRua);
              sessionStorage.setItem("quantidadeDeBanheiros", dados.quantidadeDeBanheiros);
              sessionStorage.setItem("quantidadeDeComodos", dados.quantidadeDeComodos);
              sessionStorage.setItem("possuiEncanamento", dados.possuiEncanamento);
              sessionStorage.setItem("possuiEletricidade", dados.possuiEletricidade);
              sessionStorage.setItem("possuiEsgoto", dados.possuiEsgoto);
              sessionStorage.setItem("possuiColetaDeLixo", dados.possuiColetaDeLixo);
              sessionStorage.setItem("possuiAsfalto", dados.possuiAsfalto);
              sessionStorage.setItem("tempoTrabalho", dados.tempoTrabalho);
              setDados(dados);
            })
            .catch((err) => {
              if (err.response.status === 403) {
                sessionStorage.removeItem("token");
                sessionStorage.clear()
                navigate('/');
              } else {
                setErro(err);
              }
            })
            .finally(() => {
              navigate("/cadastroMoradia/edit/" + id);
              setCarregando(false);
            })
        })
        .catch((err) => {
          if (err.response.status === 403) {
            sessionStorage.removeItem("token");
            sessionStorage.clear()
            navigate('/');
          } else {
            setErro(err);
          }
        })
        .finally(() => setCarregando(false))
    }
  }

  return (

    <div className="BackgroundImg">

      <Header />
      <div className="mx-auto my-4 rounded-2xl bg-white shadow-xl md:w-2/3 pb-6 p-1">
        {/* Stepper */}
        <div className="container horizontal mt-8">
          <Stepper steps={steps} currentStep={currentStep} />
          <div className="my-0 p-10">
            <StepperContext.Provider value={{
              stepData,
              setStepData,
              finalData,
              setFinalData
            }}>
            </StepperContext.Provider>

            <div className="flex flex-col">
              <div className='flex justify-between items-center my-4'>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <h1 style={{
                      fontFamily: 'Exo-SemiBold',
                      fontSize: '32px'
                    }}>
                      Dados Pessoais do(a) Titular:
                    </h1>
                  </Grid>
                  <Grid justifyContent="flex-end" item xs={12} sm={6}>
                    <p
                      style={{
                        fontFamily: 'Exo-SemiBold',
                        fontSize: '22px',
                        textAlign: 'end'
                      }}
                    >
                      {sessionStorage.getItem("cpf")}
                    </p>
                    <p
                      style={{
                        fontFamily: 'Exo-SemiBold',
                        fontSize: '22px',
                        textAlign: 'end'
                      }}
                    >
                      {sessionStorage.getItem("userName")}
                    </p>
                  </Grid>
                </Grid>
              </div>
              <br />
              <Grid container justifyContent="space-around" spacing={2} >
                <Grid item sm={5} xs={12} sx={12}>
                  <Controller
                    control={control}
                    name="nome"
                    render={({ field }) => (
                      <>
                        <TextField
                          sx={{ width: "100%" }}
                          placeholder="Nome Completo do(a) Titular *"
                          value={sessionStorage.getItem("userName")}
                          {...field}

                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item sm={4} xs={12} sx={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel> Sexo atribuído ao Nascimento </InputLabel>
                    <Controller
                      control={control}
                      name="sexoNascimento"
                      render={({ field }) => (
                        <>
                          <Select
                            row
                            sx={{ width: "auto" }}
                            placeholder=""
                            label="Sexo atribuído ao Nascimento"
                            open={openSexo}
                            onClose={handleCloseSexo}
                            onOpen={handleOpenSexo}
                            value={sessionStorage.getItem("sexoNascimento")}
                            onChange={handleChangeSexo}
                            {...field}

                          >
                            <MenuItem disabled value={null}>Selecione...</MenuItem>
                            <MenuItem value={0}>Feminino</MenuItem>
                            <MenuItem value={1}>Masculino</MenuItem>
                            <MenuItem value={2}>Intersexo</MenuItem>
                          </Select>
                        </>
                      )}
                    />
                  </FormControl >
                </Grid>
                <Grid item sm={3} xs={12} sx={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel> Identidade de Genêro </InputLabel>
                    <Controller
                      control={control}
                      name="identidadeGenero"
                      render={({ field }) => (
                        <>
                          <Select
                            row
                            placeholder=""
                            sx={{ width: 'auto' }}
                            label="Identidade de Genêro"
                            open={openIdentidadeGenero}
                            onClose={handleCloseIdentidadeGenero}
                            onOpen={handleOpenIdentidadeGenero}
                            value={sessionStorage.getItem("genero")}
                            onChange={handleChangeIdentidadeGenero}
                            {...field}

                          >
                            <MenuItem disabled value={0}>Selecione...</MenuItem>
                            <MenuItem value={1}>Homem CIS</MenuItem>
                            <MenuItem value={2}>Mulher CIS</MenuItem>
                            <MenuItem value={3}>Mulher Trans</MenuItem>
                            <MenuItem value={4}>Homem Trans</MenuItem>
                            <MenuItem value={5}>Travesti</MenuItem>
                            <MenuItem value={6}>Não binário</MenuItem>
                          </Select>
                        </>
                      )}
                    />
                  </FormControl >
                </Grid>
              </Grid>

              <Grid container justifyContent="space-around" spacing={2} sx={{ marginTop: 2 }} >
                <Grid item sm={6} xs={12} sx={12}>
                  <Controller
                    control={control}
                    name="nomeSocial"
                    render={({ field }) => (
                      <>
                        <TextField
                          sx={{ width: "100%" }}
                          placeholder="Nome Social"
                          defaultValue={(sessionStorage.getItem("nomeSocial") !== undefined && sessionStorage.getItem("nomeSocial") !== 'undefined' && sessionStorage.getItem("nomeSocial") !== null && sessionStorage.getItem("nomeSocial") !== 'null') ? sessionStorage.getItem("nomeSocial") : ""}
                          {...field}

                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12} sx={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel> Raça, Cor </InputLabel>
                    <Controller
                      control={control}
                      name="racaCor"
                      render={({ field }) => (
                        <>
                          <Select
                            row
                            sx={{ width: "auto" }}
                            placeholder=""
                            label="Raça, Cor"
                            open={openCor}
                            onClose={handleCloseCor}
                            onOpen={handleOpenCor}
                            value={sessionStorage.getItem("cor")}
                            onChange={handleChangeCor}
                            {...field}

                          >
                            <MenuItem disabled value={0}>Selecione...</MenuItem>
                            <MenuItem value={1}>Branca</MenuItem>
                            <MenuItem value={2}>Preta</MenuItem>
                            <MenuItem value={3}>Indígena</MenuItem>
                            <MenuItem value={4}>Parda</MenuItem>
                            <MenuItem value={5}>Amarela</MenuItem>
                            <MenuItem value={6}>Ignorada</MenuItem>
                          </Select>
                        </>
                      )}
                    />
                  </FormControl >
                </Grid>
              </Grid>

              <Box
                className='justify-around items-center'
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Grid container spacing={2} sx={{ marginY: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <div
                      className="border-2 border-#f5f5f5 border-solid rounded-lg"
                      style={{ width: '100%', padding: '15px' }}
                    >
                      <FormControl>
                        <FormLabel>Possui alguma deficiência ?</FormLabel>
                        <Controller
                          control={control}
                          name="possuiDeficiencia"
                          render={({ field }) => (
                            <>
                              <RadioGroup
                                value={sessionStorage.getItem("possuiDeficiencia")}
                                {...field}
                              >
                                <FormControlLabel
                                  onChange={handleChangeValue}
                                  value={true}
                                  control={<Radio />}
                                  label="Sim"
                                />
                                <FormControlLabel
                                  onChange={handleChangeValue}
                                  value={false}
                                  control={<Radio />}
                                  label="Não"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </FormControl>

                      <FormControl style={{ width: '100%', marginTop: '8px' }}>
                        <InputLabel> Qual o seu tipo de deficiência ? </InputLabel>
                        <Controller
                          control={control}
                          name="tipoDeDeficiencia"
                          render={({ field }) => (
                            <>
                              <Select
                                placeholder=""
                                disabled={selectedRadio === 'false'}
                                open={openDeficiencia}
                                onClose={handleCloseDeficiencia}
                                onOpen={handleOpenDeficiencia}
                                label="Qual o seu tipo de deficiência ?"
                                defaultValue={0}
                                onChange={handleChangeDeficiencia}
                                style={{ width: '100%', mt: '16px' }}
                                value={sessionStorage.getItem("tipoDeDeficiencia")}
                                {...field}
                              >
                                <MenuItem disabled value={0}>Selecione...</MenuItem>
                                <MenuItem value={1}>Deficiência Física</MenuItem>
                                <MenuItem value={2}>Deficiência Visual</MenuItem>
                                <MenuItem value={3}>Deficiência Auditiva</MenuItem>
                                <MenuItem value={4}>Deficiência Intelectual</MenuItem>
                                <MenuItem value={5}>Deficiência Múltipla</MenuItem>
                              </Select>
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div
                      className="border-2 border-#f5f5f5 border-solid rounded-lg"
                      style={{ width: '100%', padding: '15px' }}
                    >
                      {/* Trocar name depois da API*/}
                      <FormControl>
                        <FormLabel>Possui alguma Doença Crônica ?</FormLabel>
                        <Controller
                          control={control}
                          name="possuiDoencaCronica"
                          render={({ field }) => (
                            <>
                              <RadioGroup
                                value={sessionStorage.getItem("possuiDoencaCronica")}
                                {...field}
                              >
                                <FormControlLabel
                                  onChange={handleChangeValueDoenca}
                                  value={true}
                                  control={<Radio />}
                                  label="Sim"
                                />
                                <FormControlLabel
                                  onChange={handleChangeValueDoenca}
                                  value={false}
                                  control={<Radio />}
                                  label="Não"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </FormControl>

                      <FormControl style={{ width: '100%', marginTop: '8px' }}>
                        <Controller
                          control={control}
                          name="doencaCronicaDescricao"
                          render={({ field }) => (
                            <>
                              <TextField
                                required
                                placeholder="Se sim, qual doença?*"
                                style={{ width: 'auto', mt: '16px' }}
                                disabled={selectedRadioDoenca === 'false'}
                                value={(sessionStorage.getItem("doencaCronicaDescricao") !== undefined && sessionStorage.getItem("doencaCronicaDescricao") !== 'undefined') ? sessionStorage.getItem("doencaCronicaDescricao") : ""}
                                {...field}

                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </Box>

              <Grid container xs={12} sm={12} sx={{ marginY: 2 }}>
                <div
                  className="border-2 border-#f5f5f5 border-solid rounded-lg"
                  style={{ width: '100%', padding: '15px' }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item sm={3} xs={12}>
                      <FormControl>
                        <FormLabel>Você vota em Piracicaba ?</FormLabel>
                        <Controller
                          control={control}
                          name="votaEmPiracicaba"
                          render={({ field }) => (
                            <>
                              <RadioGroup
                                value={sessionStorage.getItem("votaEmPiracicaba")}
                                {...field}
                              >
                                <FormControlLabel
                                  onChange={handleChangeValueVotacao}
                                  value={true}
                                  control={<Radio />}
                                  label="Sim"
                                />
                                <FormControlLabel
                                  onChange={handleChangeValueVotacao}
                                  value={false}
                                  control={<Radio />}
                                  label="Não"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                      <FormControl style={{ width: '100%' }}>
                        <Controller
                          control={control}
                          name="votaEmPiracicabaDesde"
                          render={({ field }) => (
                            <>
                              <InputLabel htmlFor="outlined-adornment-amount">Se sim, há quantos anos você vota em Piracicaba? *</InputLabel>
                              <OutlinedInput
                                fullWidth
                                type="text"
                                className={styles.InputStyleNumber}
                                style={{ width: 'auto', mt: '16px' }}
                                disabled={selectedRadioVotacao === 'false'}
                                endAdornment={<InputAdornment position="end">anos</InputAdornment>}
                                value={(sessionStorage.getItem("votaEmPiracicabaDesde") !== undefined && sessionStorage.getItem("votaEmPiracicabaDesde") !== "undefined") ? sessionStorage.getItem("votaEmPiracicabaDesde") : 0}
                                label="Se sim, há quantos anos você vota em Piracicaba? *"
                                placeholder="Se sim, há quantos anos você vota em Piracicaba?"
                                onInput={handleInputChange}
                                {...field}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={2} sx={{ marginY: '2px' }}>
                    <Grid item sm={6} xs={12}>
                      <FormControl sx={{ width: '100%' }}>
                        <InputLabel>Se não, em qual estado você vota? *</InputLabel>
                        <Controller
                          control={control}
                          name="estadoDeVoto"
                          render={({ field }) => (
                            <>
                              <Select
                                disabled={selectedRadioVotacao === 'true'}
                                fullWidth
                                onChange={handleSelectUf2}
                                variant="outlined"
                                placeholder='Se não, em qual estado você vota? *'
                                label="Se não, em qual estado você vota? *"
                                size='medium'
                                defaultValue={(sessionStorage.getItem("estadoDeVoto") !== undefined && sessionStorage.getItem("estadoDeVoto") !== "undefined") ? sessionStorage.getItem("estadoDeVoto") : ""}
                              >
                                <MenuItem value={sessionStorage.getItem("estadoDeVoto")}>
                                  <p>{(sessionStorage.getItem("estadoDeVoto") !== undefined && sessionStorage.getItem("estadoDeVoto") !== "undefined") ? sessionStorage.getItem("estadoDeVoto") : ""}</p>
                                </MenuItem>
                                {ufs2.map((uf) => (
                                  <MenuItem key={uf.id} value={uf.sigla}>
                                    {uf.nome}
                                  </MenuItem>
                                ))}

                              </Select>
                            </>
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <FormControl sx={{ width: '100%' }}>
                        <InputLabel>Se não, em qual cidade você vota? *</InputLabel>
                        <Controller
                          control={control}
                          name="cidadeDeVoto"
                          render={({ field }) => (
                            <>
                              <Select
                                size='medium'
                                variant="outlined"
                                sx={{ width: 'auto' }}
                                onChange={handleSelectNaturality2}
                                disabled={selectedRadioVotacao === 'true'}
                                label='Se não, em qual cidade você vota? *'
                                placeholder='Se não, Em qual cidade você vota? *'
                                defaultValue={(sessionStorage.getItem("cidadeDeVoto") !== undefined && sessionStorage.getItem("cidadeDeVoto") !== "undefined") ? sessionStorage.getItem("cidadeDeVoto") : ""}
                              >
                                <MenuItem value={sessionStorage.getItem("cidadeDeVoto")}>
                                  <p>{sessionStorage.getItem("cidadeDeVoto")}</p>
                                </MenuItem>
                                {naturality2.map((city) => (
                                  <MenuItem key={city.id} value={city.nome}>
                                    {city.nome}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ marginY: 2 }}>
                    <FormControl>
                      <FormLabel>Está inscrito no Cadastro Único do Governo Federal?</FormLabel>
                      <Controller
                        control={control}
                        name="cadastradoGovernoFederal"
                        render={({ field }) => (
                          <>
                            <RadioGroup
                              value={sessionStorage.getItem("cadastradoGovernoFederal")}
                              {...field}
                            >
                              <FormControlLabel
                                onChange={handleChangeCadastroGF}
                                value={true}
                                control={<Radio />}
                                label="Sim"
                              />
                              <FormControlLabel
                                onChange={handleChangeCadastroGF}
                                value={false}
                                control={<Radio />}
                                label="Não"
                              />
                            </RadioGroup>
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </div>
              </Grid>

              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sm={6} xs={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Escolaridade *</InputLabel>
                    <Controller
                      control={control}
                      name="escolaridade"
                      render={({ field }) => (
                        <>
                          <Select
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            label="Escolaridade"
                            defaultValue="0"
                            onChange={handleChangeEscolaridade}
                            sx={{ width: "auto" }}
                            value={sessionStorage.getItem("escolaridade")}
                            {...field}
                          >
                            <MenuItem disabled value={0}>Selecione...</MenuItem>
                            <MenuItem value={1}>Não Alfabetizado</MenuItem>
                            <MenuItem value={8}>Educação Infantil</MenuItem>
                            <MenuItem value={2}>Ensino Fundamental Incompleto</MenuItem>
                            <MenuItem value={3}>Ensino Fundamental Completo</MenuItem>
                            <MenuItem value={4}>Ensino Médio Incompleto</MenuItem>
                            <MenuItem value={5}>Ensino Médio Completo</MenuItem>
                            <MenuItem value={9}>Curso Técnico</MenuItem>
                            <MenuItem value={6}>Ensino Superior Incompleto</MenuItem>
                            <MenuItem value={7}>Ensino Superior Completo</MenuItem>
                          </Select>
                        </>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Estado Civil *</InputLabel>
                    <Controller
                      control={control}
                      name="estadoCivil"
                      render={({ field }) => (
                        <>
                          <Select
                            open={openRelacao}
                            onClose={handleCloseRelacao}
                            onOpen={handleOpenRelacao}
                            label="Estado Civil"
                            onChange={handleChangeRelacao}
                            defaultValue="0"
                            sx={{ width: "auto" }}
                            value={sessionStorage.getItem("estadoCivil")}
                            {...field}
                          >
                            <MenuItem disabled value={0}>Selecione...</MenuItem>
                            <MenuItem value={1}>Solteiro(a) sem filhos</MenuItem>
                            <MenuItem value={2}>Solteiro(a) com filhos</MenuItem>
                            <MenuItem value={3}>Casado(a) sem filhos</MenuItem>
                            <MenuItem value={4}>Casado(a) com filhos</MenuItem>
                            <MenuItem value={5}>Separado(a) sem filhos</MenuItem>
                            <MenuItem value={6}>Separado(a) com filhos</MenuItem>
                            <MenuItem value={7}>Divorciado(a) sem filhos</MenuItem>
                            <MenuItem value={8}>Divorciado(a) com filhos</MenuItem>
                            <MenuItem value={9}>Amasiado(a) sem filhos</MenuItem>
                            <MenuItem value={10}>Amasiado(a) com filhos</MenuItem>
                            <MenuItem value={11}>Viúvo(a) sem filhos</MenuItem>
                            <MenuItem value={12}>Viúvo(a) com filhos</MenuItem>
                          </Select>
                        </>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sm={4} xs={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>País que nasceu *</InputLabel>
                    <Controller
                      control={control}
                      name="nacionalidade"
                      defaultValue={!!sessionStorage.getItem("nacionalidade") ? sessionStorage.getItem("nacionalidade") : 0}
                      render={({ field }) => (
                        <>
                          <Select
                            sx={{ width: 'auto' }}
                            label="País que nasceu"
                            open={openPaises}
                            onClose={handleClosePaises}
                            onOpen={handleOpenPaises}
                            onChange={(e) => {
                              field.onChange(e);
                              handleChangePaises(e);
                            }}
                            value={field.value}
                          >
                            <MenuItem value={0} disabled>Selecione...</MenuItem>
                            <MenuItem value="Brasil">Brasil</MenuItem>
                            <MenuItem value="Afeganistão">Afeganistão</MenuItem>
                            <MenuItem value="África do Sul">África do Sul</MenuItem>
                            <MenuItem value="Albânia">Albânia</MenuItem>
                            <MenuItem value="Alemanha">Alemanha</MenuItem>
                            <MenuItem value="Andorra">Andorra</MenuItem>
                            <MenuItem value="Angola">Angola</MenuItem>
                            <MenuItem value="Anguilla">Anguilla</MenuItem>
                            <MenuItem value="Antilhas Holandesas">Antilhas Holandesas</MenuItem>
                            <MenuItem value="Antárctida">Antárctida</MenuItem>
                            <MenuItem value="Antígua e Barbuda">Antígua e Barbuda</MenuItem>
                            <MenuItem value="Argentina">Argentina</MenuItem>
                            <MenuItem value="Argélia">Argélia</MenuItem>
                            <MenuItem value="Armênia">Armênia</MenuItem>
                            <MenuItem value="Aruba">Aruba</MenuItem>
                            <MenuItem value="Arábia Saudita">Arábia Saudita</MenuItem>
                            <MenuItem value="Austrália">Austrália</MenuItem>
                            <MenuItem value="Áustria">Áustria</MenuItem>
                            <MenuItem value="Azerbaijão">Azerbaijão</MenuItem>
                            <MenuItem value="Bahamas">Bahamas</MenuItem>
                            <MenuItem value="Bahrein">Bahrein</MenuItem>
                            <MenuItem value="Bangladesh">Bangladesh</MenuItem>
                            <MenuItem value="Barbados">Barbados</MenuItem>
                            <MenuItem value="Belize">Belize</MenuItem>
                            <MenuItem value="Benim">Benim</MenuItem>
                            <MenuItem value="Bermudas">Bermudas</MenuItem>
                            <MenuItem value="Bielorrússia">Bielorrússia</MenuItem>
                            <MenuItem value="Bolívia">Bolívia</MenuItem>
                            <MenuItem value="Botswana">Botswana</MenuItem>
                            <MenuItem value="Brunei">Brunei</MenuItem>
                            <MenuItem value="Bulgária">Bulgária</MenuItem>
                            <MenuItem value="Burkina Faso">Burkina Faso</MenuItem>
                            <MenuItem value="Burundi">Burundi</MenuItem>
                            <MenuItem value="Butão">Butão</MenuItem>
                            <MenuItem value="Bélgica">Bélgica</MenuItem>
                            <MenuItem value="Bósnia e Herzegovina">Bósnia e Herzegovina</MenuItem>
                            <MenuItem value="Cabo Verde">Cabo Verde</MenuItem>
                            <MenuItem value="Camarões">Camarões</MenuItem>
                            <MenuItem value="Camboja">Camboja</MenuItem>
                            <MenuItem value="Canadá">Canadá</MenuItem>
                            <MenuItem value="Catar">Catar</MenuItem>
                            <MenuItem value="Cazaquistão">Cazaquistão</MenuItem>
                            <MenuItem value="Chade">Chade</MenuItem>
                            <MenuItem value="Chile">Chile</MenuItem>
                            <MenuItem value="China">China</MenuItem>
                            <MenuItem value="Chipre">Chipre</MenuItem>
                            <MenuItem value="Colômbia">Colômbia</MenuItem>
                            <MenuItem value="Comores">Comores</MenuItem>
                            <MenuItem value="Coreia do Norte">Coreia do Norte</MenuItem>
                            <MenuItem value="Coreia do Sul">Coreia do Sul</MenuItem>
                            <MenuItem value="Costa do Marfim">Costa do Marfim</MenuItem>
                            <MenuItem value="Costa Rica">Costa Rica</MenuItem>
                            <MenuItem value="Croácia">Croácia</MenuItem>
                            <MenuItem value="Cuba">Cuba</MenuItem>
                            <MenuItem value="Dinamarca">Dinamarca</MenuItem>
                            <MenuItem value="Djibouti">Djibouti</MenuItem>
                            <MenuItem value="Dominica">Dominica</MenuItem>
                            <MenuItem value="Egito">Egito</MenuItem>
                            <MenuItem value="El Salvador">El Salvador</MenuItem>
                            <MenuItem value="Emirados Árabes Unidos">Emirados Árabes Unidos</MenuItem>
                            <MenuItem value="Equador">Equador</MenuItem>
                            <MenuItem value="Eritreia">Eritreia</MenuItem>
                            <MenuItem value="Escócia">Escócia</MenuItem>
                            <MenuItem value="Eslováquia">Eslováquia</MenuItem>
                            <MenuItem value="Eslovênia">Eslovênia</MenuItem>
                            <MenuItem value="Espanha">Espanha</MenuItem>
                            <MenuItem value="Estados Federados da Micronésia">Estados Federados da Micronésia</MenuItem>
                            <MenuItem value="Estados Unidos">Estados Unidos</MenuItem>
                            <MenuItem value="Estônia">Estônia</MenuItem>
                            <MenuItem value="Etiópia">Etiópia</MenuItem>
                            <MenuItem value="Fiji">Fiji</MenuItem>
                            <MenuItem value="Filipinas">Filipinas</MenuItem>
                            <MenuItem value="Finlândia">Finlândia</MenuItem>
                            <MenuItem value="França">França</MenuItem>
                            <MenuItem value="Gabão">Gabão</MenuItem>
                            <MenuItem value="Gana">Gana</MenuItem>
                            <MenuItem value="Geórgia">Geórgia</MenuItem>
                            <MenuItem value="Gibraltar">Gibraltar</MenuItem>
                            <MenuItem value="Granada">Granada</MenuItem>
                            <MenuItem value="Gronelândia">Gronelândia</MenuItem>
                            <MenuItem value="Grécia">Grécia</MenuItem>
                            <MenuItem value="Guadalupe">Guadalupe</MenuItem>
                            <MenuItem value="Guam">Guam</MenuItem>
                            <MenuItem value="Guatemala">Guatemala</MenuItem>
                            <MenuItem value="Guernesei">Guernesei</MenuItem>
                            <MenuItem value="Guiana">Guiana</MenuItem>
                            <MenuItem value="Guiana Francesa">Guiana Francesa</MenuItem>
                            <MenuItem value="Guiné">Guiné</MenuItem>
                            <MenuItem value="Guiné Equatorial">Guiné Equatorial</MenuItem>
                            <MenuItem value="Guiné-Bissau">Guiné-Bissau</MenuItem>
                            <MenuItem value="Gâmbia">Gâmbia</MenuItem>
                            <MenuItem value="Haiti">Haiti</MenuItem>
                            <MenuItem value="Honduras">Honduras</MenuItem>
                            <MenuItem value="Hong Kong">Hong Kong</MenuItem>
                            <MenuItem value="Hungria">Hungria</MenuItem>
                            <MenuItem value="Ilha Bouvet">Ilha Bouvet</MenuItem>
                            <MenuItem value="Ilha de Man">Ilha de Man</MenuItem>
                            <MenuItem value="Ilha do Natal">Ilha do Natal</MenuItem>
                            <MenuItem value="Ilha Heard e Ilhas McDonald">Ilha Heard e Ilhas McDonald</MenuItem>
                            <MenuItem value="Ilha Norfolk">Ilha Norfolk</MenuItem>
                            <MenuItem value="Ilhas Cayman">Ilhas Cayman</MenuItem>
                            <MenuItem value="Ilhas Cocos (Keeling)">Ilhas Cocos (Keeling)</MenuItem>
                            <MenuItem value="Ilhas Cook">Ilhas Cook</MenuItem>
                            <MenuItem value="Ilhas Feroé">Ilhas Feroé</MenuItem>
                            <MenuItem value="Ilhas Geórgia do Sul e Sandwich do Sul">Ilhas Geórgia do Sul e Sandwich do Sul</MenuItem>
                            <MenuItem value="Ilhas Malvinas">Ilhas Malvinas</MenuItem>
                            <MenuItem value="Ilhas Marshall">Ilhas Marshall</MenuItem>
                            <MenuItem value="Ilhas Menores Distantes dos Estados Unidos">Ilhas Menores Distantes dos Estados Unidos</MenuItem>
                            <MenuItem value="Ilhas Salomão">Ilhas Salomão</MenuItem>
                            <MenuItem value="Ilhas Virgens Americanas">Ilhas Virgens Americanas</MenuItem>
                            <MenuItem value="Ilhas Virgens Britânicas">Ilhas Virgens Britânicas</MenuItem>
                            <MenuItem value="Ilhas Åland">Ilhas Åland</MenuItem>
                            <MenuItem value="Indonésia">Indonésia</MenuItem>
                            <MenuItem value="Inglaterra">Inglaterra</MenuItem>
                            <MenuItem value="Índia">Índia</MenuItem>
                            <MenuItem value="Iraque">Iraque</MenuItem>
                            <MenuItem value="Irlanda do Norte">Irlanda do Norte</MenuItem>
                            <MenuItem value="Irlanda">Irlanda</MenuItem>
                            <MenuItem value="Irã">Irã</MenuItem>
                            <MenuItem value="Islândia">Islândia</MenuItem>
                            <MenuItem value="Israel">Israel</MenuItem>
                            <MenuItem value="Itália">Itália</MenuItem>
                            <MenuItem value="Iêmen">Iêmen</MenuItem>
                            <MenuItem value="Jamaica">Jamaica</MenuItem>
                            <MenuItem value="Japão">Japão</MenuItem>
                            <MenuItem value="Jersey">Jersey</MenuItem>
                            <MenuItem value="Jordânia">Jordânia</MenuItem>
                            <MenuItem value="Kiribati">Kiribati</MenuItem>
                            <MenuItem value="Kuwait">Kuwait</MenuItem>
                            <MenuItem value="Laos">Laos</MenuItem>
                            <MenuItem value="Lesoto">Lesoto</MenuItem>
                            <MenuItem value="Letônia">Letônia</MenuItem>
                            <MenuItem value="Libéria">Libéria</MenuItem>
                            <MenuItem value="Liechtenstein">Liechtenstein</MenuItem>
                            <MenuItem value="Lituânia">Lituânia</MenuItem>
                            <MenuItem value="Luxemburgo">Luxemburgo</MenuItem>
                            <MenuItem value="Líbano">Líbano</MenuItem>
                            <MenuItem value="Líbia">Líbia</MenuItem>
                            <MenuItem value="Macau">Macau</MenuItem>
                            <MenuItem value="Macedônia">Macedônia</MenuItem>
                            <MenuItem value="Madagáscar">Madagáscar</MenuItem>
                            <MenuItem value="Malawi">Malawi</MenuItem>
                            <MenuItem value="Maldivas">Maldivas</MenuItem>
                            <MenuItem value="Mali">Mali</MenuItem>
                            <MenuItem value="Malta">Malta</MenuItem>
                            <MenuItem value="Malásia">Malásia</MenuItem>
                            <MenuItem value="Marianas Setentrionais">Marianas Setentrionais</MenuItem>
                            <MenuItem value="Marrocos">Marrocos</MenuItem>
                            <MenuItem value="Martinica">Martinica</MenuItem>
                            <MenuItem value="Mauritânia">Mauritânia</MenuItem>
                            <MenuItem value="Maurícia">Maurícia</MenuItem>
                            <MenuItem value="Mayotte">Mayotte</MenuItem>
                            <MenuItem value="Moldávia">Moldávia</MenuItem>
                            <MenuItem value="Mongólia">Mongólia</MenuItem>
                            <MenuItem value="Montenegro">Montenegro</MenuItem>
                            <MenuItem value="Montserrat">Montserrat</MenuItem>
                            <MenuItem value="Moçambique">Moçambique</MenuItem>
                            <MenuItem value="Myanmar">Myanmar</MenuItem>
                            <MenuItem value="México">México</MenuItem>
                            <MenuItem value="Mônaco">Mônaco</MenuItem>
                            <MenuItem value="Namíbia">Namíbia</MenuItem>
                            <MenuItem value="Nauru">Nauru</MenuItem>
                            <MenuItem value="Nepal">Nepal</MenuItem>
                            <MenuItem value="Nicarágua">Nicarágua</MenuItem>
                            <MenuItem value="Nigéria">Nigéria</MenuItem>
                            <MenuItem value="Niue">Niue</MenuItem>
                            <MenuItem value="Noruega">Noruega</MenuItem>
                            <MenuItem value="Nova Caledônia">Nova Caledônia</MenuItem>
                            <MenuItem value="Nova Zelândia">Nova Zelândia</MenuItem>
                            <MenuItem value="Níger">Níger</MenuItem>
                            <MenuItem value="Omã">Omã</MenuItem>
                            <MenuItem value="Palau">Palau</MenuItem>
                            <MenuItem value="Palestina">Palestina</MenuItem>
                            <MenuItem value="Panamá">Panamá</MenuItem>
                            <MenuItem value="Papua-Nova Guiné">Papua-Nova Guiné</MenuItem>
                            <MenuItem value="Paquistão">Paquistão</MenuItem>
                            <MenuItem value="Paraguai">Paraguai</MenuItem>
                            <MenuItem value="País de Gales">País de Gales</MenuItem>
                            <MenuItem value="Países Baixos">Países Baixos</MenuItem>
                            <MenuItem value="Peru">Peru</MenuItem>
                            <MenuItem value="Pitcairn">Pitcairn</MenuItem>
                            <MenuItem value="Polinésia Francesa">Polinésia Francesa</MenuItem>
                            <MenuItem value="Polônia">Polônia</MenuItem>
                            <MenuItem value="Porto Rico">Porto Rico</MenuItem>
                            <MenuItem value="Portugal">Portugal</MenuItem>
                            <MenuItem value="Quirguistão">Quirguistão</MenuItem>
                            <MenuItem value="Quênia">Quênia</MenuItem>
                            <MenuItem value="Reino Unido">Reino Unido</MenuItem>
                            <MenuItem value="República Centro-Africana">República Centro-Africana</MenuItem>
                            <MenuItem value="República Checa">República Checa</MenuItem>
                            <MenuItem value="República Democrática do Congo">República Democrática do Congo</MenuItem>
                            <MenuItem value="República do Congo">República do Congo</MenuItem>
                            <MenuItem value="República Dominicana">República Dominicana</MenuItem>
                            <MenuItem value="Reunião">Reunião</MenuItem>
                            <MenuItem value="Romênia">Romênia</MenuItem>
                            <MenuItem value="Ruanda">Ruanda</MenuItem>
                            <MenuItem value="Rússia">Rússia</MenuItem>
                            <MenuItem value="Saara Ocidental">Saara Ocidental</MenuItem>
                            <MenuItem value="Saint Martin">Saint Martin</MenuItem>
                            <MenuItem value="Saint-Barthélemy">Saint-Barthélemy</MenuItem>
                            <MenuItem value="Saint-Pierre e Miquelon">Saint-Pierre e Miquelon</MenuItem>
                            <MenuItem value="Samoa Americana">Samoa Americana</MenuItem>
                            <MenuItem value="Samoa">Samoa</MenuItem>
                            <MenuItem value="Santa Helena, Ascensão e Tristão da Cunha">Santa Helena, Ascensão e Tristão da Cunha</MenuItem>
                            <MenuItem value="Santa Lúcia">Santa Lúcia</MenuItem>
                            <MenuItem value="Senegal">Senegal</MenuItem>
                            <MenuItem value="Serra Leoa">Serra Leoa</MenuItem>
                            <MenuItem value="Seychelles">Seychelles</MenuItem>
                            <MenuItem value="Singapura">Singapura</MenuItem>
                            <MenuItem value="Somália">Somália</MenuItem>
                            <MenuItem value="Sri Lanka">Sri Lanka</MenuItem>
                            <MenuItem value="Suazilândia">Suazilândia</MenuItem>
                            <MenuItem value="Sudão">Sudão</MenuItem>
                            <MenuItem value="Suriname">Suriname</MenuItem>
                            <MenuItem value="Suécia">Suécia</MenuItem>
                            <MenuItem value="Suíça">Suíça</MenuItem>
                            <MenuItem value="Svalbard e Jan Mayen">Svalbard e Jan Mayen</MenuItem>
                            <MenuItem value="São Cristóvão e Nevis">São Cristóvão e Nevis</MenuItem>
                            <MenuItem value="São Marino">São Marino</MenuItem>
                            <MenuItem value="São Tomé e Príncipe">São Tomé e Príncipe</MenuItem>
                            <MenuItem value="São Vicente e Granadinas">São Vicente e Granadinas</MenuItem>
                            <MenuItem value="Sérvia">Sérvia</MenuItem>
                            <MenuItem value="Síria">Síria</MenuItem>
                            <MenuItem value="Tadjiquistão">Tadjiquistão</MenuItem>
                            <MenuItem value="Tailândia">Tailândia</MenuItem>
                            <MenuItem value="Taiwan">Taiwan</MenuItem>
                            <MenuItem value="Tanzânia">Tanzânia</MenuItem>
                            <MenuItem value="Terras Austrais e Antárticas Francesas">Terras Austrais e Antárticas Francesas</MenuItem>
                            <MenuItem value="Território Britânico do Oceano Índico">Território Britânico do Oceano Índico</MenuItem>
                            <MenuItem value="Timor-Leste">Timor-Leste</MenuItem>
                            <MenuItem value="Togo">Togo</MenuItem>
                            <MenuItem value="Tonga">Tonga</MenuItem>
                            <MenuItem value="Toquelau">Toquelau</MenuItem>
                            <MenuItem value="Trinidad e Tobago">Trinidad e Tobago</MenuItem>
                            <MenuItem value="Tunísia">Tunísia</MenuItem>
                            <MenuItem value="Turcas e Caicos">Turcas e Caicos</MenuItem>
                            <MenuItem value="Turquemenistão">Turquemenistão</MenuItem>
                            <MenuItem value="Turquia">Turquia</MenuItem>
                            <MenuItem value="Tuvalu">Tuvalu</MenuItem>
                            <MenuItem value="Ucrânia">Ucrânia</MenuItem>
                            <MenuItem value="Uganda">Uganda</MenuItem>
                            <MenuItem value="Uruguai">Uruguai</MenuItem>
                            <MenuItem value="Uzbequistão">Uzbequistão</MenuItem>
                            <MenuItem value="Vanuatu">Vanuatu</MenuItem>
                            <MenuItem value="Vaticano">Vaticano</MenuItem>
                            <MenuItem value="Venezuela">Venezuela</MenuItem>
                            <MenuItem value="Vietname">Vietname</MenuItem>
                            <MenuItem value="Wallis e Futuna">Wallis e Futuna</MenuItem>
                            <MenuItem value="Zimbabwe">Zimbabwe</MenuItem>
                            <MenuItem value="Zâmbia">Zâmbia</MenuItem>
                          </Select>
                        </>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={4} xs={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Estado </InputLabel>
                    <Controller
                      control={control}
                      name="naturalidadeEstado"
                      defaultValue={sessionStorage.getItem("naturalidadeEstado")}
                      render={({ field }) => (
                        <>
                          <Select
                            sx={{ width: 'auto' }}
                            onChange={(e) => {
                              field.onChange(e);
                              handleSelectUf(e);
                            }}
                            disabled={campoDesabilitado}
                            variant="outlined"
                            placeholder='Estado'
                            label="Estado"
                            size='medium'
                            value={field.value}
                          >
                            <MenuItem value={sessionStorage.getItem("naturalidadeEstado")}>
                              <p>{sessionStorage.getItem("naturalidadeEstado")}</p>
                            </MenuItem>
                            {ufs.map((uf) => (
                              <MenuItem key={uf.id} value={uf.sigla}>
                                {uf.nome}
                              </MenuItem>
                            ))}

                          </Select>
                        </>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={4} xs={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Cidade que nasceu </InputLabel>
                    <Controller
                      control={control}
                      name="naturalidadeCidade"
                      defaultValue={sessionStorage.getItem("naturalidadeCidade")}
                      render={({ field }) => (
                        <>
                          <Select
                            sx={{ width: 'auto' }}
                            onChange={(e) => {
                              field.onChange(e);
                              handleSelectNaturality(e);
                            }}
                            disabled={campoDesabilitado}
                            variant="outlined"
                            placeholder='Cidade que nasceu '
                            label='Cidade que nasceu '
                            size='medium'
                            value={field.value}
                          >
                            <MenuItem value={sessionStorage.getItem("naturalidadeCidade")}>
                              <p>{sessionStorage.getItem("naturalidadeCidade")}</p>
                            </MenuItem>
                            {naturality.map((city) => (
                              <MenuItem key={city.id} value={city.nome}>
                                {city.nome}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <br />

              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sm={12} xs={12}>
                  <FormControl sx={{ width: '100%', marginBottom: '6px' }}>
                    <Controller
                      control={control}
                      name="rg"
                      render={({ field }) => (
                        <>
                          <IMaskInput
                            required
                            className={styles.InputStyle}
                            mask="00.000.000-*"
                            placeholder="RG *"
                            style={{ width: 'auto' }}
                            value={sessionStorage.getItem("rg")}
                            {...field}
                          >
                          </IMaskInput>

                        </>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sm={6} xs={12}>
                  <>
                    <FormControl sx={{ width: '100%' }}>
                      <Controller
                        control={control}
                        name="moraEmPiracicabaDesde"
                        render={({ field }) => (
                          <>
                            <TextField
                              required
                              fullWidth
                              type="text"
                              label="Há quantos anos mora em Piracicaba?"
                              name='moraEmPiracicabaDesde'
                              defaultValue={(sessionStorage.getItem("moraEmPiracicabaDesde") !== undefined && sessionStorage.getItem("moraEmPiracicabaDesde") !== "undefined") ? sessionStorage.getItem("moraEmPiracicabaDesde").substring(0, 10) : ""}
                              id='inputDate'
                              onInput={handleInputChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                            />
                          </>
                        )}
                      />
                    </FormControl>
                  </>
                </Grid>
                <Grid item sm={6} xs={12} sx={{ marginY: 4 }}>
                  <>
                    <FormControl sx={{ width: '100%' }}>
                      <Controller
                        control={control}
                        name="dataDeNascimento"
                        render={({ field }) => (
                          <>
                            <TextField
                              type="date"
                              name="dataDeNascimento"
                              label="Data de Nascimento "
                              fullWidth
                              defaultValue={!!sessionStorage.getItem("dataDeNascimento") ? sessionStorage.getItem("dataDeNascimento").substring(0, 10) : NaN}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                            />
                          </>
                        )}
                      />
                    </FormControl>
                  </>
                </Grid>
              </Grid>
              <div className='flex justify-between items-center my-4'>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <h1 style={{
                      fontFamily: 'Exo-SemiBold',
                      fontSize: '32px'
                    }}>
                      Contato:
                    </h1>
                  </Grid>
                </Grid>
              </div>

              <form onSubmitCapture={handleSubmit(onSubmit)} >

                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: '100%' }}>
                      <Controller
                        control={control}
                        name="celular"
                        render={({ field }) => (
                          <>
                            <IMaskInput
                              required
                              mask="(00) 000000000"
                              placeholder="Celular *"
                              className={styles.InputStyle}
                              value={sessionStorage.getItem("celular")}
                              {...field}
                            >
                            </IMaskInput>
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: '100%' }}>
                      <Controller
                        control={control}
                        name="celularDeRecado"
                        render={({ field }) => (
                          <>
                            <IMaskInput
                              mask="(00) 000000000"
                              className={styles.InputStyle}
                              placeholder="Celular para Recado"
                              value={(sessionStorage.getItem("celularDeRecado") !== undefined && sessionStorage.getItem("celularDeRecado") !== "undefined") ? sessionStorage.getItem("celularDeRecado") : ""}
                              {...field}
                            >
                            </IMaskInput>
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: '100%' }}>
                      <Controller
                        control={control}
                        name="contatoCelularRecado"
                        render={({ field }) => (
                          <>
                            <TextField
                              name='contatoCelularRecado'
                              placeholder='Nome da Pessoa do "Celular para Recado"'
                              defaultValue={(sessionStorage.getItem("contatoCelularRecado") !== undefined && sessionStorage.getItem("contatoCelularRecado") !== "undefined") ? sessionStorage.getItem("contatoCelularRecado") : ""}
                              {...field} />
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: '100%' }}>
                      <Controller
                        control={control}
                        name="emailMutuario"
                        render={({ field }) => (
                          <>
                            <TextField
                              required
                              name='emailMutuario'
                              type="email"
                              placeholder='E-mail *'
                              defaultValue={!!sessionStorage.getItem("emailMutuario") ? sessionStorage.getItem("emailMutuario") : sessionStorage.getItem("emailLogin")}
                              {...field} />
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <br />
                <br />
                <Divider light />
                <br />

                <br />

                {/* _9 Mensagem de erro (validação) ============================================================== */}
                <div className="mb-4">
                  {errors.nome && <Alert severity="error">{errors.nome?.message}</Alert>}
                  {errors.sexoNascimento && <Alert severity="error">{errors.sexoNascimento?.message}</Alert>}
                  {errors.possuiDeficiencia && <Alert severity="error">{errors.possuiDeficiencia?.message}</Alert>}
                  {errors.escolaridade && <Alert severity="error">{errors.escolaridade?.message}</Alert>}
                  {errors.estadoCivil && <Alert severity="error">{errors.estadoCivil?.message}</Alert>}
                  {errors.nacionalidade && <Alert severity="error">{errors.nacionalidade?.message}</Alert>}
                  {errors.naturality && <Alert severity="error">{errors.naturality?.message}</Alert>}
                  {errors.rg && <Alert severity="error">{errors.rg?.message}</Alert>}
                  {errors.moraEmPiracicabaDesde && <Alert severity="error">{errors.moraEmPiracicabaDesde?.message}</Alert>}
                  {errors.dataDeNascimento && <Alert severity="error">{errors.dataDeNascimento?.message}</Alert>}

                </div>

                <div className='flex justify-end'>
                  <LoadingButton loading={carregando} type='submit' style={{ backgroundColor: '#3F51b5', padding: '16px', borderRadius: '12px' }} variant='contained' size="large">
                    Próximo
                  </LoadingButton>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
}
