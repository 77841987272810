import { useEffect, useState } from "react";
import ControlledSelect from "../ControlledSelect";

export default function ComponenteFamiliarDeficiencia({
    control,
    row,
    selectSimNaoOptions,
    errors,
    defaultValues,
}) {
    const [Disabled, setDisabled] = useState(false);
    const selectTipoDeficienciaOptions = [
        { value: 0, description: "Selecione..." },
        { value: 1, description: "Deficiência Física" },
        { value: 2, description: "Deficiência Visual" },
        { value: 3, description: "Deficiência Auditiva" },
        { value: 4, description: "Deficiência Intelectual" },
        { value: 5, description: "Deficiência Múltipla" },
    ]

    useEffect(() => {
        if (!!defaultValues?.dependentes) {
            let familiarDef = defaultValues?.dependentes[row]?.familiarDeficiente;
            console.log('passei aqui', familiarDef);
            setDisabled(familiarDef == 'false' || familiarDef === false);
        }

        return () => defaultValues

    }, [defaultValues, row])


    const handleChange = (e) => {
        console.log('valor mudou', e.target.value);
        setDisabled(e.target.value == 'false');
    }

    return (
        <div
            className="border-2 border-#f5f5f5 border-solid rounded-lg"
            style={{ width: '100%', padding: '15px' }}
        >
            <ControlledSelect
                control={control}
                errors={errors}
                originalError={errors?.dependentes?.[row]?.["familiarDeficiente"]}
                firstDisabled={false}
                firstSelected={false}
                defaultValue={false}
                name={`dependentes.${row}.familiarDeficiente`}
                label={'Possui alguma deficiência?'}
                selectOptions={selectSimNaoOptions}
                style={{ mt: '16px' }}
                onSelectChange={handleChange}
            />
            <ControlledSelect
                control={control}
                errors={errors}
                originalError={errors?.dependentes?.[row]?.["tipoDeficienciaFamiliar"]}
                firstDisabled={false}
                firstSelected={false}
                defaultValue={false}
                name={`dependentes.${row}.tipoDeficienciaFamiliar`}
                label={'Qual o seu tipo de deficiência?'}
                selectOptions={selectTipoDeficienciaOptions}
                styleFormControl={{ width: '100%', marginTop: '8px' }}
                style={{ mt: '16px' }}
                disabled={Disabled}
            />
        </div>
    );
}
