import { Paper } from "@mui/material"
import React from "react"

export default function CodigoPenal() {
    return (
        <Paper
            sx={{
                p: 3,
                margin: 'auto',
                borderRadius: '8px',
                boder: '8px solid #000',
                marginTop: '16px',
                backgroundColor: '#f5f5f5'
            }}
        >
            <div className="mt-4">
                <p style={{ color: '#EA580C', fontSize: '18px' }}>
                    Tenha em mãos seus documentos para fazer ou atualizar seu cadastro:
                </p>

                <h1 className="font-bold mt-2" style={{ color: '#EA580C', fontSize: '22px' }}>
                    DECLARAÇÃO PÚBLICA FALSA É CRIME  - Código Penal Brasileiro (Art.  299).
                </h1>

                <p className="mt-4 border-solid border-black border-2 p-4">
                    <strong>
                        Art. 299 (Código Penal):&nbsp;
                    </strong>
                    Omitir, em documento
                    público ou particular, declaração que dele devia constar, ou nele inserir ou
                    fazer inserir declaração falsa ou diversa da que devia ser escrita, com o fim
                    de prejudicar direito, criar obrigação ou alterar a verdade sobre fato
                    juridicamente relevante.
                    <strong>
                        &nbsp;Pena:&nbsp;
                    </strong>
                    reclusão, de 1 a 5 anos, e multa.
                </p>
            </div>
        </Paper>

    )
}