import { Button } from "@mui/material";

const RemoveAnexos = (props) => {

    const removerAnexo = (e) => {
        props.onClick(e.target.value);
    }

    return (
        <Button
            color='error'
            onClick={removerAnexo}
            sx={{
                background: '#ff4040',
                color: "#fff",
                marginTop: "10px"
            }}
        >
            Remover Anexo
        </Button>
    )

}

export default RemoveAnexos;