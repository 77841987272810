//_1 Importações
//_2 Funções para a tabela
//_3 Funcionamento da tabela
//_4 Ligação com a API 
//_5 Cabeçalho da Tabela
//_6 Corpo da Tabela
//_7 Footer da Tabela

//_1 Importações =================================================================================
//React
import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

//MUI
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Button, ButtonGroup, IconButton, InputAdornment, TablePagination, Alert } from '@mui/material';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import LinearProgress from '@mui/material/LinearProgress';
import TableContainer from '@mui/material/TableContainer';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableFooter from '@mui/material/TableFooter';
import SearchIcon from '@mui/icons-material/Search';
import Box, { BoxProps } from '@mui/material/Box';
import Container from '@mui/material/Container';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';

//API
import api from ".././Services/api";
import { getDadosById } from '../Services/globalRequests';


//Componentes
import ModalNovoUsuario from '../Components/HomeAdmin/ModalNovoUsuario';
import { Header } from '../Components/Header';
import { useNavigate } from "react-router-dom";

//Estilos
import stylesInput from '../Components/Stepps/InputStyle.module.css';
import stylesText from '../Components/Stepps/Text.module.css'
import styleHeader from '../Components/Header/styles';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import GerarRelatorio from '../Components/HomeAdmin/GerarRelatorio';




//_2 Funções para a tabela ============================================================================
const theme = createTheme({
  palette: {
    neutral: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#3F51b5',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '16px',
  border: '4px solid orange',
  boxShadow: 24,
  background: 'rgb(34,29,124)',
  background: 'linear-gradient(9deg, rgba(34,29,124,1) 0%, rgba(46,29,149,1) 39%, rgba(47,34,131,1) 100%)',
  display: 'flex',
  margin: 'auto',
  flexDirection: 'column',
  p: 4,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export function HomeAdm() {
  //_3 Funcionamento da tabela ============================================================================
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [linha, setLinha] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [row, setRow] = useState([]);
  const [order, setOrder] = useState(1);
  const [colunmOrder, setColunmOrder] = useState('title')
  const [erro, setErro] = useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  //_4 Ligação com a API ==============================================================================
  useEffect(() => {
    setIsLoading(false);

    async function loadUsuarios() {
      setIsLoading(true);

      await api.get('/users/data/ListUsersData', page)
        .then(resp => {

          setRow(resp.data);
          setLinha(resp.data.length)
        })
        .catch((err) => {
          if (err.response.status === 403) {
            sessionStorage.removeItem("token");
            sessionStorage.clear()
            navigate('/');
          }else{
            console.error("Um erro inesperado aconteceu!", err.toString());
            sessionStorage.removeItem("token");
            sessionStorage.clear()
            navigate('/');
          }
        })
        .finally(() => setIsLoading(false));
    }

    // setNumberOfPages((limitRow - linha) / 2)
    loadUsuarios()
  }, [page])

  const handleOrder = (fieldName) => {
    setOrder(-order);
    setColunmOrder(fieldName)

  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, row.length - page * rowsPerPage);


  const navigate = useNavigate();

  function validaToken(validadeToken) {
    // Passo 1: Converter a string de data do token para um objeto Date
    const tokenExpirationDate = new Date(validadeToken);

    // Passo 2: Obter a data atual
    const currentDate = new Date();

    // Comparar as datas
    return currentDate < tokenExpirationDate;
  }


  async function abreUsuario(e, id, cpf, email) {
    let dadosCartao = false;
    let sucessoToken = false;

    try {
      if (!localStorage.getItem("tokenCartao") || !validaToken(localStorage.getItem("tokenValidade"))) {
        try {
          const response = await api.post('/ApiCartaoCidadao');
          if (response.data.status === "100") {
            localStorage.setItem("tokenCartao", response.data.token);
            localStorage.setItem("tokenValidade", response.data.expira);
            localStorage.setItem("tokenTempo", response.data.tempo);
            sucessoToken = true;
          } else {
            setErro("Falha ao conectar-se com o servidor do Cartão pira cidadão. Entre em contato com o suporte");
          }
        } catch (err) {
          if (err.response.status === 403) {
            setErro(err.response.data.message);
          } else {
            setErro("Algum erro inesperado aconteceu, tente novamente mais tarde!");
          }
        }
      } else {
        sucessoToken = true;
      }

      if (sucessoToken) {
        // chamada para a API passando os dados (`data`) por parâmetro
        const params = {
          'cpf': cpf.replace(/[.-]/g, ''),
          'token': localStorage.getItem("tokenCartao"),
        }
        const queryString = new URLSearchParams(params).toString();

        const responseApiCartao = await api.get('/ApiCartaoCidadao?' + queryString);

        if (responseApiCartao.status == 200) {
          sessionStorage.setItem("celular", responseApiCartao.data.celular);
          sessionStorage.setItem("CEP", responseApiCartao.data.cep);
          sessionStorage.setItem("email", responseApiCartao.data.email);

          if (responseApiCartao.data.deficiencia == "Auditiva") {
            sessionStorage.setItem("possuiDeficiencia", true);
            sessionStorage.setItem("tipoDeDeficiencia", 3);

          } else if (responseApiCartao.data.deficiencia == "Visual") {
            sessionStorage.setItem("possuiDeficiencia", true);
            sessionStorage.setItem("tipoDeDeficiencia", 2);

          } else if (responseApiCartao.data.deficiencia == "Intelectual/Cognitiva") {
            sessionStorage.setItem("possuiDeficiencia", true);
            sessionStorage.setItem("tipoDeDeficiencia", 4);

          } else if (responseApiCartao.data.deficiencia == "Física") {
            sessionStorage.setItem("possuiDeficiencia", true);
            sessionStorage.setItem("tipoDeDeficiencia", 1);

          } else if (responseApiCartao.data.deficiencia == "Autismo") {
            sessionStorage.setItem("possuiDeficiencia", true);

          } else if (responseApiCartao.data.deficiencia == "Outra") {
            sessionStorage.setItem("possuiDeficiencia", true);

          } else if (responseApiCartao.data.deficiencia == null) {
            sessionStorage.setItem("possuiDeficiencia", false);
          }

          sessionStorage.setItem("rg", responseApiCartao.data.rg);

          if (responseApiCartao.data.sexo == "M") {
            sessionStorage.setItem("sexoNascimento", 1);
          } else {
            sessionStorage.setItem("sexoNascimento", 0);
          }

          sessionStorage.setItem("emailLogin", responseApiCartao.data.email);
          sessionStorage.setItem("userName", responseApiCartao.data.nome);
          sessionStorage.setItem("nome", responseApiCartao.data.nome);
          sessionStorage.setItem("dataDeNascimento", responseApiCartao.data.nascimento);

          if (responseApiCartao.data.nacionalidade == "BRASILEIRO") {
            sessionStorage.setItem("nacionalidade", "Brasil");
            sessionStorage.setItem("naturalidadeEstado", responseApiCartao.data.naturalidade.slice(-2));
            sessionStorage.setItem("naturalidadeCidade", responseApiCartao.data.naturalidade.slice(0, -5));
          } else {
            sessionStorage.setItem("nacionalidade", null)
          }

          if (responseApiCartao.data.moradia == "Própria") {
            sessionStorage.setItem("condicaoDaMoradia", 1)
          } else if (responseApiCartao.data.moradia == "Cedida") {
            sessionStorage.setItem("condicaoDaMoradia", 2)
          } else if (responseApiCartao.data.moradia == "Alugada") {
            sessionStorage.setItem("condicaoDaMoradia", 3)
          } else {
            sessionStorage.setItem("condicaoDaMoradia", 4)
          }


          // sessionStorage.setItem("escolaridade", responseApiCartao.data.escolaridade)
          sessionStorage.setItem("Endereco", responseApiCartao.data.endereco);
          sessionStorage.setItem("Logradouro", responseApiCartao.data.LOGRADOURO);
          sessionStorage.setItem("Complemento", responseApiCartao.data.end_compl);
          sessionStorage.setItem("Bairro", responseApiCartao.data.end_bairro);
          sessionStorage.setItem("Numero", responseApiCartao.data.numero);

          if (responseApiCartao.data.complemento) {
            sessionStorage.setItem("ComprovanteResidencia", responseApiCartao.data.complemento);
          }

          if (responseApiCartao.data.documento) {
            sessionStorage.setItem("Documento", responseApiCartao.data.documento);
          }

          dadosCartao = true;
        }
        getDadosById("/users/data", id)
          .then((dados, id) => {
            console.log(dados)
            sessionStorage.setItem("celular", dados.celular);
            sessionStorage.setItem("celularDeRecado", dados.celularDeRecado);
            sessionStorage.setItem("contatoCelularRecado", dados.contatoCelularRecado);
            sessionStorage.setItem("emailLogin", dados.emailCadastro);
            sessionStorage.setItem("emailMutuario", dados.emailMutuario);
            sessionStorage.setItem("nome", dados.nome);
            sessionStorage.setItem("userName", dados.nome);
            sessionStorage.setItem("identidadeGenero", dados.identidadeGenero);
            sessionStorage.setItem("sexoNascimento", dados.sexoNascimento);
            sessionStorage.setItem("nomeSocial", dados.nomeSocial);
            sessionStorage.setItem("racaCor", dados.racaCor);
            sessionStorage.setItem("possuiDeficiencia", dados.possuiDeficiencia);
            sessionStorage.setItem("tipoDeDeficiencia", dados.tipoDeDeficiencia);
            sessionStorage.setItem("possuiDoencaCronica", dados.possuiDoencaCronica);
            sessionStorage.setItem("doencaCronicaDescricao", dados.doencaCronicaDescricao);
            sessionStorage.setItem("funcionarioPublico", dados.funcionarioPublico);
            sessionStorage.setItem("instituicaoPublica", dados.instituicaoPublica);
            sessionStorage.setItem("votaEmPiracicaba", dados.votaEmPiracicaba);
            sessionStorage.setItem("votaEmPiracicabaDesde", dados.votaEmPiracicabaDesde);
            sessionStorage.setItem("cidadeDeVoto", dados.cidadeDeVoto);
            sessionStorage.setItem("estadoDeVoto", dados.estadoDeVoto);
            sessionStorage.setItem("escolaridade", dados.escolaridade);
            sessionStorage.setItem("estadoCivil", dados.estadoCivil);
            sessionStorage.setItem("nacionalidade", dados.nacionalidade);
            sessionStorage.setItem("naturalidadeEstado", dados.naturalidadeEstado);
            sessionStorage.setItem("naturalidadeCidade", dados.naturalidadeCidade);
            sessionStorage.setItem("rg", dados.rg);
            sessionStorage.setItem("orgaoEmissor", dados.orgaoEmissor);
            sessionStorage.setItem("dataDeNascimento", dados.dataDeNascimento);
            sessionStorage.setItem("moraEmPiracicabaDesde", dados.moraEmPiracicabaDesde);
            sessionStorage.setItem("cpf", cpf);
          })
          .catch((dados) => {
            console.log("dados", dados);
            if (!sessionStorage.getItem("celular")) {
              sessionStorage.setItem("celular", dados.celular);
            }

            if (!sessionStorage.getItem("nome")) {
              sessionStorage.setItem("nome", dados.nome);
            }

            if (dadosCartao == false) {
              sessionStorage.setItem("celular", "");
              sessionStorage.setItem("emailCadastro", "");
              sessionStorage.setItem("nacionalidade", "");
              sessionStorage.setItem("dataDeNascimento", "");
              sessionStorage.setItem("naturalidadeEstado", "");
              sessionStorage.setItem("naturalidadeCidade", "");
              sessionStorage.setItem("nome", "");
              sessionStorage.setItem("userName", "");
              sessionStorage.setItem("rg", "");
              sessionStorage.setItem("orgaoEmissor", "");
              sessionStorage.setItem("sexoNascimento", "");
              sessionStorage.setItem("possuiDeficiencia", "");
              sessionStorage.setItem("tipoDeDeficiencia", "");
            }

            sessionStorage.setItem("celularDeRecado", "");
            sessionStorage.setItem("contatoCelularRecado", "");
            sessionStorage.setItem("identidadeGenero", "");
            sessionStorage.setItem("nomeSocial", "");
            sessionStorage.setItem("racaCor", "");
            sessionStorage.setItem("possuiDoencaCronica", "");
            sessionStorage.setItem("doencaCronicaDescricao", "");
            sessionStorage.setItem("funcionarioPublico", "");
            sessionStorage.setItem("instituicaoPublica", "");
            sessionStorage.setItem("votaEmPiracicaba", true);
            sessionStorage.setItem("votaEmPiracicabaDesde", 0);
            sessionStorage.setItem("cidadeDeVoto", "");
            sessionStorage.setItem("estadoDeVoto", "");
            sessionStorage.setItem("escolaridade", "");
            sessionStorage.setItem("estadoCivil", "");
            sessionStorage.setItem("moraEmPiracicabaDesde", "");
            sessionStorage.setItem("cpf", cpf);
            sessionStorage.setItem("emailMutuario", email);
          })
          .finally(() => {
            navigate("/cadastroPessoal/edit/" + id);
          })

      }

    } catch (err) {
      console.error(err);
    }
  }

  async function desativarUsuario(id) {
    if (window.confirm("Deseja realmente desativar esse usuário?")) {
      try {
        const response = await api.patch(`/desativar/${id}`, { contaAtiva: false });

        if (response.status === 200) {
          alert("Usuário Desativado com Sucesso!");
          window.location.reload(false);
        } else {
          alert("Ocorreu um Erro Inesperado! Tente Novamente Mais Tarde...");
        }
      } catch (error) {
        console.error("Erro:", error);
        alert("Ocorreu um Erro Inesperado! Tente Novamente Mais Tarde...");
      }
    }
  }

  async function ativarUsuario(id) {
    if (window.confirm("Deseja realmente ativar esse usuário?")) {
      try {
        const response = await api.patch(`/desativar/${id}`, { contaAtiva: true });

        if (response.status === 200) {
          alert("Usuário Ativado com Sucesso!");
          window.location.reload(false);
        } else {
          alert("Ocorreu um Erro Inesperado! Tente Novamente Mais Tarde...");
        }
      } catch (error) {
        console.error("Erro:", error);
        alert("Ocorreu um Erro Inesperado! Tente Novamente Mais Tarde...");
      }
    }
  }

  function VerificaBotao(props) {
    if (props.ativo == true) {
      return (
        <Button
          color="error"
          name="contaAtiva"
          sx={{ marginLeft: "8px" }}
          onClick={() => desativarUsuario(props.id)}
        >
          Desativar
        </Button>
      )
    } else {
      return (
        <Button
          color="success"
          name="contaAtiva"
          sx={{ marginLeft: "8px" }}
          onClick={() => ativarUsuario(props.id)}
        >
          Ativar
        </Button>
      )
    }
  }

  return (
    <div className="BackgroundImg">
      <div sx={styleHeader.mainStyle}>
        <Header />

        <Container>
          <Box
            className={stylesInput.boxSearch}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1,
              m: 1,
              bgcolor: 'background.paper',
              borderRadius: 1,
            }}
          >
            <div className='flex items-center w-full'>
              <SearchIcon fontSize="large" sx={{ cursor: "pointer", marginRight: "4px" }} />
              {/* Search Bar */}
              <input
                type="text"
                placeholder='Pesquisar Usuário...'
                id={stylesInput.Search}
                className={stylesInput.InputStyleSearch}
                onChange={event => { setSearchTerm(event.target.value); setPage(0) }}
              />
            </div>
            <ModalNovoUsuario />
            <GerarRelatorio />
          </Box>
          <Box>
            {erro &&
              <>
                <br />
                <Alert severity='error'>
                  {erro}
                </Alert>
              </>
            }
          </Box>
          {/* Tabela */}
          <TableContainer component={Paper} sx={{ marginY: '32px', border: '8px solid #3F51b5', borderRadius: '16px', maxWidth: '1920px' }}>
            <Table sx={{ minWidth: 80 }} aria-label="customized table">

              {/*_5 Cabeçalho da Tabela ============================================================================== */}
              <TableHead sx={{ backgroundColor: '#3F51b5' }}>
                <TableRow>
                  <StyledTableCell >Nome Completo</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center' }}>CPF</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center' }}>Email</StyledTableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <StyledTableCell
                      onClick={e => handleOrder('pontuacao')}
                      sx={{ padding: '.9rem 0rem 0rem .9rem', cursor: 'pointer' }}
                    >
                      Pontuação
                    </StyledTableCell>
                    <ThemeProvider theme={theme}>
                      <UnfoldMoreIcon
                        onClick={e => handleOrder('pontuacao')}
                        fontSize="large"
                        color="neutral"
                        sx={{ padding: '.8rem 0rem 0rem 0rem', cursor: 'pointer' }}
                      />
                    </ThemeProvider>
                  </Box>
                  <StyledTableCell sx={{ textAlign: 'center' }}>Opções</StyledTableCell>
                </TableRow>
              </TableHead>

              {/*_6 Corpo da Tabela ============================================================================================== */}
              <TableBody>

                {row
                  .sort((a, b) => {
                    return a[colunmOrder] < b[colunmOrder] ? -order : order;
                  })
                  .filter((row) => {
                    if (searchTerm == "") {
                      return row;
                    } else if (row.cpf.includes(searchTerm.toLowerCase())) {
                      return row;
                    } else if (row.nomeCompleto.toLowerCase().includes(searchTerm.toLowerCase())) {
                      return row;
                    } else if (row.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                      return row;
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell component="th" scope="row">
                        {row.nomeCompleto}
                      </StyledTableCell>

                      <StyledTableCell sx={{ textAlign: 'center', width: '150px' }}>
                        {row.cpf}
                      </StyledTableCell>

                      <StyledTableCell sx={{ textAlign: 'center' }}>
                        {row.email}
                      </StyledTableCell>

                      <TableCell
                        sx={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: '#0000cd',
                          fontSize: '16px'
                        }}
                      >
                        {row.pontuacao}
                      </TableCell>
                      <StyledTableCell>
                        <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ display: 'flex', justifyContent: 'center' }}>
                          {/* <Route exact path={"/admin/cadastroPessoalEdit/:" + row.cpf} component={ component} /> */}
                          <Button
                            color="primary"
                            onClick={event => abreUsuario(event, row.id, row.cpf, row.email)}
                          >
                            Visualizar/Editar
                          </Button>

                          <VerificaBotao id={row.id} ativo={row.ativo} cpf={row.cpf} email={row.email} />

                        </ButtonGroup>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>

              {/*_7 Footer da Tabela ==================================================================================== */}
              {totalCount === 0 && !isLoading && (
                <p style={{
                  color: '#8b8b8b',
                  fontSize: '22px',
                  margin: '16px',
                  fontWeight: '400',
                }}>Não há registro</p>
              )}
              <TableFooter>
                {isLoading && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <LinearProgress variant="indeterminate" color="warning" />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={5}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      count={row.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Container>
      </div>
    </div >
  );
}