//_1 Importações
//_2 Validações Yup
//_3 Ligação com API
//_4 Styles
//_5 Formulário Login

//Importações ======================================================================================
//React
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

//MUI
import { Paper, Container, TextField, Stack, Box, Alert, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

//API
import api from ".././Services/api";

//Componentes
import stylesInput from '../Components/Stepps/InputStyle.module.css';
import stylesText from '../Components/Stepps/Text.module.css';
import styleHeader from '../Components/Header/styles';
import { useMediaQuery } from 'react-responsive'
import logo1 from "../Assets/logo-pt1.png";
import logo2 from "../Assets/logo-pt2.png";
import dadosAplicacao from "../../package.json";

//Yup
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

//iMask
import { IMaskInput } from "react-imask";

export function Cadastro() {
    if (window.location.href.substr(0, 44) === "https://habitacao.piracicaba.sp.gov.br/?_gl=") {
        window.location.href = "https://habitacao.piracicaba.sp.gov.br";
    }

    if (localStorage.getItem('AppVersion')) {
        if (localStorage.getItem('AppVersion') != dadosAplicacao.version) {
            localStorage.setItem('AppVersion', dadosAplicacao.version);
            window.location.reload(true);
        }
    } else {
        localStorage.setItem('AppVersion', dadosAplicacao.version);
        window.location.reload(true);
    }

    //_2 Validações yup ===================================================================================
    const [erro, setErro] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const navigate = useNavigate();

    const schema = yup.object({
        password:
            yup.string()
                .min(6, "Tamanho mínimo de 6 caracteres")
                .required("Defina uma senha*"),

        confirmPassword:
            yup.string()
                .oneOf([yup.ref('password'), null], 'As senhas devem coincidir')
                .required('Confirme sua senha'),

        email:
            yup.string()
                .email("Email inválido")
                .required("Defina um Email* "),

        cpf:
            yup.string()
                .required("Defina um CPF*"),


    }).required();

    const { handleSubmit, control, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

    //_3 Ligação com API ==================================================================================

    function validaToken(validadeToken) {
        // Passo 1: Converter a string de data do token para um objeto Date
        const tokenExpirationDate = new Date(validadeToken);

        // Passo 2: Obter a data atual
        const currentDate = new Date();

        // Comparar as datas
        return currentDate < tokenExpirationDate;
    }

    async function onSubmit(data) {
        setCarregando(true);
        try {
            // para validar:
            const valid = await schema.isValid(data);

            if (valid) {
                const formatCpf = data.cpf.replace(/[^a-zA-Z0-9]/g, '');

                let sucessoToken = false;

                if (!localStorage.getItem("tokenCartao") || !validaToken(localStorage.getItem("tokenValidade"))) {
                    try {
                        const response = await api.post('/ApiCartaoCidadao');
                        if (response.data.status === "100") {
                            localStorage.setItem("tokenCartao", response.data.token);
                            localStorage.setItem("tokenValidade", response.data.expira);
                            localStorage.setItem("tokenTempo", response.data.tempo);
                            sucessoToken = true;
                        } else {
                            setErro("Falha ao conectar-se com o servidor do Cartão pira cidadão. Entre em contato com o suporte");
                        }
                    } catch (err) {
                        if (err.response.status === 403) {
                            setErro(err.response.data.message);
                        } else {
                            setErro("Algum erro inesperado aconteceu, tente novamente mais tarde!");
                        }
                    }
                } else {
                    sucessoToken = true;
                }
                
                if (sucessoToken) {
                    const params = {
                        'cpf': formatCpf,
                        'token': localStorage.getItem("tokenCartao"),
                    };

                    const queryString = new URLSearchParams(params).toString();

                    try {
                        const response = await api.get('/ApiCartaoCidadao?' + queryString, "");
                        if (response.status === 200) {
                            try {
                                const headers = {
                                    'TokenPiraCidadao':  localStorage.getItem("tokenCartao"),
                                };

                                data.cpfWithoutMask = data.cpf.replace(".", "");
                                data.cpfWithoutMask = data.cpfWithoutMask.replace(".", "");
                                data.cpfWithoutMask = data.cpfWithoutMask.replace("-", "");
                                await api.post('/register', data, { headers });
                                navigate('/cadastro/verificacaoCadastro');
                            } catch (err) {
                                setErro(err.response.data.message.toString());
                            } finally {
                                setCarregando(false);
                            }
                        } else {
                            navigate('/cadastroCidadao');
                        }
                    } catch (err) {
                        if (err.response.status === 403) {
                            setErro(err.response.data.message);
                        } else {
                            setErro("Algum erro inesperado aconteceu, tente novamente mais tarde!");
                        }
                    } finally {
                        setCarregando(false);
                    }
                }
            } else {
                // tem algum erro de validação.
                setErro("Erro de validação");
                setCarregando(false);
            }
        } catch (err) {
            console.log('valid', err);
            setCarregando(false);
        }

    }

    //_4 Styles ======================================================================================
    const style = {
        logoText: {
            marginTop: 'auto',
            marginBottom: 'auto',
            marginLeft: '20px'
        },
        titulo: {
            fontWeight: '600',
            fontSize: '21px'
        },
        subtitulo: {
            color: '#8b8b8b',
            fontSize: '14px',
            marginTop: '10px',
            fontWeight: '500'
        },
        linkCadastro: {
            color: '#3F51b5',
            fontWeight: '500',
            fontSize: '18px',
        },
    }

    const titleHeader = useMediaQuery({
        query: '(min-width: 640px)'
    })

    return (
        <div style={styleHeader.mainStyle}>
            <Box sx={styleHeader.header}>
                <Box sx={styleHeader.logoContainer}>
                    <img style={styleHeader.imgLogo} src={logo1} height='50' alt='' />
                </Box>
                {titleHeader &&
                    <Box sx={styleHeader.titulo}>
                        CADASTRO DE DEMANDA PARA HABITAÇÃO DE INTERESSE SOCIAL
                    </Box>
                }

                {/* <h1
                        style={{
                            fontSize: '28px',
                            fontWeight: '800',
                            color: '#F78C2F'
                        }}
                    >
                        HOMOLOGAÇÃO
                    </h1> */}

                <Box sx={styleHeader.logoContainer}>
                    <img style={styleHeader.imgLogo2} src={logo2} height='50' alt='' />
                </Box>
            </Box>

            <Container sx={{ pt: '8vh', pb: '8vh' }}>

                <Paper sx={{ p: 4, maxWidth: 720, margin: 'auto', borderRadius: '16px' }}>
                    <Box sx={{ mb: 3, position: 'relative' }}>
                        <p className={stylesText.titulo} style={{ fontSize: '26px', textAlign: 'center', color: '#EA580C' }}>
                            Seja bem-vindo(a) !
                        </p>
                        <p className={stylesText.titulo} style={{ textAlign: 'center', color: '#3F51b5' }}>
                            Faça seu cadastro
                        </p>
                    </Box>

                    {/*_5 Formulário Login ============================================================================================= */}
                    <form onSubmitCapture={handleSubmit(onSubmit)} >
                        <Stack spacing={1} >
                            <Controller
                                control={control}
                                name="name"
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            required
                                            placeholder="Nome Completo *"
                                            {...field}
                                        />
                                    </>
                                )}
                            />
                            <Controller
                                control={control}
                                name="cpf"
                                render={({ field }) => (
                                    <>
                                        <IMaskInput
                                            required
                                            className={stylesInput.InputStyle}
                                            mask="000.000.000-00"
                                            minLength="14"
                                            maxLength="14"
                                            placeholder="CPF *"
                                            {...field}  // importante
                                        >
                                        </IMaskInput>
                                        <p style={{ color: 'red', marginTop: '8px', display: 'flex' }}>{errors.cpf?.message}</p>
                                    </>
                                )}
                            />
                            <Controller
                                control={control}
                                name="email"
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            required
                                            placeholder="Email *"
                                            {...field}  // importante
                                        />
                                        <p style={{ color: 'red', marginTop: '8px', display: 'flex' }}>{errors.email?.message}</p>
                                    </>
                                )}
                            />
                            <Controller
                                control={control}
                                name="password"
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            required
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="Senha *"
                                            {...field} // importante
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <p style={{ color: 'red', marginTop: '8px', display: 'flex' }}>{errors.password?.message}</p>
                                    </>
                                )}
                            />
                            <Controller
                                control={control}
                                name="confirmPassword"
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            required
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            placeholder=" Confirmar Senha *"
                                            {...field} // importante
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <p style={{ color: 'red', marginTop: '8px', display: 'flex' }}>{errors.confirmPassword?.message}</p>
                                    </>
                                )}
                            />

                            {erro &&
                                <Alert severity='error'>
                                    {erro}
                                </Alert>
                            }

                            <Stack direction='row' style={{ width: '100%' }}>
                                <LoadingButton
                                    style={{
                                        backgroundColor: '#3F51b5',
                                        padding: '14px',
                                        borderRadius: '12px',
                                        width: '100%'
                                    }}
                                    fullWidth
                                    loading={carregando}
                                    variant='contained'
                                    type='submit' // importante
                                    disableElevation
                                >
                                    Cadastrar
                                </LoadingButton>
                            </Stack>

                            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <p
                                    className={stylesText.perguntaCadastro}
                                >
                                    Você ja tem um cadastro ?
                                </p>

                                <Link
                                    to="/login"
                                    className={stylesText.linkCadastro}
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Entre aqui.
                                </Link>

                            </Box>

                        </Stack>
                    </form>
                </Paper>

            </Container>
        </div >
    );
}