export const saveDependentesStorage = (value) => {
  try {
    var jsonString = JSON.stringify(value);
    sessionStorage.setItem("@Dependentes", jsonString);
  } catch (error) {
    console.error("erro ao salvar dados dependentes offline: ", error);
  }
};

export const getDependentesStorage = () => {
  try {
    var jsonString = sessionStorage.getItem("@Dependentes");
    return JSON.parse(jsonString);
  } catch (error) {
    console.error("erro ao salvar dados dependentes offline: ", error);
  }
};

export const saveFinanceiroStorage = (value) => {
  try {
    var jsonString = JSON.stringify(value);
    sessionStorage.setItem("@Financeiro", jsonString);
  } catch (error) {
    console.error("erro ao salvar dados dependentes offline: ", error);
  }
};
