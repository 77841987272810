import { FormControl, FormLabel, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default function ControlledTextFieldWithLabel({
  control,
  name,
  placeholder,
  label,
  defaultValue,
  type,
  errors,
  rest,
  style,
  styleFormControl
}) {
  return (
    <Controller
      render={({ field }) => (
        <FormControl style={styleFormControl}>
          <FormLabel>{label}</FormLabel>
          <TextField
            placeholder={placeholder}
            error={errors?.[name] !== undefined && errors?.[name] !== null}
            // helperText={errors?.[name]?.message}
            style={style}
            {...rest}
            {...field}
          />
        </FormControl>
      )}
      control={control}
      name={name}
      defaultValue={defaultValue}
      type={type}
    />
  );
}
