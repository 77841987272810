import { FormControl, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default function ControlledTextField({
  control,
  name,
  placeholder,
  otherPropertiesFormControl,
  defaultValue,
  sxInput,
  InputLabelProps,
  id,
  label,
  inputStyle,
  type,
  errors,
  originalError,
  disabled = false,
}) {
  return (
    <Controller
      render={({ field }) => (
        <FormControl {...otherPropertiesFormControl}>
          <TextField
            id={id}
            sx={sxInput}
            label={label}
            placeholder={placeholder}
            InputLabelProps={InputLabelProps}
            type={type}
            error={
              !!originalError
                ? originalError
                : errors?.[name] !== undefined && errors?.[name] !== null
            }
            // helperText={
            //   !!originalError ? originalError?.message : errors?.[name]?.message
            // }
            {...field}
            style={inputStyle}
            disabled={disabled}
          />
        </FormControl>
      )}
      control={control}
      name={name}
      defaultValue={defaultValue}
    />
  );
}
