import { FormControl, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import InputMask from 'react-input-mask';

const inputStyleFix = {
    font: 'inherit',
    letterSpacing: 'inherit',
    color: 'currentColor',
    padding: '4px 0 5px',
    border: '0',
    boxSizing: 'content-box',
    background: 'none',
    height: '1.4375em !important',
    WebkitTapHighlightColor: 'transparent',
    display: 'block',
    minWidth: '0',
    WebkitAnimationName: 'mui-auto-fill-cancel',
    animationName: 'mui-auto-fill-cancel',
    WebkitAnimationDuration: '10ms',
    animationDuration: '10ms',
    padding: '16.5px 14px',
    borderRadius: '5px',
    border: '1px solid #ccc',
};

export default function ControlledInputMask({
    control,
    name,
    placeholder,
    otherPropertiesFormControl,
    defaultValue,
    sxInput,
    InputLabelProps,
    id,
    label,
    inputStyle,
    type,
    errors,
    originalError,
    disabled = false,
    mask,
}) {
    return (
        <Controller
            render={({ field: { onChange, value } }) => (
                <FormControl {...otherPropertiesFormControl}>
                    <InputMask mask={mask} value={value} onChange={onChange}>
                        {
                            inputProps => (
                                <TextField
                                    id={id}
                                    sx={sxInput}
                                    label={label}
                                    placeholder={placeholder}
                                    InputLabelProps={InputLabelProps}
                                    type={type}
                                    error={
                                        !!originalError
                                            ? originalError
                                            : errors?.[name] !== undefined && errors?.[name] !== null
                                    }
                                    // helperText={
                                    //     !!originalError
                                    //         ? originalError?.message
                                    //         : errors?.[name]?.message
                                    // }
                                    {...inputProps}
                                    style={inputStyle}
                                />
                            )
                        }
                    </InputMask>
                </FormControl>
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
        />
    );
}
