//Importações do React
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect } from 'react';
import * as yup from 'yup';

// Importações do MUI 
import { Grid } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import { Alert } from '@mui/material';

//   Importações do "Stepper" 
import { StepperContext } from "../../Contexts/StepperContext";
import Stepper from '../../Components/Stepper/Stepper';

// Demais importações
import { getDados, getDadosById, incluirDados } from '../../Services/globalRequests';
import { CamposDependentes } from '../../Components/CamposDependentes';
import { saveDependentesStorage } from '../../Utils/GlobalStorage';
import { Header } from '../../Components/Header';
import ControlledOutlinedInput from '../../Components/ControlledOutlinedInput';
import ControlledTextFieldWithLabel from '../../Components/ControlledTextFieldWithLabel';
import ControlledSelect from '../../Components/ControlledSelect';
import { differenceInYears, set } from 'date-fns';
import { message } from 'antd';

export const CadDependentes = ({
    endpoint = "/users/addresses",
    endpoint2 = "/users/dependents",
    endpoint3 = "/users/finance",
}) => {

    const [dados, setDados] = useState([]);
    const [carregando, setCarregando] = useState(false);
    const [stepData, setStepData] = useState('');
    const [finalData, setFinalData] = useState([]);
    const selectTipoDeRendaOptions = [
        { value: 1, description: "Autônomo"},
        { value: 2, description: "Bicos" },
        { value: 3, description: "Carteira Registrada" },
        { value: 4, description: "Estagiário" },
        { value: 5, description: "LOAS / BPC" },
        { value: 6, description: "Bolsa Família" },
        { value: 7, description: "Pensão" },
        { value: 8, description: "Aposentadoria" },
        { value: 9, description: "Outros Benefícios" },
        { value: 10, description: "Sem Renda" },
    ]

    const schema = yup.object().shape({
        showDependentes: yup.boolean(),
        renda:
            yup.number()
                .required("Por favor, nos informe a sua renda.")
                .moreThan(-1, 'A sua renda não pode ser negativa')
                .test('decimal-places', 'Máximo de 2 casas decimais permitidas em renda', value => {
                    if (value !== undefined) {
                        // Use toFixed para limitar as casas decimais a 2
                        return value.toFixed(2) == value;
                    }
                })
                .max(99999, "Valor máximo excedido em renda do titular"),
        profissao:
            yup.string()
                .required("Por favor, nos informe a sua profissão.")
                .nullable("Por favor, nos informe a sua profissão."),
        quantidadeDeResidentes:
            yup.number()
                .required("Por favor, nos informe quantidade de residentes.")
                .min(1, "Deve haver pelo menos um residente.")
                .max(30, "O número máximo de dependentes é 30"),
        tipoDeRenda:
            yup.number()
                .required("Por favor, nos informe tipo de renda."),
        dependentes:
            yup.array()
                .of(
                    yup.object().shape({
                        nomeCompletoFamiliar: yup.string().required("Nome completo do familiar: Campo obrigatório"),
                        cpfFamiliar: yup.string().when('dataNascimentoFamiliar', {
                            is: (value) => differenceInYears(new Date(), new Date(value)) >= 18,
                            then: (schema) => schema.required("CPF do Familiar: Campo obrigatório"),
                            otherwise: (schema) => schema.notRequired()
                        }),
                        // rgFamiliar: yup.string().required("Campo obrigatório"),
                        nivelParentescoFamiliar: yup.string().required("Nível de parentesco do familiar: Campo obrigatório"),
                        dataNascimentoFamiliar: yup.date().required("Data de nascimento do familiar: Campo obrigatório").typeError("Data de nascimento do familiar: Campo obrigatório"),
                        tipoDeficienciaFamiliar: yup.number().when('familiarDeficiente', {
                            is: (value) => value == 'true', // isso não é o correto... depois arrumem por favor. #XGH
                            then: (schema) => schema.required("Tipo de deficiência do familiar: Campo obrigatório").min(1, "Tipo de deficiência do familiar: Selecione um tipo."),
                            otherwise: (schema) => schema.notRequired()
                        }),
                        familiarDeficiente: yup.string(),
                        familiarDoencaCronica: yup.string(),
                        descricaoDoencaCronicaFamiliar: yup.string().when('familiarDoencaCronica', {
                            is: (value) => value == 'true', // isso não é o correto... depois arrumem por favor. #XGH
                            then: (schema) => schema.required("Tipo de doença crônica do familiar: Campo obrigatório"),
                            otherwise: (schema) => schema.notRequired()
                        }),
                        genero: yup.number().required("Gênero do familiar: Campo obrigatório").typeError("Gênero do familiar: Campo obrigatório"),
                        escolaridade: yup.number().required("Escolaridade do familiar: Campo obrigatório").typeError("Escolaridade do familiar: Campo obrigatório"),
                        profissao: yup.string(),
                        renda: yup.number(),
                        tipoDeRenda: yup.number(),
                    })
                )
                .required('Required')
    });

    const { id } = useParams();
    const navigate = useNavigate();
    const methods = useForm({ resolver: yupResolver(schema) });
    const { control, formState: { errors } } = methods;

    const steps = [
        "Dados Pessoais",
        "Endereço Atual",
        "Composição e Renda Familiar",
        "Gasto mensal (Familiar)",
        "Anexos",
        "Finalizado"
    ];

    useEffect(() => {
        setCarregando(true);

        if (
            window.location.href === 'https://habitacao.piracicaba.sp.gov.br/#/cadastroDependentes' ||
            window.location.href === 'https://habita-teste.pmp.sp.gov.br/#/cadastroDependentes' ||
            window.location.href === 'https://localhost:88/#/cadastroDependentes' ||
            window.location.href === 'http://localhost:3000/#/cadastroDependentes'
        ) {
            getDados(endpoint2)
                .then(saveDataUrlAtual)
                .catch(console.log)
                .finally(() => setCarregando(false))
        } else {
            getDadosById(endpoint2, id)
                .then(saveDataUrlAtual)
                .catch((err) => {
                    if (err.response.status === 403) {
                        sessionStorage.removeItem("token");
                        sessionStorage.clear()
                        navigate('/');
                    } else {
                        setErro(err);
                    }
                })
                .finally(() => setCarregando(false))
        }

        return () => {
            console.log('fechando useEffect Dependentes');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function voltar(e) {
        setCarregando(true)

        if (
            window.location.href === 'https://habitacao.piracicaba.sp.gov.br/#/cadastroDependentes' ||
            window.location.href === 'https://habita-teste.pmp.sp.gov.br/#/cadastroDependentes' ||
            window.location.href === 'https://localhost:88/#/cadastroDependentes' ||
            window.location.href === 'http://localhost:3000/#/cadastroDependentes'
        ) {
            getDados(endpoint)
                .then(SaveDataAdress)
                .catch(err => console.log(err))
                .finally(() => {
                    setCarregando(false);
                    navigate('/cadastroMoradia');
                })
        } else {
            getDadosById("/users/addresses", id)
                .then((data) => {
                    SaveDataAdress(data);
                    navigate('/cadastroMoradia/edit/' + id);

                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        sessionStorage.removeItem("token");
                        sessionStorage.clear()
                        navigate('/');
                    } else {
                        setErro(err);
                    }
                })
                .finally(() => {
                    setCarregando(false)
                });
        }
    }

    const [msgErro, setMsgErro] = useState('');
    const [erro, setErro] = useState(false);

    const onSubmit = async (data) => {
        if(data.tipoDeRenda == 0){
            data.tipoDeRenda = 1;
        }

        setCarregando(true);

        changeToBoolean(data.dependentes);
        sessionStorage.setItem("rendaMensal", calculaSalarios(data.dependentes, data.renda));
        sessionStorage.setItem("qtdResidentes", data.dependentes.length);

        try {
            const isValid = await schema.isValid(data);
            console.log("ERRO NO COMECIN =>", erro);
            console.log('está valido:', isValid);

            if (
                window.location.href === 'https://habitacao.piracicaba.sp.gov.br/#/cadastroDependentes' ||
                window.location.href === 'https://habita-teste.pmp.sp.gov.br/#/cadastroDependentes' ||
                window.location.href === 'https://localhost:88/#/cadastroDependentes' ||
                window.location.href === 'http://localhost:3000/#/cadastroDependentes'
            ) {
                await incluirDados(endpoint2, data);

            } else {
                await incluirDados(endpoint2 + "/" + id, data);

            }

            if (
                window.location.href === 'https://habitacao.piracicaba.sp.gov.br/#/cadastroDependentes' ||
                window.location.href === 'https://habita-teste.pmp.sp.gov.br/#/cadastroDependentes' ||
                window.location.href === 'https://localhost:88/#/cadastroDependentes' ||
                window.location.href === 'http://localhost:3000/#/cadastroDependentes'
            ) {
                await getDados(endpoint3)
                    .then(SaveDataFinance)
                    .catch(console.error)
                    .finally(() => {
                        setCarregando(false);
                        navigate('/cadastroFinanceiro');
                    });

            } else {
                await getDadosById(endpoint3, id)
                    .then((data) => {
                        SaveDataFinance(data)
                        navigate("/cadastroFinanceiro/edit/" + id);
                    })
                    .catch((err) => {
                        if (err.response.status === 403) {
                            sessionStorage.removeItem("token");
                            sessionStorage.clear()
                            navigate('/');
                        } else {
                            setErro(err);
                        }
                    })
                    .finally(() => {
                        setCarregando(false);
                        navigate("/cadastroFinanceiro/edit/" + id);
                    });

            }
            console.log("ERRO NO FINALZIN  =>", erro);
        } catch (err) {
            if (err.response.status === 403) {
                sessionStorage.removeItem("token");
                sessionStorage.clear();
                navigate('/');
            } else {
                console.log(err)
                setErro(true);
                setMsgErro(err.response.data.message);
                setCarregando(false);
            }
           
        }
    }


    function changeToBoolean(data) {
        for (var i in data) {
            data[i].familiarDeficiente = data[i].familiarDeficiente == 'true';
            data[i].familiarDependente = data[i].familiarDependente == 'true';
            data[i].familiarDoencaCronica = data[i].familiarDoencaCronica == 'true';
        }
        console.log(data);
        return data;
    }

    function calculaSalarios(dependentes, rendaPrincipal) {
        var renda = 0;
        for (var i in dependentes) {
            renda += parseFloat(dependentes[i].renda);
        }

        renda += parseFloat(rendaPrincipal);

        return renda;
    }

    function saveDataUrlAtual(dados) {
        setDados(dados);
        console.log(dados.dependentes.length);
        methods.setValue("quantidadeDeResidentes", dados.dependentes.length + 1);
        methods.setValue("profissao", dados.profissao);
        methods.setValue("renda", dados.renda);
        methods.setValue("tipoDeRenda", dados.tipoDeRenda);
        saveDependentesStorage(dados);
    }

    function SaveDataAdress(dados) {
        console.log(dados);
        sessionStorage.setItem("cep", dados.cep);
        sessionStorage.setItem("bairro", dados.bairro);
        sessionStorage.setItem("rua", dados.rua);
        sessionStorage.setItem("numero", dados.numero);
        sessionStorage.setItem("estado", dados.estado);
        sessionStorage.setItem("cidade", dados.cidade);
        sessionStorage.setItem("tempoResidindoNaMoradiaAtual", dados.tempoResidindoNaMoradiaAtual);
        sessionStorage.setItem("tipoResidencia", dados.tipoResidencia);
        sessionStorage.setItem("condicaoDaMoradia", dados.condicaoDaMoradia);
        sessionStorage.setItem("referencia", dados.referencia);
        sessionStorage.setItem("complemento", dados.complemento);
        sessionStorage.setItem("possuiBanheiro", dados.possuiBanheiro);
        sessionStorage.setItem("moradorDeRua", dados.moradorDeRua);
        sessionStorage.setItem("quantidadeDeBanheiros", dados.quantidadeDeBanheiros);
        sessionStorage.setItem("quantidadeDeComodos", dados.quantidadeDeComodos);
        sessionStorage.setItem("possuiEncanamento", dados.possuiEncanamento);
        sessionStorage.setItem("possuiEletricidade", dados.possuiEletricidade);
        sessionStorage.setItem("possuiEsgoto", dados.possuiEsgoto);
        sessionStorage.setItem("possuiColetaDeLixo", dados.possuiColetaDeLixo);
        sessionStorage.setItem("possuiAsfalto", dados.possuiAsfalto);
        sessionStorage.setItem("tempoTrabalho", dados.tempoTrabalho);
        setDados(dados);
    }

    function SaveDataFinance(dados) {
        console.log(dados);
        sessionStorage.setItem("valorAgua", dados.valorAgua);
        sessionStorage.setItem("valorAlimentacao", dados.valorAlimentacao);
        sessionStorage.setItem("valorAluguel", dados.valorAluguel);
        sessionStorage.setItem("valorEducacao", dados.valorEducacao);
        sessionStorage.setItem("valorEnergia", dados.valorEnergia);
        sessionStorage.setItem("valorGasDeCozinha", dados.valorGasDeCozinha);
        sessionStorage.setItem("valorMedicamentos", dados.valorMedicamentos);
        sessionStorage.setItem("valorOutros", dados.valorOutros);
        sessionStorage.setItem("valorPlanoDeSaude", dados.valorPlanoDeSaude);
        sessionStorage.setItem("valorPlanos", dados.valorPlanos);
        sessionStorage.setItem("valorSalario", dados.valorSalario);
        sessionStorage.setItem("valorTransporte", dados.valorTransporte);
        setDados(dados);
    }

    function percorrerErrors(obj) {
        const errorElements = [];

        for (const key in obj) {
            if (Array.isArray(obj[key])) {
                for (const elemento of obj[key]) {
                    errorElements.push(percorrerErrors(elemento));
                }
            } else if (typeof obj[key] === 'object') {
                errorElements.push(percorrerErrors(obj[key]));
            } else {
                console.log(key, obj[key]);
                if (key === 'message') {
                    errorElements.push(
                        <Alert severity="error">
                            {obj[key]}
                        </Alert>
                    );
                }
            }
        }

        return errorElements;
    }


    return (
        <div className="BackgroundImg">
            <Header />
            <div className='mx-auto my-4 rounded-2xl bg-white shadow-xl md:w-2/3 pb-6 p-1'>
                <div className="container horizontal mt-8">
                    <Stepper steps={steps} currentStep={2} />
                    <div className="my-0 p-10">
                        <StepperContext.Provider value={{
                            stepData,
                            setStepData,
                            finalData,
                            setFinalData
                        }}>
                        </StepperContext.Provider>

                        <div className='flex justify-between items-center my-8'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <h1 style={{
                                        fontFamily: 'Exo-SemiBold',
                                        fontSize: '32px'
                                    }}>
                                        Composição/Renda do Titular e dos Familiares
                                    </h1>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <p
                                        style={{
                                            fontFamily: 'Exo-SemiBold',
                                            fontSize: '22px',
                                            textAlign: 'end'
                                        }}
                                    >
                                        {sessionStorage.getItem("cpf")}
                                    </p>
                                    <p
                                        style={{
                                            fontFamily: 'Exo-SemiBold',
                                            fontSize: '22px',
                                            textAlign: 'end'
                                        }}
                                    >
                                        {sessionStorage.getItem("userName")}
                                    </p>
                                </Grid>
                            </Grid>
                        </div>
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>

                                <h1 style={{
                                    fontFamily: 'Exo-SemiBold',
                                    fontSize: '24px',
                                    marginTop: 2
                                }}>
                                    Composição e Renda dos Familiares
                                </h1>

                                <div className='flex flex-col'>
                                    <ControlledOutlinedInput
                                        control={control}
                                        errors={errors}
                                        name={'quantidadeDeResidentes'}
                                        label={'Quantas pessoas moram na sua casa?'}
                                        placeholder={'Quantas pessoas moram na sua casa?'}
                                        startAdornment={<InputAdornment position="start"></InputAdornment>}
                                        inputProps={{
                                            step: "1",
                                            min: 1,
                                            maxLenght: 2
                                        }}
                                        sxFormControl={{ mt: 2 }}
                                    />
                                    <br />
                                </div>

                                <h2 style={{
                                    fontFamily: 'Exo-SemiBold',
                                    fontSize: '24px'
                                }}>
                                    Renda do Titular: {sessionStorage.getItem("nome")}
                                </h2>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <ControlledOutlinedInput
                                            control={control}
                                            errors={errors}
                                            name={'renda'}
                                            label={'Renda'}
                                            placeholder={'Renda'}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <ControlledSelect
                                            control={control}
                                            errors={errors}
                                            name="tipoDeRenda"
                                            label={'Tipo de renda'}
                                            selectOptions={selectTipoDeRendaOptions}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <ControlledTextFieldWithLabel
                                        control={control}
                                        errors={errors}
                                        name='profissao'
                                        label="Profissão/Ocupação"
                                        placeholder="Profissão/Ocupação"
                                        sx={{ width: "100%", mr: 2 }}
                                        styleFormControl={{ width: '100%', marginTop: '8px' }}
                                        style={{ width: 'auto', marginBottom: '32px' }}
                                    />
                                </Grid>
                                <br />
                                <Divider light />
                                <br />

                                <CamposDependentes defaultValues={dados} />

                                <div className='flex justify-between'>
                                    <LoadingButton
                                        style={{
                                            padding: '16px',
                                            borderRadius: '12px'
                                        }}
                                        variant='outlined'
                                        size="large"
                                        onClick={e => voltar(e)}
                                    >
                                        Voltar
                                    </LoadingButton>

                                    <LoadingButton
                                        style={{
                                            backgroundColor: '#3F51b5',
                                            padding: '16px',
                                            borderRadius: '12px'
                                        }}
                                        loading={carregando}
                                        type='submit'
                                        variant='contained'
                                        size="large"
                                    // onClick={onNavigate}
                                    >
                                        Próximo
                                    </LoadingButton>
                                </div>
                            </form>
                        </FormProvider>
                        <div>
                            {erro && <Alert severity="error">{msgErro}</Alert>}
                            {percorrerErrors(errors)}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}