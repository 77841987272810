import fundoLogin from '../../Assets/piracicaba_preto70.png'

const styleHeader = {
  mainStyle: {
    backgroundImage: `url(${fundoLogin})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    height: '100%',
    textAlign: 'center'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '32px',
    width: '100%',
    height: '80px',
    background: "linear-gradient(90deg,#263657,#276dad)",
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#FFF',
  },
  imgLogo: {
    width: '6rem',
    minWidth: '5rem',
    cursor: 'pointer'
  },
  titulo: {
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: '800',
    color: '#fff',
    display: 'flex',
  },
  imgLogo2: {
    width: '8rem',
    minWidth: '5rem',
    cursor: 'pointer'
  },
  headerEnd: {
    display: 'flex',
    alignItems: 'center'
  },
}

export default styleHeader
