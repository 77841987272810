// Guia da divisão do código:
// _1 Importações
// _2 Estilos
// _3 Declaração das constantes
// _4 Declaração dos Selects
// _5 API do ViaCep
// _6 Funções do Stepper
// _7 Yup: Validação do formulário
// _8 Retorna os valores da sessão nos inputs
// _9 Salva as informações dos inputs na sessão e envia para a API
// _10 Stepper
// _11 Campos Moradia
// _12 Campos Comunicação
// _13 Erros do Yup


// _1 Importações =============================================================================================
//Importações do React
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import React from 'react';

// Importações do MUI 
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

//impotação da API
import { getDados, getDadosById } from '../../Services/globalRequests';
import api from "../../Services/api";

//   Importações do "Stepper" 
import { StepperContext } from "../../Contexts/StepperContext";
import Stepper from '../../Components/Stepper/Stepper';

// Importações de estilo (.css e imagens)
import BackgroundImg from '../../Components/Background/estiloPages.css';
import styles from '../../Components/Stepps/InputStyle.module.css';

// Importações para o formulário
import { FormControl, InputLabel, MenuItem, Select, TextField, Alert, Button } from '@mui/material';
import { IMaskInput } from "react-imask";

//Importações yup
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Demais importações
import { Header } from '../../Components/Header';
import { CadDadosPessoais } from './cadDadosPessoais';

const handleInputChange = (event) => {
    let inputValue = event.target.value;

    // Remove qualquer não-dígito
    inputValue = inputValue.replace(/\D/g, '');

    // Limita a dois dígitos
    inputValue = inputValue.slice(0, 2);
    // Atualiza o valor do campo
    event.target.value = inputValue;
}

const handleInputChangeValueMin = (event) => {
    let inputValue = event.target.value;

    // Remove qualquer não-dígito
    inputValue = inputValue.replace(/\D/g, '');

    // Limita a dois dígitos
    inputValue = inputValue.slice(0, 1);

    // Garante que o valor seja no mínimo 1
    if (parseInt(inputValue, 10) < 1) {
        inputValue = '1';
    }

    // Atualiza o valor do campo
    event.target.value = inputValue;
}

export const CadMoradia = ({
    endpoint = "/users/data",
    endpoint2 = "/users/dependents",
    bodyParametros,
    open,
    handleClose
}) => {
    const { id } = useParams();

    // _2 Estilos =============================================================================================
    const style = {
        inputAdd: {
            padding: '8px',
            border: '1px solid #C8C8C8',
            borderRadius: '4px',
            marginRight: '4px'
        },
        containerStepper: {
            height: 'auto',
            margin: 'auto',
            marginTop: '80px'
        }
    }

    // _3 Declaração de constantes ============================================================================
    const [openCondicaoMoradia, setOpenCondicaoMoradia] = useState(false);
    const [campoDesabilitado, setCampoDesabilitado] = useState((sessionStorage.getItem("condicaoDaMoradia") == 5) ? true : false);
    const [condicaoMoradia, setCondicaoMoradia] = useState(!!sessionStorage.getItem("condicaoDaMoradia") ? sessionStorage.getItem("condicaoDaMoradia") : 1);
    const [carregando, setCarregando] = useState(false);
    const [openTipo, setOpenTipo] = useState(false);
    const [tipo, setTipo] = useState('');

    // _4 Selects =============================================================================================


    const handleChangeTipo = (event) => {
        setTipo(event.target.value)
    }

    const handleCloseTipo = () => {
        setOpenTipo(false)
    }

    const handleOpenTipo = () => {
        setOpenTipo(true)
    }

    const handleChangeCondicaoMoradia = (event) => {
        setCondicaoMoradia(event.target.value);
        setCampoDesabilitado(event.target.value == 5);
    }

    const handleCloseCondicaoMoradia = () => {
        setOpenCondicaoMoradia(false)
    }

    const handleOpenCondicaoMoradia = () => {
        setOpenCondicaoMoradia(true)
    }




    // _5 Buscando o CEP na API do ViaCep =====================================================================

    const { register, setValue, setFocus } = useForm();
    const [data, setData] = useState(null);
    const [CEP, setCEP] = useState(null);
    const checkCEP = (e) => {
        const cep = e.target.value.replace(/\D/g, '');
        console.log(cep);
        let res = null;
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(res => res.json()).then(data => {
                console.log(data);

                const { cep, logradouro, bairro, localidade, uf } = data;
                let postalCoderesponse = { "cep": cep, "rua": logradouro, "bairro": bairro, "cidade": localidade, "estado": uf };

                setValue('rua', logradouro);
                setValue('bairro', bairro);
                setValue('cidade', localidade);
                setValue('estado', uf);
                setFocus('numero');

                setCEP(postalCoderesponse);
            });
    }


    // _6 Funções para o funcionamento correto do Stepper =======================================================
    const [currentStep, setCurrentStep] = useState(1);
    const [stepData, setStepData] = useState('');
    const [finalData, setFinalData] = useState([]);
    const navigate = useNavigate()

    const handleLogout = () => {
        sessionStorage.removeItem("token");
        navigate("/");
    }

    const steps = [
        "Dados Pessoais",
        "Endereço Atual",
        "Composição e Renda Familiar",
        "Gasto mensal (Familiar)",
        "Anexos",
        "Finalizado"
    ];

    const [dados, setDados] = useState('');
    const [erro, setErro] = useState();

    // _7 Validação do formulário =============================================================================
    const schema = yup.object({
        // bairro:
        //     yup.string().when('moradorDeRua', {
        //         is: (value) => value == false,
        //         then: (schema) => schema.required("Por favor, nos informe o seu bairro."),
        //         otherwise: (schema) => schema.notRequired()
        //     }),

        // rua:
        //     yup.string().when('moradorDeRua', {
        //         is: (value) => value == false,
        //         then: (schema) => schema.required("Por favor, nos informe a sua rua."),
        //         otherwise: (schema) => schema.notRequired()

        //     }),

        // numero:
        //     yup.string().when('moradorDeRua', {
        //         is: (value) => value == false,
        //         then: (schema) => schema.required("Por favor, nos informe o número da sua casa."),
        //         otherwise: (schema) => schema.notRequired()

        //     }),

        // estado:
        //     yup.string().when('moradorDeRua', {
        //         is: (value) => value == false,
        //         then: (schema) => schema.required("Por favor, nos informe o seu estado."),
        //         otherwise: (schema) => schema.notRequired()

        //     }),

        // cidade:
        //     yup.string().when('moradorDeRua', {
        //         is: (value) => value == false,
        //         then: (schema) => schema.required("Por favor, nos informe a sua cidade."),
        //         otherwise: (schema) => schema.notRequired()

        //     }),

        tempoResidindoNaMoradiaAtual:
            yup.number().when('moradorDeRua', {
                is: (value) => value == false,
                then: (schema) => schema.required("Por favor, nos informe há quanto tempo você reside em sua casa.").typeError("Por favor, nos informe há quanto tempo você reside em sua casa."),
                otherwise: (schema) => schema.notRequired()

            }),


        condicaoDaMoradia:
            yup.string().when('moradorDeRua', {
                is: (value) => value == false,
                then: (schema) => schema.required("Por favor, nos informe a condição de sua casa."),
                otherwise: (schema) => schema.notRequired()

            }),

        tipoResidencia:
            yup.string().when(['moradorDeRua', 'condicaoDaMoradia'], {
                is: (moradorDeRua, condicaoDaMoradia) => moradorDeRua == false || condicaoDaMoradia != 5,
                then: (schema) => schema.required("Por favor, nos informe o tipo de sua casa."),
                otherwise: (schema) => schema.notRequired()

            }),


        possuiBanheiro:
            yup.boolean().when(['moradorDeRua', 'condicaoDaMoradia'], {
                is: (moradorDeRua, condicaoDaMoradia) => moradorDeRua == false || condicaoDaMoradia != 5,
                then: (schema) => schema.required("Por favor, nos informe se a sua casa contém banheiro."),
                otherwise: (schema) => schema.notRequired()

            }),



        quantidadeDeBanheiros:
            yup.number().when(['moradorDeRua', 'condicaoDaMoradia'], {
                is: (moradorDeRua, condicaoDaMoradia) => moradorDeRua == false || condicaoDaMoradia != 5,
                then: (schema) => schema.required("Por favor, nos informe a quantidade de banheiros que há em sua casa."),
                otherwise: (schema) => schema.notRequired()

            }),

        quantidadeDeComodos:
            yup.number().when(['moradorDeRua', 'condicaoDaMoradia'], {
                is: (moradorDeRua, condicaoDaMoradia) => moradorDeRua == false || condicaoDaMoradia != 5,
                then: (schema) => schema.required("Por favor, nos informe a quantidade de cômodos de sua casa.").typeError("Por favor, nos informe a quantidade de cômodos de sua casa."),
                otherwise: (schema) => schema.notRequired()

            }),


        possuiEncanamento:
            yup.boolean().when(['moradorDeRua', 'condicaoDaMoradia'], {
                is: (moradorDeRua, condicaoDaMoradia) => moradorDeRua == false || condicaoDaMoradia != 5,
                then: (schema) => schema.required("Por favor, nos informe se a sua casa possui água encanada."),
                otherwise: (schema) => schema.notRequired()

            }),

        possuiEletricidade:
            yup.boolean().when(['moradorDeRua', 'condicaoDaMoradia'], {
                is: (moradorDeRua, condicaoDaMoradia) => moradorDeRua == false || condicaoDaMoradia != 5,
                then: (schema) => schema.required("Por favor, nos informe se a sua casa possui rede elétrica."),
                otherwise: (schema) => schema.notRequired()

            }),

        tempoTrabalho:
            yup.number().when(['moradorDeRua', 'condicaoDaMoradia'], {
                is: (moradorDeRua, condicaoDaMoradia) => moradorDeRua == false || condicaoDaMoradia != 5,
                then: (schema) => schema.required("Por favor, nos informe quanto tempo você demora para realizar seu trajeto até o trabalho"),
                otherwise: (schema) => schema.notRequired()

            }),

            possuiAsfalto:
            yup.boolean().when(['moradorDeRua', 'condicaoDaMoradia'], {
                is: (moradorDeRua, condicaoDaMoradia) => moradorDeRua == false || condicaoDaMoradia != 5,
                then: (schema) => schema.required("Por favor, nos informe se a rua da sua casa possui asfalto"),
                otherwise: (schema) => schema.notRequired()

            }),

        possuiEsgoto:
            yup.boolean().when(['moradorDeRua', 'condicaoDaMoradia'], {
                is: (moradorDeRua, condicaoDaMoradia) => moradorDeRua == false || condicaoDaMoradia != 5,
                then: (schema) => schema.required("Por favor, nos informe se a sua casa possui rede de esgoto."),
                otherwise: (schema) => schema.notRequired()

            }),

        possuiColetaDeLixo:
            yup.boolean().when(['moradorDeRua', 'condicaoDaMoradia'], {
                is: (moradorDeRua, condicaoDaMoradia) => moradorDeRua == false || condicaoDaMoradia != 5,
                then: (schema) => schema.required("Por favor, nos informe se a sua casa possui coleta de lixo."),
                otherwise: (schema) => schema.notRequired()

            }),

        moradorDeRua:
            yup.boolean(),


    });

    // _8 Pegando dados da sessão para escrever nos campos =====================================================
    // useEffect(() => {
    //     const valorDaSessionStorage = sessionStorage.getItem('Complemento');

    //     if (sessionStorage.getItem('Complemento') == null) {
    //         valorDaSessionStorage == '';
    //     }
    //   }, []);


    const { handleSubmit, getValues, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),

        defaultValues: {
            "cep": !!sessionStorage.getItem("cep") ? sessionStorage.getItem("cep") : sessionStorage.getItem("CEP"),
            "cidade": !!sessionStorage.getItem("cidade") ? sessionStorage.getItem("cidade") : "",
            "estado": !!sessionStorage.getItem("estado") ? sessionStorage.getItem("estado") : "",
            "bairro": !!sessionStorage.getItem("bairro") ? sessionStorage.getItem("bairro") : sessionStorage.getItem("Bairro"),
            "rua": !!sessionStorage.getItem("rua") ? sessionStorage.getItem("rua") : sessionStorage.getItem("Endereco"),
            "numero": !!sessionStorage.getItem("numero") ? sessionStorage.getItem("numero") : sessionStorage.getItem("Numero"),
            "tempoResidindoNaMoradiaAtual": !!sessionStorage.getItem("tempoResidindoNaMoradiaAtual") ? sessionStorage.getItem("tempoResidindoNaMoradiaAtual") : 0,
            "condicaoDaMoradia": !!sessionStorage.getItem("condicaoDaMoradia") ? sessionStorage.getItem("condicaoDaMoradia") : 1,
            "tipoResidencia": !!sessionStorage.getItem("tipoResidencia") ? sessionStorage.getItem("tipoResidencia") : 1,
            "complemento": !!sessionStorage.getItem("complemento") ? sessionStorage.getItem("complemento") : "",
            "referencia": !!sessionStorage.getItem("referencia") ? sessionStorage.getItem("referencia") : "",
            "possuiBanheiro": !!sessionStorage.getItem("possuiBanheiro") ? sessionStorage.getItem("possuiBanheiro") : true,
            "moradorDeRua": !!sessionStorage.getItem("moradorDeRua") ? sessionStorage.getItem("moradorDeRua") : false,
            "quantidadeDeBanheiros": !!sessionStorage.getItem("quantidadeDeBanheiros") ? sessionStorage.getItem("quantidadeDeBanheiros") : 0,
            "quantidadeDeComodos": !!sessionStorage.getItem("quantidadeDeComodos") ? sessionStorage.getItem("quantidadeDeComodos") : 1,
            "possuiEncanamento": !!sessionStorage.getItem("possuiEncanamento") ? sessionStorage.getItem("possuiEncanamento") : true,
            "possuiEletricidade": !!sessionStorage.getItem("possuiEletricidade") ? sessionStorage.getItem("possuiEletricidade") : true,
            "possuiEsgoto": !!sessionStorage.getItem("possuiEsgoto") ? sessionStorage.getItem("possuiEsgoto") : true,
            "possuiColetaDeLixo": !!sessionStorage.getItem("possuiColetaDeLixo") ? sessionStorage.getItem("possuiColetaDeLixo") : true,
            "possuiAsfalto": !!sessionStorage.getItem("possuiAsfalto") ? sessionStorage.getItem("possuiAsfalto") : true,
            "tempoTrabalho": !!sessionStorage.getItem("tempoTrabalho") ? sessionStorage.getItem("tempoTrabalho") : 0,
        }
    });

    // _9 Envia os dados para a API e salva os valores na sessão =============================================
    function voltar(e) {
        // e.preventDefault();

        if (
            window.location.href == 'https://habitacao.piracicaba.sp.gov.br/#/cadastroMoradia' ||
            window.location.href === 'https://habita-teste.pmp.sp.gov.br/#/cadastroMoradia' ||
            window.location.href == 'https://localhost:88/#/cadastroMoradia' ||
            window.location.href === 'http://localhost:3000/#/cadastroMoradia'
        ) {
            getDados(endpoint)
                .then((dados) => {
                    sessionStorage.setItem("celular", dados.celular);
                    sessionStorage.setItem("celularDeRecado", dados.celularDeRecado);
                    sessionStorage.setItem("contatoCelularRecado", dados.contatoCelularRecado);
                    sessionStorage.setItem("emailMutuario", dados.emailMutuario);
                    sessionStorage.setItem("emailCadastro", dados.emailCadastro);
                    sessionStorage.setItem("nome", dados.nome);
                    sessionStorage.setItem("identidadeGenero", dados.identidadeGenero);
                    sessionStorage.setItem("sexoNascimento", dados.sexoNascimento);
                    sessionStorage.setItem("nomeSocial", dados.nomeSocial);
                    sessionStorage.setItem("racaCor", dados.racaCor);
                    sessionStorage.setItem("possuiDeficiencia", dados.possuiDeficiencia);
                    sessionStorage.setItem("tipoDeDeficiencia", dados.tipoDeDeficiencia);
                    sessionStorage.setItem("possuiDoencaCronica", dados.possuiDoencaCronica);
                    sessionStorage.setItem("cadastradoGovernoFederal", dados.cadastradoGovernoFederal);
                    sessionStorage.setItem("doencaCronicaDescricao", dados.doencaCronicaDescricao);
                    sessionStorage.setItem("funcionarioPublico", dados.funcionarioPublico);
                    sessionStorage.setItem("instituicaoPublica", dados.instituicaoPublica);
                    sessionStorage.setItem("votaEmPiracicaba", dados.votaEmPiracicaba);
                    sessionStorage.setItem("votaEmPiracicabaDesde", dados.votaEmPiracicabaDesde);
                    sessionStorage.setItem("cidadeDeVoto", dados.cidadeDeVoto);
                    sessionStorage.setItem("estadoDeVoto", dados.estadoDeVoto);
                    sessionStorage.setItem("escolaridade", dados.escolaridade);
                    sessionStorage.setItem("estadoCivil", dados.estadoCivil);
                    sessionStorage.setItem("nacionalidade", dados.nacionalidade);
                    sessionStorage.setItem("naturalidadeEstado", dados.naturalidadeEstado);
                    sessionStorage.setItem("naturalidadeCidade", dados.naturalidadeCidade);
                    sessionStorage.setItem("rg", dados.rg);
                    sessionStorage.setItem("orgaoEmissor", dados.orgaoEmissor);
                    sessionStorage.setItem("dataDeNascimento", dados.dataDeNascimento);
                    sessionStorage.setItem("moraEmPiracicabaDesde", dados.moraEmPiracicabaDesde);

                    setDados(dados);


                })
                .catch(setErro)
                .finally(() => {
                    setCarregando(false)
                    navigate('/cadastroPessoal');
                });
        } else {
            getDadosById("/users/data", id)
                .then((dados) => {
                    sessionStorage.setItem("celular", dados.celular);
                    sessionStorage.setItem("celularDeRecado", dados.celularDeRecado);
                    sessionStorage.setItem("contatoCelularRecado", dados.contatoCelularRecado);
                    sessionStorage.setItem("email", dados.email);
                    sessionStorage.setItem("nome", dados.nome);
                    sessionStorage.setItem("userName", dados.nome);
                    sessionStorage.setItem("identidadeGenero", dados.identidadeGenero);
                    sessionStorage.setItem("sexoNascimento", dados.sexoNascimento);
                    sessionStorage.setItem("nomeSocial", dados.nomeSocial);
                    sessionStorage.setItem("racaCor", dados.racaCor);
                    sessionStorage.setItem("possuiDeficiencia", dados.possuiDeficiencia);
                    sessionStorage.setItem("tipoDeDeficiencia", dados.tipoDeDeficiencia);
                    sessionStorage.setItem("possuiDoencaCronica", dados.possuiDoencaCronica);
                    sessionStorage.setItem("cadastradoGovernoFederal", dados.cadastradoGovernoFederal);
                    sessionStorage.setItem("doencaCronicaDescricao", dados.doencaCronicaDescricao);
                    sessionStorage.setItem("funcionarioPublico", dados.funcionarioPublico);
                    sessionStorage.setItem("instituicaoPublica", dados.instituicaoPublica);
                    sessionStorage.setItem("votaEmPiracicaba", dados.votaEmPiracicaba);
                    sessionStorage.setItem("votaEmPiracicabaDesde", dados.votaEmPiracicabaDesde);
                    sessionStorage.setItem("cidadeDeVoto", dados.cidadeDeVoto);
                    sessionStorage.setItem("estadoDeVoto", dados.estadoDeVoto);
                    sessionStorage.setItem("escolaridade", dados.escolaridade);
                    sessionStorage.setItem("estadoCivil", dados.estadoCivil);
                    sessionStorage.setItem("nacionalidade", dados.nacionalidade);
                    sessionStorage.setItem("naturalidadeEstado", dados.naturalidadeEstado);
                    sessionStorage.setItem("naturalidadeCidade", dados.naturalidadeCidade);
                    sessionStorage.setItem("rg", dados.rg);
                    sessionStorage.setItem("orgaoEmissor", dados.orgaoEmissor);
                    sessionStorage.setItem("dataDeNascimento", dados.dataDeNascimento);
                    sessionStorage.setItem("moraEmPiracicabaDesde", dados.moraEmPiracicabaDesde);

                    setDados(dados);
                    navigate("../CadastroPessoal/edit/" + id);
                })
                .catch((err) => {
                    console.log(err)
                    if (err.response.status === 403) {
                        sessionStorage.removeItem("token");
                        sessionStorage.clear()
                        navigate('/');
                    } else {
                        setErro(err);
                    }
                })
                .finally(() => {
                    setCarregando(false)
                    
                });
        }
    }


    async function onSubmit(data) {

        console.log(register);

        setCarregando(true);

        // valor 
        console.log(CEP); // aqui vc tem os valores de CEP< vc pode acessar ele de qualquer lugar do código
        console.log(data); // data recebida do useForm

        data.possuiBanheiro = banheiro;
        data.condicaoDaMoradia = condicaoMoradia

        data = { ...data, ...CEP };

        if (!data.cep) {
            let element = document.getElementById("cep");
            data.cep = element.value;
            data.bairro = bairro;
            data.estado = estado;
            data.cidade = cidade;
            data.rua = rua;
        }

        if (
            window.location.href == 'https://habitacao.piracicaba.sp.gov.br/#/cadastroMoradia' ||
            window.location.href == 'https://habita-teste.pmp.sp.gov.br/#/cadastroMoradia' ||
            window.location.href === 'https://localhost:88/#/cadastroMoradia' ||
            window.location.href === 'http://localhost:3000/#/cadastroMoradia'
        ) {
            api.post('/users/addresses', data)
                .then(function (response) {
                    navigate('/cadastroDependentes');
                })
                .catch(function (error) {
                    console.log("Erro ao buscar dados da tabela.");
                })
                .finally((response) => {
                    setCarregando(false);
                });
        } else {
            api.post('/users/addresses/' + id, data)
                .then(function (response) {
                    navigate("/cadastroDependentes/edit/" + id);
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        sessionStorage.removeItem("token");
                        sessionStorage.clear()
                        navigate('/');
                    } else {
                        setErro(err);
                    }
                })
                .finally((response) => {
                    setCarregando(false);
                });
        }
    }

    var dataAtual = new Date();
    var dia = String(dataAtual.getDate()).padStart(2, '0');
    var mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    var ano = dataAtual.getFullYear();
    dataAtual = ano + '-' + mes + '-' + dia;

    const [dataUser, setDataUser] = useState();
    const [diffInAn, setDiffInAn] = useState();
    const [diffInMs, setDiffInMs] = useState();

    const [qtdBanheiros, setQtdBanheiros] = useState();
    const [qtdComodos, setQtdComodos] = useState();



    const handleChangeDataUser = (event) => {
        setDataUser(event.target.value);
    }

    const handleChangeQtdBanheiros = (event) => {
        setQtdBanheiros(event.target.value);
    }

    const handleChangeQtdComodos = (event) => {
        setQtdComodos(event.target.value);
    }


    useEffect(() => {

        const dias = (new Date(dataAtual) - new Date(dataUser)) / (1000 * 60 * 60 * 24);
        setDiffInAn(Math.trunc(dias / 365));
        setDiffInMs(Math.trunc((dias % 365) / 30));

    })

    if (isNaN(diffInMs) && isNaN(diffInAn)) {
        setDiffInAn(0);
        setDiffInMs(0);
    }

    const [openMoradorDeRua, setOpenMoradorDeRua] = useState('');
    const [moradorDeRua, setMoradorDeRua] = useState((sessionStorage.getItem("moradorDeRua") == "true") ? true : false);

    const handleChangeMoradorDeRua = (event) => {
        setMoradorDeRua(event.target.value);
        sessionStorage.setItem("moradorDeRua", event.target.value);
    }

    const handleCloseMoradorDeRua = () => {
        setOpenMoradorDeRua(false);
    }

    const handleOpenMoradorDeRua = () => {
        setOpenMoradorDeRua(true);
    }

    const [openBanheiro, setOpenBanheiro] = useState('');
    const [banheiro, setBanheiro] = useState(!!sessionStorage.getItem("possuiBanheiro") ? sessionStorage.getItem("possuiBanheiro") : true);

    const handleChangeBanheiro = (event) => {
        setBanheiro(event.target.value);
    }
    const handleCloseBanheiro = () => {
        setOpenBanheiro(false);
    }

    const handleOpenBanheiro = () => {

        setOpenBanheiro(true);
    }

    const [aguaEncanada, setAguaEncanada] = useState(true);
    const [openAguaEncanada, setOpenAguaEncanada] = useState(false);

    const handleChangeAguaEncanada = (event) => {
        setAguaEncanada(event.target.value);
    }

    const handleCloseAguaEncanada = () => {
        setOpenAguaEncanada(false);
    }

    const handleOpenAguaEncanada = () => {
        setOpenAguaEncanada(true);
    }

    if (banheiro === "false") {
        setBanheiro(false);
    }
    if (banheiro === "true") {
        setBanheiro(true);
    }

    const [energiaEletrica, setEnergiaEletrica] = useState(true);
    const [openEnergiaEletrica, setOpenEnergiaEletrica] = useState(false);

    const handleChangeEnergiaEletrica = (event) => {
        setEnergiaEletrica(event.target.value);
    }

    const handleCloseEnergiaEletrica = () => {
        setOpenEnergiaEletrica(false);
    }

    const handleOpenEnergiaEletrica = () => {
        setOpenEnergiaEletrica(true);
    }


    const [esgoto, setEsgoto] = useState(true);
    const [openEsgoto, setOpenEsgoto] = useState(false);

    const handleChangeEsgoto = (event) => {
        setEsgoto(event.target.value);
    }

    const handleCloseEsgoto = () => {
        setOpenEsgoto(false);
    }

    const handleOpenEsgoto = () => {
        setOpenEsgoto(true);
    }


    const [coletaLixo, setColetaLixo] = useState(true);
    const [openColetaLixo, setOpenColetaLixo] = useState(false);

    const handleChangeColetaLixo = (event) => {
        setColetaLixo(event.target.value);
    }

    const handleCloseColetaLixo = () => {
        setOpenColetaLixo(false);
    }

    const handleOpenColetaLixo = () => {
        setOpenColetaLixo(true);
    }

    const [asfalto, setAsfalto] = useState(true);
    const [openAsfalto, setOpenAsfalto] = useState(false);

    const handleChangeAsfalto = (event) => {
        setAsfalto(event.target.value);
        console.log(event.target.value);
    }

    const handleCloseAsfalto = () => {
        setOpenAsfalto(false);
    }

    const handleOpenAsfalto = () => {
        setOpenAsfalto(true);
    }


    const [tempoTrabalho, setTempoTrabalho] = useState(0);
    const [openTempoTrabalho, setOpenTempoTrabalho] = useState();

    const handleChangeTempoTrabalho = (event) => {
        setTempoTrabalho(event.target.value);
        console.log(event.target.value);
    }

    const handleCloseTempoTrabalho = () => {
        setOpenTempoTrabalho(false);
    }

    const handleOpenTempoTrabalho = () => {
        setOpenTempoTrabalho(true);
    }



    function percorrerErrors(obj) {
        const errorElements = [];

        for (const key in obj) {
            if (Array.isArray(obj[key])) {
                for (const elemento of obj[key]) {
                    errorElements.push(percorrerErrors(elemento));
                }
            } else if (typeof obj[key] === 'object') {
                errorElements.push(percorrerErrors(obj[key]));
            } else {
                if (key === 'message') {
                    errorElements.push(
                        <Alert severity="error">
                            {obj[key]}
                        </Alert>
                    );
                }
            }
        }

        return errorElements;
    }

    const [bairro, setBairro] = useState(sessionStorage.getItem("bairro") ? sessionStorage.getItem("bairro") : sessionStorage.getItem("Bairro"));
    const [cidade, setCidade] = useState(sessionStorage.getItem("cidade") ? sessionStorage.getItem("cidade") : "");
    const [estado, setEstado] = useState(sessionStorage.getItem("estado") ? sessionStorage.getItem("estado") : "");
    const [rua, setRua] = useState(sessionStorage.getItem("rua") ? sessionStorage.getItem("rua") : sessionStorage.getItem("Logradouro"));

    return (

        <div className="BackgroundImg">

            <Header/>
            <div className="mx-auto my-4 rounded-2xl bg-white shadow-xl md:w-2/3 pb-6 p-1">
                <div className="container horizontal mt-8">

                    {/* _10 Stepper =============================================================================================*/}
                    <Stepper steps={steps} currentStep={currentStep} />
                    <div className="my-0 p-10">
                        <StepperContext.Provider value={{
                            stepData,
                            setStepData,
                            finalData,
                            setFinalData
                        }}>
                        </StepperContext.Provider>
                        <div className="flex flex-col">
                            {/* _10 Campos de Moradia =====================================================================================*/}
                            <form onSubmitCapture={handleSubmit(onSubmit)} >

                                {/* _11 Campos de Comunicaçãp ================================================================================== */}
                                <Divider light />
                                <br />


                                <div className='flex justify-between items-center my-8'>
                                    <Grid container spacing={2} sx={{ mb: 2 }}>
                                        <Grid item xs={12} sm={6}>
                                            <h1 style={{
                                                fontFamily: 'Exo-SemiBold',
                                                fontSize: '32px'
                                            }}>
                                                Endereço Atual
                                            </h1>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <p
                                                style={{
                                                    fontFamily: 'Exo-SemiBold',
                                                    fontSize: '22px',
                                                    textAlign: 'end'
                                                }}
                                            >
                                                {sessionStorage.getItem("cpf")}
                                            </p>
                                            <p
                                                style={{
                                                    fontFamily: 'Exo-SemiBold',
                                                    fontSize: '22px',
                                                    textAlign: 'end'
                                                }}
                                            >
                                                {sessionStorage.getItem("userName")}
                                            </p>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Grid container spacing={3} sx={{ mb: 2 }}>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="moradorDeRua"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel id="demo-multiple-checkbox-label">Você é uma pessoa em situação de rua? *</InputLabel>
                                                        <Select
                                                            open={openMoradorDeRua}
                                                            onClose={handleCloseMoradorDeRua}
                                                            onOpen={handleOpenMoradorDeRua}
                                                            defaultValue={moradorDeRua}
                                                            label="Você é uma pessoa em situação de rua?"
                                                            name="moradorDeRua"
                                                            {...field}
                                                            onChange={(ev) => {
                                                                try {
                                                                    field.onChange(ev);
                                                                    handleChangeMoradorDeRua(ev);
                                                                } catch (error) {
                                                                    console.error(error);
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem value={false}>Não</MenuItem>
                                                            <MenuItem value={true}>Sim</MenuItem>
                                                        </Select>
                                                    </>
                                                )}
                                            />
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="cep"
                                                render={({ field }) => (
                                                    <>
                                                        <IMaskInput
                                                            name='cep'
                                                            id="cep"
                                                            mask="00000-000"
                                                            className={styles.InputStyle}
                                                            defaultValue={sessionStorage.getItem("cep") || sessionStorage.getItem("CEP")}
                                                            disabled={moradorDeRua ? true : false}
                                                            placeholder='CEP'
                                                            onBlur={(ev) => {
                                                                try {
                                                                    field.onBlur(ev);
                                                                    checkCEP(ev);
                                                                } catch (error) {
                                                                    console.error(error);
                                                                }
                                                            }}
                                                            value={field.value} // Importante: forneça o valor diretamente do field
                                                            onAccept={(value) => field.onChange(value)} // Atualize o valor no onChange do IMaskInput
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
                                    <FormControl style={{ width: '100%' }}>
                                        <TextField
                                            name='bairro'
                                            placeholder='Bairro'
                                            {...register("bairro")}
                                            defaultValue={sessionStorage.getItem("bairro") || sessionStorage.getItem("Bairro")}
                                            disabled={moradorDeRua ? true : false}
                                            onChange={(e) => { setBairro(e.target.value) }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={9} sm={9}>
                                        <FormControl style={{ width: '100%' }}>
                                            <TextField
                                                name='rua'
                                                placeholder='Rua'
                                                sx={{ width: '100%' }}
                                                {...register("rua")}
                                                defaultValue={sessionStorage.getItem("rua") ? sessionStorage.getItem("rua") : sessionStorage.getItem("Endereco")}
                                                disabled={moradorDeRua ? true : false}
                                                onChange={(e) => { setRua(e.target.value) }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="numero"
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            value={sessionStorage.getItem("numero") || sessionStorage.getItem("Numero")}
                                                            disabled={moradorDeRua ? true : false}
                                                            name='numero'
                                                            placeholder='Número'
                                                            sx={{ width: '100%' }}
                                                            {...field}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl style={{ width: '100%' }}>
                                            <TextField
                                                {...register("estado")}
                                                defaultValue={sessionStorage.getItem("estado")}
                                                name='estado'
                                                placeholder='Estado'
                                                onChange={(e) => { setEstado(e.target.value) }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl style={{ width: '100%' }}>
                                            <TextField
                                                {...register("cidade")}
                                                defaultValue={sessionStorage.getItem("cidade")}
                                                name='cidade'
                                                placeholder='Cidade'
                                                onChange={(e) => { setCidade(e.target.value) }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="complemento"
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...register("complemento")}
                                                            disabled={moradorDeRua ? true : false}
                                                            defaultValue={sessionStorage.getItem("complemento")}
                                                            name='complemento'
                                                            placeholder='Complemento'
                                                            {...field}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="referencia"
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...register("referencia")}
                                                            defaultValue={sessionStorage.getItem("referencia")}
                                                            name='referencia'
                                                            disabled={moradorDeRua ? true : false}
                                                            placeholder='Referência'
                                                            {...field}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="tempoResidindoNaMoradiaAtual"
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            fullWidth
                                                            type="text"
                                                            onChange={handleChangeDataUser}
                                                            name="tempoResidindoNaMoradiaAtual"
                                                            label="Há quantos anos mora na sua residência atual? *"
                                                            disabled={moradorDeRua ? true : false}
                                                            defaultValue={sessionStorage.getItem("tempoResidindoNaMoradiaAtual")}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onInput={handleInputChange}
                                                            {...field}
                                                        />
                                                    </>
                                                )}
                                            />
                                            {/* {errors.tempoResidindoNaMoradiaAtual && <Alert severity="error">{errors.tempoResidindoNaMoradiaAtual?.message}</Alert>} */}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            control={control}
                                            name="condicaoDaMoradia"
                                            render={({ field }) => (
                                                <>
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <InputLabel id="demo-multiple-checkbox-label">Condição de moradia *</InputLabel>
                                                        <Select
                                                            required
                                                            name="condicaoDaMoradia"
                                                            open={openCondicaoMoradia}
                                                            onClose={handleCloseCondicaoMoradia}
                                                            onOpen={handleOpenCondicaoMoradia}
                                                            label="Condição de Moradia *"
                                                            disabled={moradorDeRua ? true : false}
                                                            defaultValue={!!sessionStorage.getItem("condicaoDaMoradia") ? sessionStorage.getItem("condicaoDaMoradia") : 1}
                                                            onChange={handleChangeCondicaoMoradia}
                                                        >
                                                            <MenuItem disabled value={0}>Condição da sua moradia</MenuItem>
                                                            <MenuItem value={1}>Própria</MenuItem>
                                                            <MenuItem value={2}>Alugada</MenuItem>
                                                            <MenuItem value={3}>Cedida</MenuItem>
                                                            <MenuItem value={4}>Ocupação</MenuItem>
                                                            <MenuItem value={5}>Adolescente em acolhimento institucional com 17 anos</MenuItem>
                                                        </Select>

                                                        {/* {errors.condicaoDaMoradia && <Alert severity="error">{errors.condicaoDaMoradia?.message}</Alert>} */}
                                                    </FormControl>
                                                </>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="tipoResidencia"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel id="demo-multiple-checkbox-label">Tipo de moradia *</InputLabel>
                                                        <Select
                                                            open={openTipo}
                                                            onClose={handleCloseTipo}
                                                            onOpen={handleOpenTipo}
                                                            label="Área da moradia *"
                                                            onChange={handleChangeTipo}
                                                            name="tipoResidencia"
                                                            disabled={moradorDeRua || campoDesabilitado ? true : false}
                                                            defaultValue={sessionStorage.getItem("tipoResidencia")}
                                                            {...field}
                                                        >
                                                            <MenuItem disabled value={0}>Selecione...</MenuItem>
                                                            <MenuItem value={1}>Tijolo/ Blocos</MenuItem>
                                                            <MenuItem value={2}>Madeira</MenuItem>
                                                        </Select>
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <br />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel id="demo-multiple-checkbox-label">Sua casa possui banheiro? *</InputLabel>
                                            <Select
                                                open={openBanheiro}
                                                onClose={handleCloseBanheiro}
                                                onOpen={handleOpenBanheiro}
                                                defaultValue={!!sessionStorage.getItem("possuiBanheiro") ? sessionStorage.getItem("possuiBanheiro") : true}
                                                label="Sua casa possui banheiro? *"
                                                disabled={moradorDeRua || campoDesabilitado ? true : false}
                                                name="possuiBanheiro"
                                                onChange={handleChangeBanheiro}

                                            >
                                                <MenuItem value={false}>Não</MenuItem>
                                                <MenuItem value={true}>Sim</MenuItem>
                                            </Select>
                                            {/* {errors.possuiBanheiro && <Alert severity="error">{errors.possuiBanheiro?.message}</Alert>} */}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="quantidadeDeBanheiros"
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            type="number"
                                                            onChange={handleChangeQtdBanheiros}
                                                            name="quantidadeDeBanheiros"
                                                            label="Quantos banheiros? "
                                                            disabled={(banheiro ? false : true) || (campoDesabilitado || moradorDeRua ? true : false)}
                                                            defaultValue={sessionStorage.getItem("quantidadeDeBanheiros")}
                                                            inputProps={{
                                                                min: 1,
                                                            }}
                                                            onInput={handleInputChangeValueMin}
                                                            {...field}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <br />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="quantidadeDeComodos"
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            fullWidth
                                                            type="text"
                                                            onChange={handleChangeQtdComodos}
                                                            name="quantidadeDeComodos"
                                                            disabled={moradorDeRua || campoDesabilitado ? true : false}
                                                            label="Quantos cômodos tem a sua casa? * "
                                                            defaultValue={sessionStorage.getItem("quantidadeDeComodos")}
                                                            inputProps={{
                                                                min: 1,
                                                            }}
                                                            onInput={handleInputChangeValueMin}
                                                            {...field}
                                                        />
                                                    </>
                                                )}
                                            />
                                            {/* {errors.quantidadeDeComodos && <Alert severity="error">{errors.quantidadeDeComodos?.message}</Alert>} */}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="possuiEncanamento"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel id="demo-multiple-checkbox-label">Sua casa possui água encanada? *</InputLabel>
                                                        <Select
                                                            required
                                                            open={openAguaEncanada}
                                                            onClose={handleCloseAguaEncanada}
                                                            onOpen={handleOpenAguaEncanada}
                                                            disabled={moradorDeRua || campoDesabilitado ? true : false}
                                                            defaultValue={sessionStorage.getItem("possuiEncanamento")}
                                                            label="Sua casa possui água encanada?"
                                                            name="possuiEncanamento"
                                                            onChange={handleChangeAguaEncanada}
                                                            {...field}
                                                        >
                                                            <MenuItem value={false}>Não</MenuItem>
                                                            <MenuItem value={true}>Sim</MenuItem>
                                                        </Select>
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <br />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="possuiEletricidade"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel id="demo-multiple-checkbox-label">Sua casa possui energia elétrica? *</InputLabel>
                                                        <Select
                                                            required
                                                            open={openEnergiaEletrica}
                                                            onClose={handleCloseEnergiaEletrica}
                                                            onOpen={handleOpenEnergiaEletrica}
                                                            disabled={moradorDeRua || campoDesabilitado ? true : false}
                                                            defaultValue={sessionStorage.getItem("possuiEletricidade")}
                                                            label="Sua casa possui energia elétrica?"
                                                            name="possuiEletricidade"
                                                            onChange={handleChangeEnergiaEletrica}
                                                            {...field}
                                                        >
                                                            <MenuItem value={false}>Não</MenuItem>
                                                            <MenuItem value={true}>Sim</MenuItem>
                                                        </Select>
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="possuiEsgoto"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel id="demo-multiple-checkbox-label">Sua casa possui rede de esgoto? *</InputLabel>
                                                        <Select
                                                            required
                                                            open={openEsgoto}
                                                            onClose={handleCloseEsgoto}
                                                            onOpen={handleOpenEsgoto}
                                                            disabled={moradorDeRua || campoDesabilitado ? true : false}
                                                            defaultValue={sessionStorage.getItem("possuiEsgoto")}
                                                            label="Sua casa possui rede de esgoto?"
                                                            name="possuiEsgoto"
                                                            onChange={handleChangeEsgoto}
                                                            {...field}
                                                        >
                                                            <MenuItem value={false}>Não</MenuItem>
                                                            <MenuItem value={true}>Sim</MenuItem>
                                                        </Select>
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <br />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="possuiColetaDeLixo"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel id="demo-multiple-checkbox-label">Sua casa possui coleta de lixo? *</InputLabel>
                                                        <Select
                                                            required
                                                            open={openColetaLixo}
                                                            onClose={handleCloseColetaLixo}
                                                            onOpen={handleOpenColetaLixo}
                                                            disabled={moradorDeRua || campoDesabilitado ? true : false}
                                                            value={sessionStorage.getItem("possuiColetaDeLixo")}
                                                            label="Sua casa possui coleta de lixo?"
                                                            name="possuiColetaDeLixo"
                                                            onChange={handleChangeColetaLixo}
                                                            {...field}
                                                        >
                                                            <MenuItem value={false}>Não</MenuItem>
                                                            <MenuItem value={true}>Sim</MenuItem>
                                                        </Select>
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="possuiAsfalto"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel id="demo-multiple-checkbox-label">Sua rua possui asfalto? *</InputLabel>
                                                        <Select
                                                            required
                                                            open={openAsfalto}
                                                            onClose={handleCloseAsfalto}
                                                            onOpen={handleOpenAsfalto}
                                                            disabled={moradorDeRua || campoDesabilitado ? true : false}
                                                            defaultValue={sessionStorage.getItem("possuiAsfalto")}
                                                            label="Sua rua possui asfalto?"
                                                            name="possuiAsfalto"
                                                            onChange={handleChangeAsfalto}
                                                            {...field}
                                                        >
                                                            <MenuItem value={false}>Não</MenuItem>
                                                            <MenuItem value={true}>Sim</MenuItem>
                                                        </Select>
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="tempoTrabalho"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel id="demo-multiple-checkbox-label">Tempo gasto no percurso para o trabalho *</InputLabel>
                                                        <Select
                                                            required
                                                            open={openTempoTrabalho}
                                                            onClose={handleCloseTempoTrabalho}
                                                            onOpen={handleOpenTempoTrabalho}
                                                            disabled={moradorDeRua || campoDesabilitado ? true : false}
                                                            defaultValue={sessionStorage.getItem("tempoTrabalho")}
                                                            label="Tempo gasto no percurso para o trabalho "
                                                            name="tempoTrabalho"
                                                            onChange={handleChangeTempoTrabalho}
                                                            {...field}
                                                        >
                                                            <MenuItem value={0}>Até 15 minutos</MenuItem>
                                                            <MenuItem value={1}>15 a 30 minutos</MenuItem>
                                                            <MenuItem value={2}>30 minutos a 1 hora</MenuItem>
                                                            <MenuItem value={3}>Mais que 1 hora</MenuItem>
                                                        </Select>
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="meioTransporte"
                                                render={({ field }) => (
                                                    <>
                                                        <InputLabel id="demo-multiple-checkbox-label">Sua rua possui asfalto? *</InputLabel>
                                                        <Select
                                                            required
                                                            open={openAsfalto}
                                                            onClose={handleCloseAsfalto}
                                                            onOpen={handleOpenAsfalto}
                                                            disabled={moradorDeRua || campoDesabilitado ? true : false}
                                                            defaultValue={sessionStorage.getItem("possuiAsfalto")}
                                                            label="Sua rua possui asfalto?"
                                                            name="possuiAsfalto"
                                                            onChange={handleChangeAsfalto}
                                                            {...field}
                                                        >
                                                            <MenuItem value={false}>Não</MenuItem>
                                                            <MenuItem value={true}>Sim</MenuItem>
                                                        </Select>
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid> */}
                                </Grid>

                                <br />
                                <br />

                                {/* _12 Retorno de erros do Yup ================================================================================*/}
                                <div className='mb-4'>
                                    {percorrerErrors(errors)}
                                </div>

                                <div className='flex justify-between'>
                                    <LoadingButton
                                        style={{
                                            padding: '16px',
                                            borderRadius: '12px'
                                        }}
                                        variant='outlined'
                                        size="large"
                                        // disabled
                                        onClick={voltar}
                                    >
                                        Voltar
                                    </LoadingButton>
                                    <Button
                                        style={{
                                            backgroundColor: '#3F51b5',
                                            padding: '16px',
                                            borderRadius: '12px'
                                        }}
                                        type='submit'
                                        variant='contained'
                                        size="large"
                                    >
                                        Próximo
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </div >

    )
}