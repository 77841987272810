import { InputLabel, OutlinedInput, InputAdornment, Box, Divider, FormControl, Grid } from "@mui/material"
import { Space } from "antd"
import { useFieldArray, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import ControlledTextField from "../ControlledTextField";
import ControlledInputMask from "../ControlledInputMask";
import { format } from 'date-fns'
import ControlledSelect from "../ControlledSelect";
import ComponenteFamiliarDeficiencia from "../ComponenteFamiliarDeficiencia";
import ComponenteFamiliarDoencaCronica from "../ComponenteFamiliarDoencaCronica";
import styles from '../Stepps/InputStyle.module.css'

export const CamposDependentes = ({ defaultValues }) => {
    const { watch, register, control, setValue, formState: { errors } } = useFormContext();
    const { fields, append, remove } = useFieldArray({ name: 'dependentes', control });
    let residentesNumber = parseInt(watch('quantidadeDeResidentes')) -1;


    const selectTipoDeRendaOptions = [
        { value: 1, description: "Autônomo" },
        { value: 2, description: "Bicos" },
        { value: 3, description: "Carteira Registrada" },
        { value: 4, description: "Estagiário" },
        { value: 5, description: "LOAS / BPC" },
        { value: 6, description: "Bolsa Família" },
        { value: 7, description: "Pensão" },
        { value: 8, description: "Aposentadoria" },
        { value: 9, description: "Outros Benefícios" },
        { value: 10, description: "Sem Renda" },
    ]


    const selectSimNaoOptions = [
        { value: true, description: "Sim" },
        { value: false, description: "Não" },
    ]

    const selectGeneroOptions = [
        { value: false, description: "Selecione..." },
        { value: 1, description: "Masculino" },
        { value: 2, description: "Feminino" },
        { value: 3, description: "Não Binário" },
    ]

    const selectNivelParentescoOptions = [
        { value: false, description: "Selecione..." },
        { value: 1, description: "Esposo(a)" },
        { value: 2, description: "Companheiro(a)" },
        { value: 3, description: "Filho(a)" },
        { value: 4, description: "Enteado(a)" },
        { value: 5, description: "Irmão(ã)" },
        { value: 6, description: "Pai" },
        { value: 7, description: "Mãe" },
        { value: 8, description: "Tio(a)" },
        { value: 9, description: "Primo(a)" },
        { value: 10, description: "Avô(ó)" },
        { value: 11, description: "Sobrinho(a)" },
        { value: 12, description: "Neto(a)" },
        { value: 13, description: "Bisneto(a)" },
        { value: 14, description: "Bisavô(ó)" },
        { value: 15, description: "Nora" },
        { value: 16, description: "Genro" },
    ]

    //Não alterar os valores pois estão alinhados com o banco de dados
    const selectEscolaridadeOptions = [
        { value: false, description: "Selecione..." },
        { value: 1, description: "Não Alfabetizado" },
        { value: 8, description: "Educação Infantil" },
        { value: 2, description: "Fundamental Incompleto" },
        { value: 3, description: "Fundamental Completo" },
        { value: 4, description: "Médio Incompleto" },
        { value: 5, description: "Médio Completo" },
        { value: 9, description: "Curso Técnico" },
        { value: 6, description: "Superior Incompleto" },
        { value: 7, description: "Superior Completo" },
        
    ]

    var defaultNullObject = {
        "indexFamiliar": 0,
        "quantidadeDeResidentes": 1,
        "nomeCompletoFamiliar": "",
        "cpfFamiliar": "",
        "rgFamiliar": "",
        "nivelParentescoFamiliar": false,
        "dataNascimentoFamiliar": "",
        "tipoDeficienciaFamiliar": 0,
        "descricaoDoencaCronicaFamiliar": "",
        "familiarDeficiente": false,
        "familiarDoencaCronica": false,
        "escolaridade": false,
        "profissao": "",
        "tipoDeRenda": 1,
        "renda": "",
        "genero": false
    }

    useEffect(() => {
        const newVal = parseInt(residentesNumber || 0);
        const oldVal = fields.length;
        if (newVal > oldVal) {
            for (let i = oldVal; i < newVal; i++) {
                var value = defaultNullObject;
                append(value);
            }
        } else {
            for (let i = oldVal; i > newVal; i--) {
                remove(i - 1);
            }
        }

        return () => {
            console.log("fechando residentesNumber");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [residentesNumber]);

    useEffect(() => {
        const newVal = parseInt(residentesNumber || 0);

        for (let index = 0; index < newVal; index++) {
            setValoresDefault(index);
        }

        return () => {
            console.log("fechando defaultValues");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues, residentesNumber])

    const setValoresDefault = (i) => {
        try {
            if (!!defaultValues?.dependentes) {
                const currentValue = defaultValues?.dependentes[i];

                if (!!currentValue) {
                    setValue(`dependentes.${i}.indexFamiliar`, currentValue.indexFamiliar);
                    setValue(`dependentes.${i}.nomeCompletoFamiliar`, currentValue.nomeCompletoFamiliar);
                    setValue(`dependentes.${i}.cpfFamiliar`, currentValue.cpfFamiliar);
                    setValue(`dependentes.${i}.rgFamiliar`, currentValue.rgFamiliar);
                    setValue(`dependentes.${i}.nivelParentescoFamiliar`, currentValue.nivelParentescoFamiliar);
                    setValue(`dependentes.${i}.dataNascimentoFamiliar`, format(new Date(currentValue.dataNascimentoFamiliar), 'yyyy-MM-dd'));
                    setValue(`dependentes.${i}.tipoDeficienciaFamiliar`, currentValue.tipoDeficienciaFamiliar);
                    setValue(`dependentes.${i}.descricaoDoencaCronicaFamiliar`, currentValue.descricaoDoencaCronicaFamiliar);
                    setValue(`dependentes.${i}.familiarDeficiente`, currentValue.familiarDeficiente);
                    setValue(`dependentes.${i}.familiarDoencaCronica`, currentValue.familiarDoencaCronica);
                    setValue(`dependentes.${i}.genero`, currentValue.genero);
                    setValue(`dependentes.${i}.escolaridade`, currentValue.escolaridade);
                    setValue(`dependentes.${i}.profissao`, currentValue.profissao);
                    setValue(`dependentes.${i}.renda`, currentValue.renda);
                    setValue(`dependentes.${i}.tipoDeRenda`, currentValue.tipoDeRenda);
                }
            }
        } catch (error) {
            console.error("erro for", error);
        }
    }

    return (
        <div>
            {residentesNumber >= 1 &&
                <h1 style={{ marginBottom: '16px' }}>Preencha os dados de cada residente abaixo:</h1>
            }

            <ul>
                {
                    fields.map((_, row) => (
                        <Space
                            style={{
                                marginBottom: 8,
                            }}
                            align="baseline"
                            key={row}
                        >
                            <input type="hidden" {...register(`dependentes.${row}.indexFamiliar`)} />

                            <div className='flex justify-around items-center'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <ControlledTextField
                                            control={control}
                                            errors={errors}
                                            name={`dependentes.${row}.nomeCompletoFamiliar`}
                                            originalError={errors?.dependentes?.[row]?.["nomeCompletoFamiliar"]}
                                            label='Nome e Sobrenome'
                                            placeholder="Nome e Sobrenome"
                                            otherPropertiesFormControl={{ sx: { width: "100%" } }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ControlledInputMask
                                            mask="999.999.999-99"
                                            control={control}
                                            errors={errors}
                                            name={`dependentes.${row}.cpfFamiliar`}
                                            originalError={errors?.dependentes?.[row]?.["cpfFamiliar"]}
                                            label='CPF'
                                            placeholder="CPF"
                                            otherPropertiesFormControl={{ sx: { width: "100%", mr: 2 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                    <ControlledInputMask
                                            mask="99.999.999-*"
                                            control={control}
                                            errors={errors}
                                            name={`dependentes.${row}.rgFamiliar`}
                                            originalError={errors?.dependentes?.[row]?.["rgFamiliar"]}
                                            label='RG'
                                            placeholder="RG"
                                            otherPropertiesFormControl={{ sx: { width: "100%" } }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <br />
                            <div className='flex justify-around items-center'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <ControlledSelect
                                            control={control}
                                            errors={errors}
                                            originalError={errors?.dependentes?.[row]?.["genero"]}
                                            firstDisabled={true}
                                            firstSelected={true}
                                            defaultValue={0}
                                            name={`dependentes.${row}.genero`}
                                            label={'Sexo atribuído ao Nascimento'}
                                            selectOptions={selectGeneroOptions}
                                            style={{ mt: '16px' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ControlledSelect
                                            control={control}
                                            errors={errors}
                                            originalError={errors?.dependentes?.[row]?.["nivelParentescoFamiliar"]}
                                            firstDisabled={true}
                                            firstSelected={true}
                                            defaultValue={0}
                                            name={`dependentes.${row}.nivelParentescoFamiliar`}
                                            label={'Nível de parentesco'}
                                            selectOptions={selectNivelParentescoOptions}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ControlledTextField
                                            control={control}
                                            errors={errors}
                                            name={`dependentes.${row}.dataNascimentoFamiliar`}
                                            originalError={errors?.dependentes?.[row]?.["dataNascimentoFamiliar"]}
                                            placeholder="Data Nascimento"
                                            label='Data Nascimento'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sxInput={{
                                                width: '100%'
                                            }}
                                            type='date'
                                            id={"dataNascimeto" + row}
                                            otherPropertiesFormControl={{ sx: { width: "100%", mt: 3 } }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>

                            <Box>
                                <Grid container spacing={2} sx={{ marginY: 1 }}>
                                    <Grid item xs={12} sm={6}>
                                        <ControlledSelect
                                            control={control}
                                            errors={errors}
                                            originalError={errors?.dependentes?.[row]?.["escolaridade"]}
                                            firstDisabled={true}
                                            firstSelected={true}
                                            defaultValue={0}
                                            name={`dependentes.${row}.escolaridade`}
                                            label={'Nível de Escolaridade'}
                                            selectOptions={selectEscolaridadeOptions}
                                            style={{ mt: '16px' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <ControlledTextField
                                            control={control}
                                            errors={errors}
                                            name={`dependentes.${row}.profissao`}
                                            originalError={errors?.dependentes?.[row]?.["profissao"]}
                                            label='Profissão/Ocupação'
                                            placeholder="Profissão/Ocupação"
                                            otherPropertiesFormControl={{ sx: { width: "100%" } }}
                                            style={{ width: "100%" }}
                                            inputStyle={{ width: 'auto', marginTop: '1.5em' }}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>
                            <Box>
                                <Grid container spacing={2} sx={{ marginY: 1 }}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl style={{ width: '100%' }} sx={{ mt: 3 }}>
                                            <InputLabel htmlFor="outlined-adornment-amount">Renda:</InputLabel>
                                            <OutlinedInput
                                                required
                                                style={{ width: '100%' }}
                                                type='number'
                                                inputProps={{
                                                    step: "0.01"
                                                }}
                                                name="wageValue"
                                                // className={styles.InputStyle}
                                                placeholder="Renda"
                                                startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                label="Renda"
                                                {...register(`dependentes.${row}.renda`)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <ControlledSelect
                                            control={control}
                                            errors={errors}
                                            originalError={errors?.dependentes?.[row]?.["tipoDeRenda"]}
                                            firstDisabled={false}
                                            firstSelected={true}
                                            defaultValue={1}
                                            name={`dependentes.${row}.tipoDeRenda`}
                                            label='Tipo de renda'
                                            selectOptions={selectTipoDeRendaOptions}
                                            style={{ mt: '16px' }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className='justify-around items-center' sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                <Grid container spacing={2} sx={{ marginY: 1 }}>
                                    <Grid item xs={12} sm={6}>
                                        <ComponenteFamiliarDeficiencia
                                            control={control}
                                            errors={errors}
                                            selectSimNaoOptions={selectSimNaoOptions}
                                            defaultValues={defaultValues}
                                            row={row}
                                            key={row}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <ComponenteFamiliarDoencaCronica
                                            control={control}
                                            errors={errors}
                                            setValue={setValue}
                                            selectSimNaoOptions={selectSimNaoOptions}
                                            defaultValues={defaultValues}
                                            row={row}
                                            key={row}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <br />
                            <Divider light />
                            <br />
                        </Space>

                    )

                    )
                }
            </ul>
        </div >
    )
}