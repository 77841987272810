import { Alert, FormControl, FormLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import stylesSelect from "../../Components/Stepps/InputStyle.module.css";

export default function ControlledSelect({
  control,
  name,
  label,
  defaultValue,
  errors,
  rest,
  originalError,
  onSelectChange,
  selectOptions = [],
  styleFormControl = { width: "100%" },
  firstSelected = false,
  firstDisabled = false,
  disabled = false,
}) {
  return (
    
    <FormControl style={styleFormControl}>
      <FormLabel>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <select
            label={label}
            className={stylesSelect.selectStyle}
            {...field}
            {...rest}
            onChange={(ev) => {
              try {
                field.onChange(ev);
                onSelectChange(ev);
              } catch (error) {
                console.error(error);
              }
            }}
            disabled={disabled}
          >
            {selectOptions.length > 0
              ? selectOptions.map((element, index) => (
                  <option
                    value={element.value}
                    disabled={index === 0 && firstDisabled}
                    selected={index === 0 && firstSelected}
                  >
                    {element.description}
                  </option>
                ))
              : null}
          </select>
        )}
      />
      {/* {!!errors?.[name] || !!originalError ? (
        <Alert severity="error">
          {!!originalError ? originalError?.message : errors.nome?.message}
        </Alert>
      ) : null} */}
    </FormControl>
    
  );
}
