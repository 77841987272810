// _1 Importações
// _2 Estilos
// _3 Declaração de constantes
// _4 Stepper
// _5 Mensagem de cadastro finalizado


// _1 Importações =========================================================================================
//Importações do React
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import React from 'react';

//   Importações do "Stepper" 
import { StepperContext } from "../../Contexts/StepperContext";
import Stepper from '../../Components/Stepper/Stepper';

import { Grid, Box } from '@mui/material';


// Importações de estilo (.css e imagens)
import BackgroundImg from '../../Components/Background/estiloPages.css';
import CodigoPenal from '../../Components/CódigoPenal/index';
import { Header } from '../../Components/Header';
import pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from 'pdfmake/build/pdfmake';
import Impressao from './impressao';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


export function CadFinalizado() {
    const { id } = useParams();

    // _2 Estilos ============================================================================================
    const style = {
        inputAdd: {
            padding: '8px',
            border: '1px solid #C8C8C8',
            borderRadius: '4px',
            marginRight: '4px'
        },
        containerStepper: {
            height: 'auto',
            margin: 'auto',
            marginTop: '80px'
        }
    }

    // _3 Declaração de constantes ============================================================================
    const [currentStep, setCurrentStep] = useState(6);
    const [stepData, setStepData] = useState('');
    const [protocolo, setProtocolo] = useState()
    const [finalData, setFinalData] = useState([]);
    const navigate = useNavigate()

    const handleLogout = () => {
        if (window.location.href == 'https://habitacao.piracicaba.sp.gov.br/#/cadastroFinalizado'
            || window.location.href == 'https://habita-teste.pmp.sp.gov.br/#/cadastroFinalizado'
            || window.location.href == 'https://localhost:88/#/cadastroFinalizado'
            || window.location.href === 'http://localhost:3000/#/cadastroFinalizado'
        ) {

            sessionStorage.removeItem("token");
            sessionStorage.clear()
            navigate("/");

        } else {
            navigate("/admin");
        }
    }

    const steps = [
        "Dados Pessoais",
        "Endereço Atual",
        "Composição e Renda Familiar",
        "Gasto mensal (Familiar)",
        "Anexos",
        "Finalizado"
    ];

    var dataAtual = new Date();
    var dia = String(dataAtual.getDate()).padStart(2, '0');
    var mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    var ano = dataAtual.getFullYear();
    dataAtual = dia + '/' + mes + '/' + ano;

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`

    const data =
        [
            {
                CPF: sessionStorage.getItem("cpf"),
                nomeCompleto: sessionStorage.getItem("nomeCompleto"),
                protocolo: sessionStorage.getItem("protocolo"),
                data: dataAtual
            }
        ]

    console.log(data);
    return (

        <div className="BackgroundImg">
            <Header />

            <div className="mx-auto my-4 rounded-2xl bg-white shadow-xl md:w-2/3 pb-6 p-1">
                <div className="container horizontal mt-8">
                    {/* _4 Stepper ================================================================================= */}
                    <Stepper steps={steps} currentStep={currentStep} />
                    <div className="my-0 p-10">
                        <StepperContext.Provider value={{
                            stepData,
                            setStepData,
                            finalData,
                            setFinalData
                        }}>
                        </StepperContext.Provider>
                        {/* _5 Mensagem de cadastro finalizado =============================================================== */}
                        <br />
                        <br />
                        <Grid container spacing={2} sx={{ textAlign: 'center', justifyContent: 'center', alignContent: 'flex-start' }}>
                            <br />
                            <Grid item xs={12} sm={12}>
                                Parabéns, você realizou/Atualizou seu cadastro com sucesso!
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                Atualize seu cadastro pelo menos 1 vez no ano
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                Muito obrigado!
                            </Grid>
                            <Grid item xs={0} sm={3}></Grid>
                            <Grid item xs={12} sm={6}>
                                <center>
                                    <div className="border rounded-lg shadow-xl p-4" >
                                        <div>
                                            <div className=" mr-2 text-l font-semibold uppercase text-orange-500">
                                                Número do seu protocolo:
                                            </div>
                                            <div className=" text-l font-semibold uppercase text-blue-800">
                                                {sessionStorage.getItem("protocolo")}
                                            </div>
                                        </div>
                                        <div className="font-semibold text-blue-800 mt-8">
                                            Data de cadastro/atualização: {date}
                                        </div>
                                    </div>
                                </center>
                            </Grid>
                            <Grid item xs={0} sm={3}></Grid>

                            <Grid item xs={12} sm={12}>
                                <CodigoPenal />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <button
                                    className="
                                        mt-5 h-10 px-12 
                                        text-black-900 
                                        bg-orange-500
                                        transition-colors duration-150 
                                        border rounded-lg 
                                        focus:shadow-outline 
                                        hover:bg-indigo-500 hover:text-white-900
                                    "
                                    onClick={() => Impressao(data)}
                                >
                                    {console.log(data)}
                                    Imprimir Protocolo
                                </button>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <button
                                    className="
                                        mt-5 h-10 px-12 
                                        text-indigo-700 
                                        transition-colors duration-150 
                                        border border-gray-300 rounded-lg 
                                        focus:shadow-outline 
                                        hover:bg-indigo-500 hover:text-green-100
                                    "
                                    onClick={handleLogout}
                                >
                                    Fechar
                                </button>
                            </Grid>
                            <br />
                        </Grid>
                    </div>
                </div>
            </div >
        </div>

    )
}