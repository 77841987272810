import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';
import logo1 from "../../Assets/logo-pt1.png";
import logo2 from "../../Assets/logo-pt2.png";
import ModalLogout from './ModalLogout/index';
import { Box, Button } from '@mui/material';
import styleHeader from './styles';
import React from 'react';
import BotaoHomeAdm from './BotaoHomeAdm';
import { parse } from 'date-fns';


export function Header(props) {

    const navigate = useNavigate()

    function decodeToken (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        var json = JSON.parse(jsonPayload);
        return json.PanelAccess;
    }

    const handleLogout = () => {
        sessionStorage.removeItem("token");
        sessionStorage.clear()
        navigate("/");
    }

    const titleHeader = useMediaQuery({
        query: '(min-width: 640px)'
    })

    return (
        <Box sx={styleHeader.header}>
            <div>
                {
                    decodeToken(sessionStorage.getItem("token")) == "Emdhap"  &&
                    window.location.href.slice(-5) != "admin" && <BotaoHomeAdm />
                }
            </div>
            <Box sx={styleHeader.logoContainer}>
                <div>
                    <img
                        style={styleHeader.imgLogo}
                        onClick={handleLogout}
                        src={logo1}
                        height='50'
                        alt=''
                    />
                </div>
            </Box>
            {titleHeader &&
                <Box sx={styleHeader.titulo}>
                    CADASTRO DE DEMANDA PARA HABITAÇÃO DE INTERESSE SOCIAL
                </Box>
            }

            {/* <h1
                        style={{
                            fontSize: '28px',
                            fontWeight: '800',
                            color: '#F78C2F'
                        }}
                    >
                        HOMOLOGAÇÃO
                    </h1> */}

            <Box sx={styleHeader.headerEnd}>
                <img
                    style={styleHeader.imgLogo2}
                    src={logo2}
                    height='50'
                    alt=''
                />
                <div>
                    <ModalLogout />
                </div>

            </Box>
        </Box>
    )
}