// _1 Importações
// _2 Declaração de constantes
// _3 API
// _4 Stepper
// _5 Formulário Meus anexos (Parte Fixa)
// _6 Formulário demais anexos (Dinâmico)

// _1 Importações ============================================================================================
//Importações do React
import { useState, ChangeEvent, useEffect } from 'react';
import React from 'react';

// Importações do MUI 
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

//Icons
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SignpostIcon from '@mui/icons-material/Signpost';
import PinIcon from '@mui/icons-material/Pin';

//impotação da API
import api from "../../Services/api";
import { getDados, getDadosById } from '../../Services/globalRequests';

//   Importações do "Stepper" 
import { StepperContext } from "../../Contexts/StepperContext";
import Stepper from '../../Components/Stepper/Stepper';

// Importações de estilo (.css e imagens)
import BackgroundImg from '../../Components/Background/estiloPages.css';
import styles from '../../Components/Stepps/InputStyle.module.css';

// Importações para o formulário
import { FormControl } from '@mui/material';
import { Button, Form, Space } from 'antd';

//Importações yup
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Demais importações
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../Components/Header';
import ExibeAnexos from '../../Components/ExibeAnexos/ExibeAnexos';
import RemoveAnexos from '../../Components/RemoveAnexos';
import { render } from '@testing-library/react';

export const CadAnexos = ({
    endpoint = "/users/dependents",
    endpoint2 = "/users/finance",
    endpoint3 = "/users/attchments"

}) => {
    const { id } = useParams();

    // _2 Declaração de constantes ============================================================================
    const [carregando, setCarregando] = useState(false);
    const navigate = useNavigate()

    const handleLogout = () => {
        sessionStorage.removeItem("token");
        navigate("/");
    }

    // Funções do Stepper
    const [currentStep, setCurrentStep] = useState(4);
    const [stepData, setStepData] = useState('');
    const [finalData, setFinalData] = useState([]);

    const steps = [
        "Dados Pessoais",
        "Endereço Atual",
        "Composição e Renda Familiar",
        "Gasto mensal (Familiar)",
        "Anexos",
        "Finalizado"
    ];

    const style = {
        containerStepper: {
            height: 'auto',
            margin: 'auto',
            marginTop: '80px'
        }
    }

    //  Função do formulário
    const onFinish = (values) => {
        console.log('Received values of form:', values);
    };


    // Validação pelo yup
    var schema = yup.object({

    });
    const [msg, setMsg] = useState(false);

    // Constantes UseForm
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    // _3 API =================================================================================================
    const [dados, setDados] = useState('');
    const [erro, setErro] = useState();
    const [list, setList] = useState([]);

    function voltar(e) {

        if (
            window.location.href == 'https://habitacao.piracicaba.sp.gov.br/#/cadastroAnexos' ||
            window.location.href == 'https://habita-teste.pmp.sp.gov.br/#/cadastroAnexos' ||
            window.location.href === 'https://localhost:88/#/cadastroAnexos' ||
            window.location.href === 'http://localhost:3000/#/cadastroAnexos'
        ) {
            getDados(endpoint2)
                .then((dados) => {
                    console.log(dados);
                    sessionStorage.setItem("valorAgua", dados.valorAgua);
                    sessionStorage.setItem("valorAlimentacao", dados.valorAlimentacao);
                    sessionStorage.setItem("valorAluguel", dados.valorAluguel);
                    sessionStorage.setItem("valorEducacao", dados.valorEducacao);
                    sessionStorage.setItem("valorEnergia", dados.valorEnergia);
                    sessionStorage.setItem("valorGasDeCozinha", dados.valorGasDeCozinha);
                    sessionStorage.setItem("valorMedicamentos", dados.valorMedicamentos);
                    sessionStorage.setItem("valorOutros", dados.valorOutros);
                    sessionStorage.setItem("valorPlanoDeSaude", dados.valorPlanoDeSaude);
                    sessionStorage.setItem("valorPlanos", dados.valorPlanos);
                    sessionStorage.setItem("valorSalario", dados.valorSalario);
                    sessionStorage.setItem("valorTransporte", dados.valorTransporte);
                    sessionStorage.setItem("rendaMensal", dados.rendaMensal);
                    setDados(dados);
                    navigate('/cadastroFinanceiro');

                })
                .catch(setErro)
                .finally(() => {
                    setCarregando(false)
                });
        } else {
            getDadosById("/users/finance", id)
                .then((dados) => {
                    console.log(dados);
                    sessionStorage.setItem("valorAgua", dados.valorAgua);
                    sessionStorage.setItem("valorAlimentacao", dados.valorAlimentacao);
                    sessionStorage.setItem("valorAluguel", dados.valorAluguel);
                    sessionStorage.setItem("valorEducacao", dados.valorEducacao);
                    sessionStorage.setItem("valorEnergia", dados.valorEnergia);
                    sessionStorage.setItem("valorGasDeCozinha", dados.valorGasDeCozinha);
                    sessionStorage.setItem("valorMedicamentos", dados.valorMedicamentos);
                    sessionStorage.setItem("valorOutros", dados.valorOutros);
                    sessionStorage.setItem("valorPlanoDeSaude", dados.valorPlanoDeSaude);
                    sessionStorage.setItem("valorPlanos", dados.valorPlanos);
                    sessionStorage.setItem("valorSalario", dados.valorSalario);
                    sessionStorage.setItem("valorTransporte", dados.valorTransporte);
                    sessionStorage.setItem("rendaMensal", dados.rendaMensal);
                    setDados(dados);
                    navigate("/cadastroFinanceiro/edit/" + id);

                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        sessionStorage.removeItem("token");
                        sessionStorage.clear()
                        navigate('/');
                    } else {
                        setErro(err);
                    }
                })
                .finally(() => {
                    setCarregando(false)
                });
        }
    }

    const [fileSelectedRG, setFileSelectedRG] = useState();
    const saveFileSelectedRG = (e) => {
        setFileSelectedRG(e.target.files[0]);
        console.log("fileSelectedRG", fileSelectedRG);
    };

    const [fileSelectedEND, setFileSelectedEND] = useState();
    const saveFileSelectedEND = (e) => {
        setFileSelectedEND(e.target.files[0]);
    };

    const [fileSelectedCPF, setFileSelectedCPF] = useState();
    const saveFileSelectedCPF = (e) => {
        setFileSelectedCPF(e.target.files[0]);
    };

    const [imagens, setImagens] = useState([]);
    useEffect(() => {
        if (
            window.location.href == 'https://habitacao.piracicaba.sp.gov.br/#/cadastroAnexos' ||
            window.location.href == 'https://habita-teste.pmp.sp.gov.br/#/cadastroAnexos' ||
            window.location.href === 'https://localhost:88/#/cadastroAnexos' ||
            window.location.href === 'http://localhost:3000/#/cadastroAnexos'
        ) {
            getDados(endpoint3)
                .then((dados) => {
                    dados = dados || {};

                    if (!dados.textImageCpf && sessionStorage.getItem("Documento") || localStorage.getItem("Documento")) {
                        if(sessionStorage.getItem("Documento")){
                            dados.textImageCpf = sessionStorage.getItem("Documento");
                        } else {
                            dados.textImageCpf = localStorage.getItem("Documento");
                        }
                    }

                    if (!dados.textImageRg && sessionStorage.getItem("Documento") || localStorage.getItem("Documento")) {
                        if(sessionStorage.getItem("Documento")){
                            dados.textImageRg = sessionStorage.getItem("Documento");
                        } else {
                            dados.textImageRg = localStorage.getItem("Documento");
                        }
                    }

                    if (!dados.textImageEnd && sessionStorage.getItem("ComprovanteResidencia")) {
                        dados.textImageEnd = sessionStorage.getItem("ComprovanteResidencia");
                    }

                    setImagens(dados);

                })
                .catch((err) => {
                    setErro(err);
                })
                .finally(() => {
                    setCarregando(false)
                });
        } else {
            getDadosById(endpoint3, id)
                .then((dados) => {
                    setImagens(dados);
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        sessionStorage.removeItem("token");
                        sessionStorage.clear()
                        navigate('/');
                    } else {
                        setErro(err);
                    }
                })
                .finally(() => {
                    setCarregando(false)
                });
        }

    }, []);

    const [alteracoes, setAlteracoes] = useState('a');

    const salvaAlteracoes = async (data) => {

        const formData = new FormData();

        if (fileSelectedRG == undefined) {
            formData.append("rg", null);
        } else {
            formData.append("rg", fileSelectedRG);
        }

        if (fileSelectedCPF == undefined) {
            formData.append("cpf", null);
        } else {
            formData.append("cpf", fileSelectedCPF);
        }

        if (fileSelectedEND == undefined) {
            formData.append("end", null);
        } else {
            formData.append("end", fileSelectedEND);
        }

        if (imagens.textImageCpf === true) {
            formData.append("cpf", imagens.textImageCpf);
        }

        if (imagens.textImageEnd === true) {
            formData.append("end", imagens.textImageEnd);
        }

        if (imagens.textImageRg === true) {
            formData.append("rg", imagens.textImageRg);
        }

        try {

            if (
                window.location.href == 'https://habitacao.piracicaba.sp.gov.br/#/cadastroAnexos' ||
                window.location.href == 'https://habita-teste.pmp.sp.gov.br/#/cadastroAnexos' ||
                window.location.href === 'https://localhost:88/#/cadastroAnexos' ||
                window.location.href === 'http://localhost:3000/#/cadastroAnexos'
            ) {
                const res = await api.post("/users/attchments", formData, {
                    headers: {
                        'content-type': 'mulpart/form-data; charset=utf-8',
                        'Accept': '*/*',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                    }
                });
            } else {
                const res = await api.post("/users/attchments/" + id, formData, {
                    headers: {
                        'content-type': 'mulpart/form-data; charset=utf-8',
                        'Accept': '*/*',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                    }
                
                });
            }

        } catch (err) {
            if (err.response.status === 403) {
                sessionStorage.removeItem("token");
                sessionStorage.clear()
                navigate('/');
            } else {
                setErro(err);
            }
        }
        // window.location.reload(true);
    }

    const [teste, setTeste] = useState('');

    const removerAnexo = (e, id, idUser) => {

        if (
            window.location.href == 'https://habitacao.piracicaba.sp.gov.br/#/cadastroAnexos' ||
            window.location.href == 'https://habita-teste.pmp.sp.gov.br/#/cadastroAnexos' ||
            window.location.href === 'https://localhost:88/#/cadastroAnexos' ||
            window.location.href === 'http://localhost:3000/#/cadastroAnexos'
        ) {
            api.post('/users/attchments/delete/' + id)
                .then(() => {
                    if (id === 1) {
                        imagens.textImageCpf = null
                        setTeste("1");
                        console.log("cpf =>", fileSelectedCPF);
                    } else if (id === 2) {
                        imagens.textImageEnd = null
                        setTeste("2");
                    } else if (id === 3) {
                        imagens.textImageRg = null
                        setTeste("3");
                    }

                })
                .catch((error) => console.log(error))
        } else {
            api.post('/users/attchments/delete/' + idUser + '/' + id)
                .then(() => {
                    if (id === 1) {
                        imagens.textImageCpf = null
                        setTeste("1");
                        console.log("cpf =>", fileSelectedCPF);
                    } else if (id === 2) {
                        imagens.textImageEnd = null
                        setTeste("2");
                    } else if (id === 3) {
                        imagens.textImageRg = null
                        setTeste("3");
                    }

                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        sessionStorage.removeItem("token");
                        sessionStorage.clear()
                        navigate('/');
                    } else {
                        setErro(err);
                    }
                })
        }

    }

    const proximaPagina = () => {

        if (
            window.location.href == 'https://habitacao.piracicaba.sp.gov.br/#/cadastroAnexos' ||
            window.location.href == 'https://habita-teste.pmp.sp.gov.br/#/cadastroAnexos' ||
            window.location.href === 'https://localhost:88/#/cadastroAnexos' ||
            window.location.href === 'http://localhost:3000/#/cadastroAnexos'
        ) {
            api.post('/users/protocol/')
                .then(function (response) {
                    sessionStorage.setItem("nomeCompleto", response.data.nomeCompleto);
                    sessionStorage.setItem("protocolo", response.data.protocolo);
                    console.log("response =>", response.data);
                    navigate('/cadastroFinalizado');

                })
                .catch(function (error) {
                    console.log("Erro ao buscar dados da tabela.");
                })
                .finally((response) => {
                    setCarregando(false);
                });
            navigate('/cadastroFinalizado');
        } else {
            api.post('/users/protocol/' + id)

                .then(function (response) {
                    console.log("response =>", response.protocolo);
                    sessionStorage.setItem("nomeCompleto", response.data.nomeCompleto);
                    sessionStorage.setItem("protocolo", response.data.protocolo);
                    navigate('/cadastroFinalizado/edit/' + id);
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        sessionStorage.removeItem("token");
                        sessionStorage.clear()
                        navigate('/');
                    } else {
                        setErro(err);
                    }
                })
                .finally((response) => {
                    setCarregando(false);
                });
            navigate('/cadastroFinalizado');
        }
    }

    return (
        <div className="BackgroundImg">
            <Header />
            <div className='mx-auto my-4 rounded-2xl bg-white shadow-xl md:w-2/3 pb-6 p-1'>
                <div className="container horizontal mt-8">
                    {/* _4 Stepper ======================================================================================  */}
                    <Stepper steps={steps} currentStep={currentStep} />
                    <div className="my-0 p-10">
                        <StepperContext.Provider value={{
                            stepData,
                            setStepData,
                            finalData,
                            setFinalData
                        }}>
                        </StepperContext.Provider>
                        {/* _5 Meus anexos (Parte fixa) ==================================================================== */}
                        <div className='flex justify-between items-center my-4'>
                            {/* <FormControl> */}
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} sm={6}>
                                    {/* <center> */}
                                    <h1 style={{
                                        fontFamily: 'Exo-SemiBold',
                                        fontSize: '32px',
                                        marginTop: '15px',
                                    }} >
                                        Meus Anexos
                                    </h1>
                                    <p style={{
                                        color: '#9e9e9e',
                                        marginBottom: '15px'
                                    }}>
                                        ATENÇÃO! Todos os arquivos devem estar em .jpg
                                    </p>
                                    {/* </center> */}
                                </Grid>
                                <Grid justifyContent="flex-end" item xs={12} sm={6}>
                                    <p
                                        style={{
                                            fontFamily: 'Exo-SemiBold',
                                            fontSize: '22px',
                                            textAlign: 'end'
                                        }}
                                    >
                                        {sessionStorage.getItem("cpf")}
                                    </p>
                                    <p
                                        style={{
                                            fontFamily: 'Exo-SemiBold',
                                            fontSize: '22px',
                                            textAlign: 'end'
                                        }}
                                    >
                                        {sessionStorage.getItem("userName")}
                                    </p>
                                </Grid>
                            </Grid>
                            {msg && <Alert severity="error">Por favor, insira arquivos com no máximo 2Mb!</Alert>}
                            {/* </FormControl> */}
                        </div>

                        <Divider light />
                        <Form name="formAnexos" autoComplete="off">
                            <FormControl sx={{ width: '100%' }}>
                                <div className="justify-around items-center">
                                    <Grid sm={12} sx={{ mt: 2 }}>
                                        <Grid className={styles.IconsUpload} sx={{ mb: 1 }}>
                                            <AssignmentIndIcon />&nbsp; RG (FOTO ÚNICA)
                                        </Grid>
                                        <Grid>
                                            <input
                                                id="1uploadRG"
                                                name="1uploadRG"
                                                className="input_file"
                                                // accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                accept="image/jpeg, image/jpg, image/png"
                                                type="file"
                                                onChange={saveFileSelectedRG}

                                            />
                                        </Grid>
                                        {imagens.textImageRg
                                            && (
                                                <>
                                                    <ExibeAnexos anexo={imagens.textImageRg} />
                                                    <RemoveAnexos onClick={e => removerAnexo(e, 3, id)} />
                                                </>
                                            )}

                                    </Grid>
                                    <br />
                                    <Divider />
                                    <br />
                                    <Grid sm={12} sx={{ mt: 2 }}>
                                        <Grid className={styles.IconsUpload} sx={{ mb: 1 }}>
                                            <PinIcon />
                                            &nbsp; CPF (FOTO ÚNICA)
                                        </Grid>
                                        <Grid>
                                            <input
                                                id="2uploadCPF"
                                                name="2uploadCPF"
                                                className="input_file"
                                                accept="image/jpeg, image/jpg, image/png"
                                                type="file"
                                                onChange={saveFileSelectedCPF}
                                            />
                                        </Grid>
                                        {imagens.textImageCpf && (
                                            <>
                                                <ExibeAnexos anexo={imagens.textImageCpf} />
                                                <RemoveAnexos onClick={e => removerAnexo(e, 1, id)} />
                                            </>
                                        )}
                                    </Grid>
                                    <br />
                                    <Divider />
                                    <br />
                                    <Grid sm={12} sx={{ mt: 2 }}>
                                        <Grid className={styles.IconsUpload} sx={{ mb: 1 }}>
                                            <SignpostIcon />
                                            &nbsp; COMPROVANTE DE ENDEREÇO
                                        </Grid>
                                        <Grid>
                                            <input
                                                id="3uploadEND"
                                                name="3uploadEND"
                                                className="input_file"
                                                accept="image/jpeg, image/jpg, image/png"
                                                type="file"
                                                onChange={saveFileSelectedEND}
                                            />
                                        </Grid>
                                        {imagens.textImageEnd && (
                                            <>
                                                <ExibeAnexos anexo={imagens.textImageEnd} />
                                                <RemoveAnexos onClick={e => removerAnexo(e, 2, id)} />
                                            </>
                                        )}
                                    </Grid>
                                    <br />
                                    <Divider />
                                </div>
                                <br />
                            </FormControl>

                            {/* <Divider light /> */}
                            {/* _6 Faormulário Dinâmico ======================================================================== */}
                            {/* <div className="flex justify-center items-center">
                                <div className='flex flex-col mt-10 mb-10 '>
                                    <Form.List name="users">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Space
                                                        key={key}
                                                        style={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            marginBottom: 16,
                                                            marginTop: 0
                                                        }}
                                                    >

                                                        <Form.Item>
                                                            <Grid sm={12} sx={{ mt: 2 }}>
                                                                <Grid className={styles.IconsUpload} sx={{ mb: 1 }}>
                                                                    <AddBoxIcon />
                                                                    &nbsp; OUTROS DOCUMENTOS
                                                                </Grid>
                                                                <Grid>
                                                                    <Controller
                                                                        control={control}
                                                                        name={"4uploadADD" + name}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    id="uploadADD"
                                                                                    name={'4uploadADD' + name}
                                                                                    className="input_file"
                                                                                    accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                                                    type="file"
                                                                                    {...field}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />

                                                                </Grid>
                                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                                            </Grid>
                                                        </Form.Item>
                                                    </Space>
                                                ))}
                                                <Form.Item>
                                                    <div className='flex justify-center'> */}
                            {/* <Button
                                                            type="dashed"
                                                            style={{
                                                                backgroundColor: '#EA580C',
                                                                padding: '16px',
                                                                color: '#fff',
                                                                borderRadius: '8px',
                                                                marginTop: '16px'
                                                            }}
                                                            onClick={() => add()}
                                                            block
                                                            icon={<PlusOutlined style={{ marginRight: '8px' }} />}
                                                        >
                                                            ADICIONAR OUTRO DOCUMENTO
                                                        </Button> */}
                            {/* </div>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </div>
                            </div> */}
                            <center style={{ marginBottom: '-50px' }}>
                                <div className='flex justify-between'>
                                    <div>
                                        <LoadingButton
                                            style={{
                                                padding: '16px',
                                                borderRadius: '12px'
                                            }}
                                            variant='outlined'
                                            size="large"
                                            onClick={voltar}
                                        >
                                            Voltar
                                        </LoadingButton>
                                    </div>
                                    <div>
                                        <LoadingButton
                                            style={{
                                                backgroundColor: '#006400',
                                                padding: '16px',
                                                borderRadius: '12px',
                                                color: '#fff'
                                            }}
                                            variant='outlined'
                                            size="large"
                                            onClick={salvaAlteracoes}
                                        >
                                            Salvar Alterações
                                        </LoadingButton>
                                        <p
                                            style={{
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                marginTop: '10px',
                                                marginBottom: '0px'
                                            }}
                                        >
                                            Caso tenha inserido um novo anexo, lembre-se <br /> de salvar as alterações antes de prosseguir
                                        </p>
                                    </div>
                                    <div>
                                        <LoadingButton
                                            style={{
                                                backgroundColor: '#3F51b5',
                                                padding: '16px',
                                                borderRadius: '12px'
                                            }}
                                            loading={carregando}
                                            type='submit'
                                            variant='contained'
                                            size="large"
                                            onClick={proximaPagina}
                                        >
                                            Próximo
                                        </LoadingButton>
                                    </div>
                                </div>
                            </center>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
