import axios from 'axios';
import { Navigate } from 'react-router-dom';

//define a url base da API
const api = axios.create({
	baseURL: window.url_API,
});

//intercepta as requisições e injeta os valores abaixo no header.
api.interceptors.request.use(async config => {
	config.headers.Authorization = `Bearer ${sessionStorage.getItem("token")}`;

	return config;
});

api.interceptors.response.use(response => {
	return response;
}, error => {
	if (error.response.status === 401) {
		Navigate("/");
		console.log("Token expirado!");
		return Promise.reject(error);
	}
	else {
		return Promise.reject(error);
	}
})

export default api;