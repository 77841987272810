//Reaproveitei este arquivo do Portal do consultor. Verificar o que será útil.

import api from "./api";

const FormataErro = (err) => {
  try {
    return err.response.data.message
      ? err.response.data.message
      : err.toString();
  } catch (error) {
    return err.toString();
  }
};

export const getDados = (endpoint, params) =>
  new Promise((resolve, reject) =>
    api
      .get(endpoint, params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(FormataErro(err)))
      .finally((data) => data)
  );

export const getDadosById = (endpoint, params) =>
  new Promise((resolve, reject) =>
    api
      .get(endpoint + "/" + params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );

// export const getDadosWithQueryParams = (endpoint, params) => {
//     const urlParams = new URLSearchParams();

//     params.forEach(element => urlParams.append(element.nome, element.valor));

//     return new Promise((resolve, reject) =>
//         api.get(endpoint + '?' + urlParams)
//             .then(res => resolve(res.data))
//             .catch(err => reject(FormataErro(err)))
//     );
// }

export const incluirDados = (endpoint, params) =>
  new Promise((resolve, reject) =>
    api
      .post(endpoint, params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );

// export const editarDados = (endpoint, params) =>
//     new Promise((resolve, reject) =>
//         api.put(endpoint, params)
//             .then(res => resolve(res.data))
//             .catch(err => reject(FormataErro(err)))
//     );

// export const editarDadosWithQueryParams = (endpoint, params) => {
//     const urlParams = new URLSearchParams();

//     params.forEach(element => urlParams.append(element.nome, element.valor));

//     return new Promise((resolve, reject) =>
//         api.put(endpoint + '?' + urlParams)
//             .then(res => resolve(res.data))
//             .catch(err => reject(FormataErro(err)))
//     );
// }

/**
 * Método de Exclusão por API
 * @param {string} endpoint URL para exclusão
 * @param {*} params Id/Valor para excluir
 * @returns
 */
// export const excluirDados = (endpoint, params) =>
//     new Promise((resolve, reject) =>
//         api.delete(endpoint + '/' + params)
//             .then(res => resolve(res.data))
//             .catch(err => reject(FormataErro(err)))
//     );

// export const excluirDadosWithQueryParams = (endpoint, params) => {
//     const urlParams = new URLSearchParams();

//     params.forEach(element => urlParams.append(element.nome, element.valor));

//     return new Promise((resolve, reject) =>
//         api.delete(endpoint + '?' + urlParams)
//             .then(res => resolve(res.data))
//             .catch(err => reject(FormataErro(err)))
//     );
// }
