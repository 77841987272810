import stylesInput from '../../Stepps/InputStyle.module.css';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, Button, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export default function BotaoHomeAdm() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();

    const handleClickAdmin = () => {
        navigate("/admin");
    }

    return (
        <>
            <Button
                style={{ margin: '10px' }}
                variant="contained"
                color="warning"
                size="small"
                onClick={handleClickAdmin}
            >
                <UndoIcon
                    style={{
                        fontSize: "20px",
                        color: "white",
                        cursor: "pointer"
                    }}
                />
                &nbsp;
                Admin
            </Button>
        </>
    )
}