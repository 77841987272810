import { useEffect, useState } from "react";
import ControlledSelect from "../ControlledSelect";
import ControlledTextField from "../ControlledTextField";
import { setValue } from "react-advanced-form";

export default function ComponenteFamiliarDoencaCronica({
    control,
    row,
    setValue,
    selectSimNaoOptions,
    errors,
    defaultValues,
}) {
    const [Disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (!!defaultValues?.dependentes) {
            let familiarDef = defaultValues?.dependentes[row]?.familiarDoencaCronica;
            console.log('passei familiarDoencaCronica', familiarDef);
            setDisabled(familiarDef == 'false' || familiarDef === false);
        }

        return () => defaultValues

    }, [defaultValues, row])


    const handleChange = (e) => {
        console.log('valor mudou', e.target.value);
        setDisabled(e.target.value == 'false');
        if (e.target.value == 'false')
            setValue(`dependentes.${row}.descricaoDoencaCronicaFamiliar`, "");

    }

    return (
        <div
            className="border-2 border-#f5f5f5 border-solid rounded-lg"
            style={{ width: '100%', padding: '15px' }}
        >
            <ControlledSelect
                control={control}
                errors={errors}
                originalError={errors?.dependentes?.[row]?.["familiarDoencaCronica"]}
                firstDisabled={false}
                firstSelected={false}
                defaultValue={false}
                name={`dependentes.${row}.familiarDoencaCronica`}
                label={'Possui alguma Doença Crônica?'}
                selectOptions={selectSimNaoOptions}
                style={{ mt: '16px' }}
                onSelectChange={handleChange}
            />
            <ControlledTextField
                control={control}
                errors={errors}
                name={`dependentes.${row}.descricaoDoencaCronicaFamiliar`}
                originalError={errors?.dependentes?.[row]?.["descricaoDoencaCronicaFamiliar"]}
                label='Se sim, qual doença?'
                placeholder="Se sim, qual doença?"
                otherPropertiesFormControl={{ sx: { width: "100%" } }}
                style={{ width: "100%" }}
                inputStyle={{ width: 'auto', marginTop: '1.5em' }}
                disabled={Disabled}
            />
        </div>
    );
}
