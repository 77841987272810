import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

//MUI
import { Paper, Container, Stack, Box, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import EmailIcon from '@mui/icons-material/Email';

//Componentes
import stylesText from '../../Components/Stepps/Text.module.css';
import styleHeader from '../../Components/Header/styles';
import logo1 from "../../Assets/logo-pt1.png";
import logo2 from "../../Assets/logo-pt2.png"
import { useMediaQuery } from 'react-responsive'

//CSS
import BackgroundImg from '../../Components/Background/estiloPages.css';

export function ConfirmacaoSenha() {

    //_2 Validações yup ===================================================================================
    const [erro, setErro] = useState('');
    const [carregando, setCarregando] = useState(false);
    const navigate = useNavigate();

    const finalizar = () => {
        navigate("/login")
    }
    
    const titleHeader = useMediaQuery({
        query: '(min-width: 640px)'
    })

    return (
        <div className="BackgroundImg">
            <Box sx={styleHeader.header}>
                <Box sx={styleHeader.logoContainer}>
                    <img style={styleHeader.imgLogo} src={logo1} height='50' alt='' />
                </Box>
                {titleHeader &&
                    <Box sx={styleHeader.titulo}>
                        CADASTRO DE DEMANDA PARA HABITAÇÃO DE INTERESSE SOCIAL
                    </Box>
                }

                {/* <h1
                        style={{
                            fontSize: '28px',
                            fontWeight: '800',
                            color: '#F78C2F'
                        }}
                    >
                        HOMOLOGAÇÃO
                    </h1> */}

                <Box sx={styleHeader.logoContainer}>
                    <img style={styleHeader.imgLogo2} src={logo2} height='50' alt='' />
                </Box>
            </Box>

            <Container sx={{ pt: '8vh' }}>

                <Paper
                    sx={{
                        p: 4,
                        maxWidth: 720,
                        margin: 'auto',
                        borderRadius: '16px'
                    }}
                >
                    <Box
                        sx={{
                            mb: 3,
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <p className={stylesText.titulo} style={{ fontSize: '24px' }}>
                           Senha alterada com sucesso !
                        </p>

                    </Box>
                        <Stack spacing={1} >
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        textAlign: 'center',
                                        borderRadius: '24px',
                                        marginY: '16px',
                                        padding: '8px',
                                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                                    }}
                                >
                                    <br />
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        className={stylesText.textEmail}
                                        style={{color: '#EA580C'}}
                                    >
                                      Sua senha foi alterada com sucesso, volte para a tela de login.
                                    </Grid>
                                </Grid>
                            <Stack direction='row' style={{ width: '100%' }}>
                                <LoadingButton
                                    style={{
                                        backgroundColor: '#3F51b5',
                                        padding: '14px',
                                        borderRadius: '12px',
                                        width: '100%',
                                        marginTop: '24px'
                                    }}
                                    fullWidth
                                    loading={carregando}
                                    variant='contained'
                                    disableElevation
                                    onClick={finalizar}
                                >
                                   Voltar para a tela de Login
                                </LoadingButton>
                            </Stack>
                        </Stack>
                </Paper>

            </Container>
        </div >
    );
}