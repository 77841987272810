//_1 Importações
//_2 Validações Yup
//_3 Ligação com API
//_4 Styles
//_5 Formulário Login

//Importações ======================================================================================
//React
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

//MUI
import { Paper, Container, TextField, Stack, Box, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

//API
import api from "../../Services/api";

//Componentes
import stylesInput from '../../Components/Stepps/InputStyle.module.css'
import stylesText from '../../Components/Stepps/Text.module.css';
import styleHeader from '../../Components/Header/styles';
import { Header } from '../../Components/Header';
import logo1 from "../../Assets/logo-pt1.png";
import logo2 from "../../Assets/logo-pt2.png";
import { useMediaQuery } from 'react-responsive'

//Yup
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

//iMask
import { IMaskInput } from "react-imask";

//CSS
import BackgroundImg from '../../Components/Background/estiloPages.css';

export function RestaurarConta() {

    //_2 Validações yup ===================================================================================
    const [erro, setErro] = useState('');
    const [carregando, setCarregando] = useState(false);
    const navigate = useNavigate();

    const schema = yup.object({

        cpfCadastrado:
            yup.string()
                .required("Defina seu CPF*"),


    }).required();

    const { handleSubmit, control, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

    const handleLogout = () => {
        sessionStorage.removeItem("token");
        sessionStorage.clear()
        navigate("/");

    }

    //_3 Ligação com API ==================================================================================
    async function onSubmit(data) {
        setCarregando(true);
        console.log(data);

        // para validar:
        schema.isValid(data)
            .then((valid) => {
                // caso esteja validado
                if (valid) {
                    // chamada para a API passando os dados ( `data` ) por parametro
                    console.log(data);
                    api.post('/perdiSenha/' + data.cpfCadastrado)
                        .then(function (response) {
                            // sucesso da api
                            console.log(response.data);
                            navigate('/restaurarConta/verificacaoEmail');
                        })
                        .catch(err => setErro(err.response.data.message.toString()))
                        .finally((response) => {
                            setCarregando(false);
                        });
                } else {
                    // tem algum erro de validação.
                    console.log("erro de validação")
                }
            })
            .catch((err) => console.log('valid', err))
    }

    const titleHeader = useMediaQuery({
        query: '(min-width: 640px)'
    })


    return (
        <div className="BackgroundImg">
            <Box sx={styleHeader.header}>
                <Box sx={styleHeader.logoContainer}>
                    <img style={styleHeader.imgLogo} src={logo1} height='50' alt='' />
                </Box>
                {titleHeader &&
                    <Box sx={styleHeader.titulo}>
                        CADASTRO DE DEMANDA PARA HABITAÇÃO DE INTERESSE SOCIAL
                    </Box>
                }

                {/* <h1
                        style={{
                            fontSize: '28px',
                            fontWeight: '800',
                            color: '#F78C2F'
                        }}
                    >
                        HOMOLOGAÇÃO
                    </h1> */}

                <Box sx={styleHeader.logoContainer}>
                    <img style={styleHeader.imgLogo2} src={logo2} height='50' alt='' />
                </Box>
            </Box>

            <Container sx={{ pt: '8vh' }}>

                <Paper sx={{ p: 4, maxWidth: 720, margin: 'auto', borderRadius: '16px' }}>
                    <Box sx={{ mb: 3, position: 'relative' }}>
                        <p className={stylesText.titulo}>
                            Recupere a sua conta !
                        </p>
                    </Box>

                    {/*_5 Formulário Login ============================================================================================= */}
                    <form onSubmitCapture={handleSubmit(onSubmit)} >
                        <Stack spacing={1} >
                            <Controller
                                control={control}
                                name="cpfCadastrado"
                                render={({ field }) => (
                                    <>
                                        <IMaskInput
                                            required
                                            className={stylesInput.InputStyle}
                                            mask="000.000.000-00"
                                            minLength="14"
                                            maxLength="14"
                                            placeholder="Insira o seu CPF cadastrado *"
                                            {...field}  // importante
                                        >
                                        </IMaskInput>
                                        <p
                                            style={{
                                                color: 'red',
                                                marginTop: '8px',
                                                display: 'flex'
                                            }}
                                        >
                                            {errors.cpfCadastrado?.message}
                                        </p>
                                    </>
                                )}
                            />

                            {erro &&
                                <Alert severity='error'>
                                    {erro}
                                </Alert>
                            }

                            <Stack
                                direction='row'
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: 32
                                }}
                            >
                                <LoadingButton
                                    style={{
                                        padding: '14px',
                                        borderRadius: '12px',
                                    }}
                                    loading={carregando}
                                    variant="outlined"
                                    disableElevation
                                    onClick={handleLogout}
                                >
                                    Sair
                                </LoadingButton>
                                <LoadingButton
                                    style={{
                                        backgroundColor: '#3F51b5',
                                        padding: '14px',
                                        borderRadius: '12px',
                                    }}
                                    loading={carregando}
                                    variant='contained'
                                    type='submit' // importante
                                    disableElevation
                                >
                                    Enviar
                                </LoadingButton>
                            </Stack>
                        </Stack>
                    </form>
                </Paper>

            </Container>
        </div >
    );
}