import stylesInput from '../../Stepps/InputStyle.module.css';
import { LogoutOutlined } from '@mui/icons-material';
import { Box, Button, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo1 from "../../../Assets/logo-pt1.png";
import styleModal from './styles'
import React from 'react';


export default function ModalLogout() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.removeItem("token");
        sessionStorage.clear()
        navigate("/");
    }

    return (
        <>
            <LogoutOutlined
                style={{
                    fontSize: "32px",
                    color: "red",
                    cursor: "pointer"
                }}
                onClick={handleOpen}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box style={styleModal.modal}>
                    <div className='flex flex-col items-center'>
                        <img src={logo1} style={styleModal.logotipo} />
                        <p className="font-normal text-2xl mt-8 text-white flex flex-start "  >
                            Você tem certeza que deseja sair da sua conta ?
                        </p>
                    </div>
                    <div className='flex justify-center my-8'>
                        <Button
                            style={{ marginRight: '8px' }}
                            variant="contained"
                            size="large"
                            onClick={handleClose}
                        >
                            Voltar
                        </Button>
                        <Button
                            style={{ marginLeft: '8px' }}
                            variant="contained"
                            color="error"
                            size="large"
                            onClick={handleLogout}
                        >
                            Sair
                        </Button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}