import { FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { Controller } from "react-hook-form";

export const ControlledOutlinedInput = ({ control, name, errors, label, placeholder, type = 'number', styleInput = { width: '100%' }, inputProps = {
    step: "0.01"
}, startAdornment = <InputAdornment position="start">R$</InputAdornment>, sxFormControl = { mt: 3 } }) => (

    <FormControl style={{ width: '100%' }} sx={sxFormControl}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <OutlinedInput
                    style={styleInput}
                    type={type}
                    inputProps={inputProps}
                    placeholder={placeholder}
                    startAdornment={startAdornment}
                    label={label}
                    error={errors?.[name] !== undefined && errors?.[name] !== null}
                    helperText={errors?.[name]?.message}
                    {...field}
                />
            )}
        />
    </FormControl>

)

export default ControlledOutlinedInput;