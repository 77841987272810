import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import axios from 'axios';
import SaveIcon from '@mui/icons-material/Save';

export default function GerarRelatorio() {
    const [loading, setLoading] = useState(false);

    const getRelatorio = async () => {
        setLoading(true);

        try {
            const response = await axios.get(window.url_API + '/users/data/usersReport', {
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
            });

            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const a = document.createElement('a');
            a.href = url;
            a.download = 'UserReport.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Erro ao baixar o relatório:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {!loading ? (
                <Button
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginLeft: '15px',
                    }}
                    variant='contained'
                    color='success'
                    onClick={getRelatorio}
                >
                    Gerar Relatório
                </Button>
            ) : (
                <LoadingButton
                    sx={{
                        marginLeft: '15px',
                    }}
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                >
                    &nbsp;&nbsp;Gerando...&nbsp;&nbsp;
                </LoadingButton>
            )}
        </>
    );
}
