import fundoLogin from '../Assets/piracicaba_preto70.png';
import styleHeader from '../Components/Header/styles';
import { Paper, Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';

import logo1 from "../Assets/logo-pt1.png";

export function VerificacaoCadastro() {

    return (
        <div style={{
            backgroundImage: `url(${fundoLogin})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            height: '100vh',
            textAlign: 'center'
        }}>
            <Box sx={styleHeader.header}>
                <Box sx={styleHeader.logoContainer}>
                    <Link to="/">
                        <img style={styleHeader.imgLogo} src={logo1} height='50' alt='' />
                    </Link>
                </Box>
            </Box>

            <Container sx={{ pt: '8vh' }}>

                <Paper sx={{ p: 4, maxWidth: 720, margin: 'auto', borderRadius: '16px' }}>
                    <div className="flex flex-col items-center">
                        <div className="text-lg font-semibold text-gray-500">
                            Conta criada com sucesso.
                        </div>
                        <div className="mt-2 text-lg font-semibold text-gray-500">
                            Agora volte para a tela de entrada e entre em sua conta.
                        </div>
                        <div className="mt-3 text-xl font-semibold uppercase text-orange-500">
                            Obrigado!
                        </div>
                        <br />
                        <Link to="/login">
                            <button className="mt-5 h-10 px-5 font-medium text-indigo-700 transition-colors duration-150 border border-gray-300 rounded-lg focus:shadow-outline hover:bg-indigo-500 hover:text-green-100">
                                Voltar para o login
                            </button>
                        </Link>
                    </div>
                    <br />
                </Paper>

            </Container>
        </div >
    );
}